import { axios } from 'api-types-shared';
import clonedeep from 'lodash.clonedeep';
import { NodeTypesEnum, } from 'types-shared';
export const stripImgsFromData = (data) => {
    const strippedData = clonedeep(data);
    strippedData.nodes.forEach((node) => {
        if (node.type === NodeTypesEnum.Image) {
            delete node.data.imageData.originalData;
            delete node.data.imageData.thumbnailData;
        }
    });
    return strippedData;
};
export const uploadBlobToS3 = async (blob, url) => {
    return axios.put(url, blob, {
        headers: {
            'Content-Type': blob.type,
            'Content-Length': blob.size.toString(),
        },
    });
};
export const getBlobFromS3 = async (url) => {
    const response = await axios.get(url, {
        responseType: 'blob',
    });
    return new Blob([response.data]);
};
