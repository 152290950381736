"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleZodCheck = void 0;
var handleZodCheck = function (value, schema, onSuccess, onFailure) {
    var result = schema.safeParse(value);
    if (result.success) {
        return onSuccess(result.data);
    }
    else if (onFailure) {
        return onFailure(result.error);
    }
};
exports.handleZodCheck = handleZodCheck;
