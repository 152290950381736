import { AddCircleOutlineOutlined } from 'assets-shared';
import Chip from '@mui/material/Chip';
import { clsx } from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import type { Variable, VariableRef } from 'types-shared';
import { v4 as uuidv4 } from 'uuid';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, MouseSensor, useSensor, useSensors } from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  useSortable,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';

import Input from './Input';
import VariablesMenu from './Menu';

export interface Item {
  id: string;
  type: 'string' | 'variable';
  value: string | VariableRef;
}

const mergeStrings = (arr: Item[]) => {
  const mergedArr: Item[] = [];
  let currentString = '';
  let currentId = '';

  arr.forEach((obj) => {
    if (obj.type === 'string') {
      currentString += `${obj.value as string} `;
      if (!currentId) {
        currentId = uuidv4();
      }
    } else {
      if (currentString !== '') {
        mergedArr.push({
          id: currentId,
          type: 'string',
          value: currentString.trim(),
        });
        currentString = '';
        currentId = '';
      }
      mergedArr.push(obj);
    }
  });

  if (currentString) {
    mergedArr.push({
      id: currentId,
      type: 'string',
      value: currentString.trim(),
    });
  }

  return mergedArr;
};

interface VariableProps {
  item: Item;
  disabled?: boolean;
  index: number;
  previewVariableIndex?: number | null;
  itemsLength: number;
  itemDetail: Variable | Item;
  handleInputChange: (id: string) => (val: string) => void;
  handleDeleteItem: (id: string) => void;
  handlePreview?: (index: number) => void;
}

function SortableItem({
  item,
  itemDetail,
  index,
  itemsLength,
  handleInputChange,
  handleDeleteItem,
  handlePreview,
  disabled,
  previewVariableIndex,
}: VariableProps) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: item.id });
  const { id, value, type } = item;

  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
    ...(index === itemsLength - 1 ? { flex: 1 } : {}),
  };

  const chipDisabled = disabled && index !== previewVariableIndex;

  return (
    <span ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {type === 'string' ? (
        <Input
          className={clsx('py-0.5', {
            '!flex-1 !max-w-none': index === itemsLength - 1,
            '!min-w-10': !value && index === itemsLength - 1,
            '!min-w-1': !value && index === 0,
          })}
          disabled={disabled}
          onChange={handleInputChange(id)}
          value={value as string}
        />
      ) : (
        <Chip
          clickable
          color="secondary"
          disabled={chipDisabled}
          label={(itemDetail as Variable).name}
          onClick={() => {
            handlePreview?.(index);
          }}
          onDelete={() => {
            handleDeleteItem(id);
          }}
          size="small"
          sx={{
            '& .MuiChip-deleteIcon': {
              display:
                index === previewVariableIndex || disabled ? 'none' : 'block',
            },
          }}
        />
      )}
    </span>
  );
}

interface Props {
  label: string;
  data: Item[];
  disabled?: boolean;
  previewVariableIndex?: number | null;
  className?: string;
  variables: Variable[];
  onChange: (val: Item[]) => void;
  allowAddVariable?: boolean;
  onAddNew?: () => void;
  onPreview?: (index: number) => void;
  variablesMap: Record<string, Variable>;
}

function VariableInput({
  className,
  data,
  label,
  onChange,
  onPreview,
  onAddNew,
  disabled = false,
  previewVariableIndex,
  allowAddVariable = true,
  variables,
  variablesMap,
}: Props) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [showVariableMenu, setShowVariableMenu] = useState<boolean>(false);
  const itemsLength = data.length;

  const handleAddVariable = (variable: Variable) => {
    const variableItemId = uuidv4();
    const inputId = uuidv4();
    onChange([
      ...(itemsLength === 1 && data[0].value === '' ? [] : data),
      {
        id: variableItemId,
        type: 'variable',
        value: variable,
      },
      {
        id: inputId,
        type: 'string',
        value: '',
      },
    ]);
  };

  const handleInputChange = (id: string) => (val: string) => {
    onChange(
      data.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            value: val,
          };
        }
        return item;
      }),
    );
  };

  const handleDeleteItem = (id: string) => {
    const filteredItems = data.filter((item) => item.id !== id);
    onChange(mergeStrings(filteredItems));
  };

  const handleAddNew = () => {
    setShowVariableMenu(false);
    onAddNew?.();
  };

  const handleCloseMenu = () => {
    setShowVariableMenu(false);
  };

  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id === over?.id) {
      return;
    }
    const newData = [...data];
    const oldIndex = newData.findIndex((item) => item.id === active.id);
    const newIndex = newData.findIndex((item) => item.id === over?.id);
    onChange(mergeStrings(arrayMove(newData, oldIndex, newIndex)));
  };

  const createStringVariable = (): Item => ({
    id: uuidv4(),
    type: 'string',
    value: '',
  });

  useEffect(() => {
    const needsUpdate =
      data[0]?.type === 'variable' ||
      data[data.length - 1]?.type === 'variable';

    if (needsUpdate) {
      const newData = [...data];
      if (newData[0]?.type === 'variable') {
        newData.unshift(createStringVariable());
      }
      if (newData[newData.length - 1]?.type === 'variable') {
        newData.push(createStringVariable());
      }
      onChange(mergeStrings(newData));
    }
  }, [data, onChange]);

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  });
  const sensors = useSensors(mouseSensor);

  return (
    <div
      className={clsx(
        'relative flex border px-3 py-2.5 max-w-full rounded',
        className,
      )}
      ref={containerRef}
    >
      <span className="absolute -top-2 left-3 text-xs text-gray-500 bg-white px-1">
        {label}
      </span>
      <div className="flex flex-1 items-center w-max overflow-x-auto space-x-1 mr-6 no-scrollbar">
        <DndContext
          modifiers={[restrictToHorizontalAxis]}
          onDragEnd={onDragEnd}
          sensors={sensors}
        >
          <SortableContext
            items={data}
            strategy={horizontalListSortingStrategy}
          >
            {data.map((item, index) => (
              <SortableItem
                disabled={disabled}
                handleDeleteItem={handleDeleteItem}
                handleInputChange={handleInputChange}
                handlePreview={onPreview}
                index={index}
                item={item}
                itemDetail={
                  item.type === 'string'
                    ? item
                    : variablesMap[(item.value as VariableRef).id]
                }
                itemsLength={itemsLength}
                key={item.id}
                previewVariableIndex={previewVariableIndex}
              />
            ))}
          </SortableContext>
        </DndContext>
      </div>
      <button
        className={clsx(
          'absolute right-1 top-1/2 -translate-y-1/2 flex p-1.5 rounded',
          {
            'text-gray-400  !cursor-not-allowed': disabled,
            'text-blue-500 hover:bg-blue-500 hover:text-white': !disabled,
          },
        )}
        disabled={disabled}
        onClick={() => {
          setShowVariableMenu(true);
        }}
        type="button"
      >
        <AddCircleOutlineOutlined className="!w-5 !h-5" />
      </button>
      {showVariableMenu ? (
        <VariablesMenu
          allowAddVariable={allowAddVariable}
          anchorEl={containerRef.current}
          onAddNew={handleAddNew}
          onClose={handleCloseMenu}
          onSelect={handleAddVariable}
          open={showVariableMenu}
          variables={variables}
        />
      ) : null}
    </div>
  );
}

export default VariableInput;
