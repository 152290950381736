"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkflowMetadata = exports.WorkflowStatus = exports.WorkflowStatusEnum = void 0;
var types_shared_1 = require("types-shared");
var WorkflowStatusEnum;
(function (WorkflowStatusEnum) {
    WorkflowStatusEnum["ProcessingImport"] = "processing_import";
    WorkflowStatusEnum["ProcessedImport"] = "processed_import";
})(WorkflowStatusEnum || (exports.WorkflowStatusEnum = WorkflowStatusEnum = {}));
exports.WorkflowStatus = types_shared_1.z.nativeEnum(WorkflowStatusEnum);
exports.WorkflowMetadata = types_shared_1.z.object({
    userId: types_shared_1.z.string(),
    workflowId: types_shared_1.z.string(),
    createdAt: types_shared_1.z
        .union([types_shared_1.z.string(), types_shared_1.z.date()])
        .transform(function (x) { return (typeof x === 'string' ? new Date(x) : x); }),
    uploadedAt: types_shared_1.z
        .union([types_shared_1.z.string(), types_shared_1.z.date()])
        .transform(function (x) { return (typeof x === 'string' ? new Date(x) : x); })
        .optional(),
    updatedAt: types_shared_1.z
        .union([types_shared_1.z.string(), types_shared_1.z.date()])
        .transform(function (x) { return (typeof x === 'string' ? new Date(x) : x); })
        .optional(),
    workflowName: types_shared_1.z.string(),
    deletedAt: types_shared_1.z
        .union([types_shared_1.z.string(), types_shared_1.z.date()])
        .transform(function (x) { return (typeof x === 'string' ? new Date(x) : x); })
        .optional(),
    lastRunAt: types_shared_1.z
        .union([types_shared_1.z.string(), types_shared_1.z.date()])
        .transform(function (x) { return (typeof x === 'string' ? new Date(x) : x); })
        .optional(),
    committedAt: types_shared_1.z
        .union([types_shared_1.z.string(), types_shared_1.z.date()])
        .transform(function (x) { return (typeof x === 'string' ? new Date(x) : x); })
        .optional(),
    successCount: types_shared_1.z.number().optional(),
    errorCount: types_shared_1.z.number().optional(),
    totalRunDuration: types_shared_1.z.number().optional(),
    status: exports.WorkflowStatus.optional(),
});
