import { clsx } from 'clsx';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Radio } from 'ui-kit';
import type { VariableRef, BaseQueryPaths } from 'types-shared';
import FormControlLabel from '@mui/material/FormControlLabel';
import { elementText } from 'types-shared';

export interface Item {
  id: string;
  type: 'string' | 'variable';
  value: string | VariableRef;
}

interface Props {
  data: Item[];
  disabled?: boolean;
  className?: string;
  options: BaseQueryPaths[];
  onChange: (val: Item[]) => void;
}

function MultipleChoice({
  className,
  data,
  disabled,
  onChange,
  options,
}: Props) {
  const handleItemSelect = (value: number) => {
    const itemId = uuidv4();
    onChange([
      {
        id: itemId,
        type: 'string',
        value: value.toString(),
      },
    ]);
  };

  return (
    <div
      className={clsx(
        'relative flex flex-col items-start max-w-full rounded',
        className,
      )}
    >
      {options.map((queryPath, i) => {
        const optionText = elementText(queryPath) || `Option ${i + 1}`;

        return (
          <FormControlLabel
            className={clsx(
              '!mx-0 !border-b !w-full',
              optionText.length > 35 ? '!items-start !py-2' : '!items-center',
            )}
            control={
              <Radio
                checked={i.toString() === data[0]?.value}
                className={clsx('!-ml-3', {
                  '!-mt-1.5': optionText.length > 35,
                })}
                color="secondary"
                disabled={disabled}
                onClick={() => {
                  handleItemSelect(i);
                }}
              />
            }
            key={queryPath.id ?? queryPath.cssPath}
            label={optionText}
          />
        );
      })}
    </div>
  );
}

export default MultipleChoice;
