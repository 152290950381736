import { UploadTypeEnum, axios, createZodFetcher, getExecutionSchema, getMultipleExecutionsSchema, listExecutionsSchema, popExecutionsSchema, putExecutionDataSchema, queueExecutionsSchema, updateExecutionSchema, zodAxios, } from 'api-types-shared';
import { ScrapeMap } from 'types-shared';
import { apiEndpoints } from 'ui-kit';
import ky from 'ky';
import { getBlobFromS3, uploadBlobToS3 } from './utils';
export class ExecutionSDK {
    kyFetcher;
    endpoint;
    constructor(env, opts) {
        this.kyFetcher = createZodFetcher(opts ? ky.extend(opts) : ky);
        this.endpoint = apiEndpoints[env].executionApiV1;
    }
    _updateExecution = (req) => {
        return this.kyFetcher(updateExecutionSchema.response, `${this.endpoint}/${req.params.executionId}`, {
            method: 'PUT',
            body: JSON.stringify(req.body),
        });
    };
    updateExecution = ({ executionId, status, statusDescr, }) => {
        return this._updateExecution({
            params: { executionId },
            body: { status, statusDescr },
            query: null,
        });
    };
    _putExecutionData = (req) => {
        // TODO(michael): Swap to use kyFetcher with auth
        return zodAxios(putExecutionDataSchema.response, `${this.endpoint}/${req.params.executionId}/data`, {
            method: 'PUT',
            params: { uploadType: req.query.uploadType },
            data: req.body,
        });
    };
    putExecutionData = async ({ executionId, data, uploadType, dataId, name, }) => {
        const { uploadUrl } = await this._putExecutionData({
            params: { executionId },
            body: { dataId, name },
            query: { uploadType },
        });
        if (uploadType === UploadTypeEnum.Image) {
            await uploadBlobToS3(data, uploadUrl);
        }
        else if (uploadType === UploadTypeEnum.Variables) {
            await axios(uploadUrl, {
                method: 'PUT',
                data,
            });
        }
        else if (uploadType === UploadTypeEnum.Artifact) {
            await uploadBlobToS3(data, uploadUrl);
        }
        return { executionId, dataId };
    };
    _getExecution = (req) => {
        return this.kyFetcher(getExecutionSchema.response, `${this.endpoint}/${req.params.executionId}`, {
            method: 'get',
        });
    };
    getExecution = async (executionId) => {
        const { metadata, imageUrls, variablesUrls, artifacts } = await this._getExecution({
            params: { executionId },
        });
        console.log(metadata, imageUrls, variablesUrls);
        const imageArrayPromise = Promise.all(imageUrls.map((imageUrl) => getBlobFromS3(imageUrl)));
        const variablesArrayPromise = Promise.all(variablesUrls.map((variablesUrl) => zodAxios(ScrapeMap, variablesUrl, {
            method: 'get',
        })));
        const [imageArray, variablesArray] = await Promise.all([
            imageArrayPromise,
            variablesArrayPromise,
        ]);
        const variables = variablesArray.reduce((acc, variablesSub) => ({ ...acc, ...variablesSub }), {});
        return { metadata, imageArray, variables, artifacts };
    };
    getMultipleExecutions = (req) => {
        return this.kyFetcher(getMultipleExecutionsSchema.response, `${this.endpoint}/get-multiple`, {
            method: 'POST',
            body: JSON.stringify(req.body),
        });
    };
    listExecutions = async (req) => {
        let url = `${this.endpoint}/list?workflowId=${req.query.workflowId}`;
        if (req.query.setId) {
            url += `&setId=${req.query.setId}`;
        }
        const resp = await this.kyFetcher(listExecutionsSchema.response, url, {
            method: 'get',
        });
        return resp.filter((exec) => !exec.adminRun);
    };
    queueExecutions = (req) => {
        return this.kyFetcher(queueExecutionsSchema.response, `${this.endpoint}/queue/${req.params.workflowId}`, {
            method: 'PUT',
            body: JSON.stringify(req.body),
        });
    };
    popExecutions = (req) => {
        return this.kyFetcher(popExecutionsSchema.response, `${this.endpoint}/pop`, {
            method: 'PUT',
            body: JSON.stringify(req.body),
        });
    };
}
