import './config/dayjs';
import './index.css';
import 'ui-kit/dist/tailwind.css';

import CssBaseline from '@mui/material/CssBaseline';
import { QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider } from 'react-router-dom';
import {
  AlertWrapper,
  DialogWrapper,
  ErrorBoundary,
  UIKITProvider,
  useAuth0,
} from 'ui-kit';
/** Services */
import { queryClient } from './config/react-query';
import { APIProvider } from './hooks/useApi';
import { useAnalytics } from './hooks/useAnalytics';
import { router } from './router';
import theme from './theme';

export default function Initializer() {
  const { getAccessTokenSilently } = useAuth0();

  useAnalytics();
  return (
    <UIKITProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <ErrorBoundary>
        <DialogWrapper />
        <AlertWrapper />
        <APIProvider
          initialState={{
            kyOpts: {
              hooks: {
                beforeRequest: [
                  async (request) => {
                    request.headers.set('User-Agent', 'ky');
                    if (process.env.REACT_APP_AUTH0_DOMAIN) {
                      const accessToken = await getAccessTokenSilently();
                      request.headers.set(
                        'Authorization',
                        `Bearer ${accessToken}`,
                      );
                    }
                  },
                ],
              },
            },
          }}
        >
          <QueryClientProvider client={queryClient}>
            <RouterProvider router={router} />
          </QueryClientProvider>
        </APIProvider>
      </ErrorBoundary>
    </UIKITProvider>
  );
}
