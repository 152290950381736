import type { SVGProps } from 'react';
import React from 'react';

export default function FilePlaceholderIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      fill="none"
      height="159"
      viewBox="0 0 125 159"
      width="125"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M70.9584 3.38235C70.1507 3.375 69.2437 3.375 68.207 3.375H30.3601C20.8858 3.375 16.1452 3.375 12.5265 5.21881C9.34345 6.84067 6.75741 9.42671 5.13555 12.6098C3.29175 16.2285 3.29175 20.9691 3.29175 30.4433V128.56C3.29175 138.034 3.29175 142.769 5.13556 146.388C6.75741 149.571 9.34345 152.161 12.5265 153.783C16.1417 155.625 20.8767 155.625 30.3325 155.625L94.6679 155.625C104.124 155.625 108.852 155.625 112.467 153.783C115.65 152.161 118.245 149.571 119.866 146.388C121.708 142.772 121.708 138.044 121.708 128.589V56.8797C121.708 55.8417 121.708 54.9337 121.7 54.125M70.9584 3.38235C73.3744 3.40434 74.9029 3.49211 76.3619 3.84241C78.0881 4.25681 79.7369 4.94203 81.2505 5.86955C82.9571 6.91537 84.4225 8.3808 87.3464 11.3047L113.783 37.7411C116.709 40.6668 118.163 42.1257 119.209 43.8328C120.137 45.3464 120.823 46.9968 121.238 48.7229C121.588 50.1814 121.677 51.7102 121.7 54.125M70.9584 3.38235V27.0583C70.9584 36.5325 70.9584 41.2664 72.8022 44.885C74.4241 48.0681 77.0101 50.6611 80.1932 52.283C83.8083 54.125 88.5433 54.125 97.999 54.125H121.7M121.7 54.125H121.71"
        stroke="#246BFA"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
    </svg>
  );
}
