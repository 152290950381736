import { produce } from 'immer';
import type {
  Target,
  TargetMap,
  TargetStateData,
} from 'types-shared/workflowTypes';
import type { StateCreator } from 'zustand';

export const initialTargetState: TargetStateData = {
  targets: {},
};

export interface TargetStateActions {
  setTargets: (targets: TargetMap) => void;
  addTarget: (target: Target) => void;
  deleteTarget: (targetId: string) => void;
  updateTarget: (target: Target) => void;
  resetTargets: () => void;
}

export const TargetState: StateCreator<TargetStateData & TargetStateActions> = (
  set,
) => ({
  ...initialTargetState,
  setTargets: (targets: TargetMap) => {
    set(
      produce((state: TargetStateData) => {
        state.targets = targets;
      }),
    );
  },
  addTarget: (target: Target) => {
    set(
      produce((state: TargetStateData) => {
        state.targets[target.id] = target;
      }),
    );
  },
  deleteTarget: (targetId: string) => {
    set(
      produce((state: TargetStateData) => {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete state.targets[targetId];
      }),
    );
  },
  updateTarget: (target: Target) => {
    set(
      produce((state: TargetStateData) => {
        state.targets[target.id] = target;
      }),
    );
  },
  resetTargets: () => {
    set(initialTargetState);
  },
});
