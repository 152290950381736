import { EditPencilIcon } from 'ui-kit';
import { clsx } from 'clsx';
import React from 'react';

interface Props {
  showFullContent: boolean;
  onClick: () => void;
}
export function PlaceholderNodeCore({ showFullContent, onClick }: Props) {
  return (
    <div
      className={clsx('flex-1 flex justify-center items-center', {
        'rounded border border-dashed border-gray-400': showFullContent,
      })}
    >
      <button
        className={clsx('p-4 bg-color-extralight-blue rounded-lg', {
          '!p-6 !m-auto': showFullContent,
        })}
        onClick={onClick}
        type="button"
      >
        <EditPencilIcon
          className={clsx(
            '!text-info',
            showFullContent ? '!h-6 !w-6' : '!h-16 !w-16',
          )}
        />
      </button>
    </div>
  );
}
