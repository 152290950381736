import { type WorkflowNodeProps } from 'types-shared';
import NodeElement from './index';
import { EditorStore, type EditorStoreProps } from '../../store/EditorState';
import { useShallow } from 'zustand/react/shallow';
import { ConditionalNodeCore, useEditingNodeId } from 'editor-shared';
import isNil from 'lodash/isNil';

export default function ConditionalNode(workflowData: WorkflowNodeProps) {
  const { setEditingNodeId } = useEditingNodeId();
  const { nodes } = EditorStore(
    useShallow((state: EditorStoreProps) => ({
      nodes: state.nodes,
    })),
  );

  const node = nodes.find((n) => n.id === workflowData.id);

  if (isNil(node)) {
    throw new Error('Node not found');
  }

  return (
    <NodeElement showEditButton workflowData={workflowData}>
      <ConditionalNodeCore
        node={node}
        onClick={() => {
          setEditingNodeId(workflowData.id);
        }}
      />
    </NodeElement>
  );
}
