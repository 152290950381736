import type { StateCreator } from 'zustand';
import { produce } from 'immer';

export interface ExecutionStateData {
  watchedExecutionIds: string[];
}

export const initialExecutionState: ExecutionStateData = {
  watchedExecutionIds: [],
};

export interface ExecutionStateActions {
  addWatchedExecutionIds: (executionIds: string[]) => void;
}

export const ExecutionState: StateCreator<
  ExecutionStateData & ExecutionStateActions
> = (set) => ({
  ...initialExecutionState,
  addWatchedExecutionIds: (executionIds: string[]) => {
    set(
      produce((state: ExecutionStateData) => {
        state.watchedExecutionIds.push(...executionIds);
      }),
    );
  },
});
