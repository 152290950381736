import { Fragment, useEffect, useMemo, useState, useCallback } from 'react';
import {
  AutoFixActiveIcon,
  AutoFixInactiveIcon,
  WarningAmberOutlined,
} from 'assets-shared';
import type { Variable, WorkflowAction, ScrapeVariable } from 'types-shared';
import { ActionsEnum, VariableTypeEnum } from 'types-shared';
import { Button, IconButton, Input, Select } from 'ui-kit';
import { v4 as uuid } from 'uuid';
import type { Item } from '../VariableInput';

import { EditorStore } from '../../store/EditorState';
import { useTransformData } from '../../hooks';
import { Transformations } from 'editor-shared';

interface FormValues {
  name: string;
  key: string;
  transformQuery?: string | null;
  previewTransform?: string | null;
}

interface Props {
  action?: WorkflowAction;
  variable?: Item | ScrapeVariable;
  onCancel: () => void;
}

function AddVariable({ action, variable, onCancel }: Props) {
  const {
    datasourceMetadata,
    tableData,
    addVariable,
    updateVariable,
    variables,
  } = EditorStore();
  const { name: datasourceName, datasourceId } = datasourceMetadata ?? {};
  const [defaultRow = {}] = tableData?.rowData ?? [];
  const isScrape = variable?.type === VariableTypeEnum.Scrape;

  const datasourceColumns = useMemo(
    () =>
      tableData ? tableData.columnDefinitions.map((col) => col.field) : [],
    [tableData],
  );

  const variableData: Variable | null = useMemo(() => {
    if (!isScrape && variable) {
      return variables[(variable.value as { id: string }).id];
    }
    return null;
  }, [variable, isScrape, variables]);

  const [formValues, setFormValues] = useState<FormValues>({
    name: '',
    key: '',
    transformQuery: '',
    previewTransform: '',
  });

  useEffect(() => {
    if (variableData) {
      const {
        name = '',
        data,
        transformQuery,
        previewTransform,
      } = variableData;
      setFormValues({
        name,
        key: (data as { key: string }).key,
        previewTransform,
        transformQuery,
      });
    }
    if (isScrape) {
      setFormValues({
        key: '',
        transformQuery: '',
        previewTransform: '',
        name: variable.name,
      });
    }
  }, [variableData, isScrape, variable]);

  const [showTransformations, setShowTransformations] =
    useState<boolean>(false);
  const toggleTransformations = () => {
    setShowTransformations(!showTransformations);
  };

  const createVariable = () => {
    if (isScrape) {
      updateVariable({ ...variable, name: formValues.name });
      onCancel();
      return;
    }
    if (!datasourceId) {
      throw new Error('Datasource not found!');
    }
    const { name, key, transformQuery, previewTransform } = formValues;
    const variableId = uuid();
    const datasourceVariable: Variable = {
      id: variableData ? variableData.id : variableId,
      name,
      type: VariableTypeEnum.Datasource,
      transformQuery,
      previewTransform,
      data: {
        datasourceId,
        key,
      },
    };
    if (variableData) {
      updateVariable(datasourceVariable);
    } else {
      addVariable(datasourceVariable);
    }

    if (action?.variableId && !variableData) {
      const actionVariable = variables[action.variableId];
      updateVariable({
        ...actionVariable,
        data:
          action.actionType === ActionsEnum.Select
            ? [{ id: datasourceVariable.id }]
            : [
                ...(actionVariable.data as unknown[]),
                { id: datasourceVariable.id },
                '',
              ],
      } as Variable);
    }
    onCancel();
  };

  const { mutateAsync: transformData, status: transformDataStatus } =
    useTransformData();

  const onTransformData = useCallback(
    async (prompt: string) => {
      if (prompt && defaultRow[formValues.key]) {
        const value = await transformData({
          data: defaultRow[formValues.key] as string,
          prompt,
        });
        return value?.processedData;
      }
    },
    [defaultRow, formValues.key, transformData],
  );

  return (
    <div>
      <div className="border my-6 -mx-4" />
      <div className="text-sm flex flex-col">
        {!variableData && !isScrape ? (
          <span className="font-medium mb-5 text-info">New Variable</span>
        ) : null}
        {isScrape ? (
          <span className="font-medium mb-5 text-info">Variable Preview</span>
        ) : null}
        {!isScrape ? (
          <>
            <div className="flex flex-col">
              <Select
                classes={{ select: '!py-4' }}
                defaultValue={datasourceName}
                disabled
                getLabel={(opt: string) => opt}
                getValue={(opt: string) => opt}
                label="Variable Source"
                labelId="template-select-variable-source"
                onChange={() => ''}
                options={datasourceName ? [datasourceName] : []}
              />
            </div>
            <div className="flex flex-col mt-3">
              <Select
                classes={{ select: '!py-4' }}
                getLabel={(opt: string) => opt}
                getValue={(opt: string) => opt}
                label="Associated column"
                labelId="template-select-variable-source"
                onChange={(e) => {
                  setFormValues((form) => ({
                    ...form,
                    key: e.target.value,
                  }));
                }}
                options={datasourceColumns}
                value={formValues.key}
              />
            </div>
          </>
        ) : null}

        <Input
          classes={{ wrapper: 'flex flex-col my-3' }}
          floatingLabel
          label="Variable Name"
          onChange={(name: string) => {
            setFormValues((form) => ({
              ...form,
              name,
            }));
          }}
          placeholder="Name"
          value={formValues.name}
        />

        {formValues.key || isScrape ? (
          <>
            <span className="text-gray-500 text-sm py-7">
              Preview the variable value and format it using our GPT
              transformation tool.
            </span>
            {variableData?.transformQuery ? (
              <>
                <span className="text-black text-sm pb-0.5">
                  Transformation applied
                </span>
                <span className="text-gray-400 text-sm pb-7">
                  {variableData.previewTransform}
                </span>
              </>
            ) : null}
            <div className="flex flex-row gap-4 mb-7 items-center">
              <div className="grow flex-col pt-1.5 bg-gray-100 rounded-tl-lg rounded-tr-lg">
                <p className="text-xs text-gray-500 px-3">Value preview</p>
                <p className="text-base text-gray-500 mt-1 px-3 mb-1.5">
                  {isScrape
                    ? variable.data.scrapedText
                        ?.split('\n')
                        .map((line: string) => (
                          <Fragment key={line}>
                            {line}
                            <br />
                          </Fragment>
                        ))
                    : formValues.transformQuery ||
                      (defaultRow[formValues.key] as string)}
                </p>
                {!formValues.transformQuery ? (
                  <div className="border border-dashed border-slate-300" />
                ) : (
                  <div className="!bg-gradient-to-r from-primary-blue to-primary-purple pb-0.5" />
                )}
              </div>

              <IconButton
                className="flex-none h-6 w-6"
                onClick={toggleTransformations}
              >
                {showTransformations ? (
                  <AutoFixActiveIcon />
                ) : (
                  <AutoFixInactiveIcon />
                )}
              </IconButton>
            </div>
          </>
        ) : null}
      </div>

      {showTransformations ? (
        <Transformations
          formValues={formValues}
          onApply={(transformQuery: string, previewTransform: string) => {
            setFormValues((form) => ({
              ...form,
              transformQuery,
              previewTransform,
            }));
          }}
          onClose={toggleTransformations}
          onTransformData={onTransformData}
          transformDataStatus={transformDataStatus}
        />
      ) : null}

      {variableData ? (
        <div className="flex flex-row justify-between gap-4 my-6 px-4 py-3.5 rounded-lg bg-warning-light">
          <WarningAmberOutlined className="text-warning" />
          <div className="flex flex-col">
            <p className="text-base text-warning-dark font-medium">
              Variable changes are global
            </p>
            <p className="text-sm text-warning-dark">
              The changes you make over existing variables will impact all the
              places where the variable is used.
            </p>
          </div>
        </div>
      ) : null}

      <div className="flex flex-row justify-between gap-4 mt-auto">
        <Button
          className="flex-1"
          color="secondary"
          disabled={
            !formValues.name ||
            showTransformations ||
            (!isScrape && !formValues.key)
          }
          onClick={createVariable}
          variant="contained"
        >
          {variableData || isScrape ? 'SAVE CHANGES' : 'ADD VARIABLE'}
        </Button>
        <Button
          className="flex-1"
          color="secondary"
          onClick={onCancel}
          variant="outlined"
        >
          CANCEL
        </Button>
      </div>
    </div>
  );
}

export default AddVariable;
