import { MoreVert } from '@mui/icons-material';
import { ArrowLeftIcon } from 'assets-shared';
import React, { useState } from 'react';
import {
  Button,
  DeleteIcon,
  IconButton,
  Input,
  Menu,
  MenuItem,
  SolaModal,
  Typography,
} from 'ui-kit';
import type { WorkflowEdge } from 'types-shared';

interface Props {
  edge: WorkflowEdge;
  onCancel: () => void;
  onDelete: () => void;
  onUpdateName: (name: string) => void;
}

export function EditBranch({ edge, onCancel, onDelete, onUpdateName }: Props) {
  const [edgeName, setEdgeName] = useState<string>(
    typeof edge.label === 'string' ? edge.label : '',
  );
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState<boolean>(false);
  const menuOpen = Boolean(menuAnchorEl);

  return (
    <>
      <div className="absolute left-2 top-2 bottom-2 w-120 bg-white rounded-lg z-[10] flex flex-col justify-between space-y-5">
        <div className="overflow-auto">
          <div className="flex justify-between items-center pt-8 px-8">
            <div className="flex items-center">
              <span
                className="flex !border !border-solid !border-info !rounded-lg cursor-pointer mr-2"
                onClick={onCancel}
                role="presentation"
              >
                <ArrowLeftIcon className="text-info !h-7 !w-7" />
              </span>
              <span className="text-sm font-medium">
                Conditional block /&nbsp;
              </span>
              <span className="text-primary-blue">Branch</span>
            </div>
          </div>
          <div className="py-6 px-8 flex justify-between items-center">
            <h2 className="text-lg font-medium text-info-dark">
              Branch: {edge.label}
            </h2>
            <div>
              <IconButton
                aria-controls={menuOpen ? 'basic-menu' : undefined}
                aria-expanded={menuOpen ? 'true' : undefined}
                aria-haspopup="true"
                aria-label="more"
                onClick={(event) => {
                  setMenuAnchorEl(event.currentTarget);
                }}
              >
                <MoreVert />
              </IconButton>
              <Menu
                anchorEl={menuAnchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                onClose={() => {
                  setMenuAnchorEl(null);
                }}
                open={menuOpen}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem
                  onClick={() => {
                    setMenuAnchorEl(null);
                    setIsDeletePopupOpen(true);
                  }}
                >
                  <p className="text-error min-w-50">Delete branch</p>
                  <DeleteIcon className="!w-4 !h-4 text-error ml-8 !fill-none" />
                </MenuItem>
              </Menu>
            </div>
          </div>
          <hr className="border-b border-color-grey" />
          <div className="flex-1 flex flex-col gap-4 mt-6 px-8">
            <p className="font-bold text-sm">Branch Name</p>
            <Input
              floatingLabel
              label="Branch Name"
              onChange={(e) => {
                setEdgeName(e);
              }}
              placeholder="Branch Name"
              value={edgeName}
            />
          </div>
        </div>
        <div className="flex w-full gap-9 p-8">
          <Button
            className="!flex-1"
            color="secondary"
            fullWidth
            onClick={() => {
              onUpdateName(edgeName);
            }}
            variant="contained"
          >
            Save changes
          </Button>
          <Button
            className="!flex-1"
            color="secondary"
            fullWidth
            onClick={onCancel}
            variant="outlined"
          >
            cancel
          </Button>
        </div>
      </div>
      {isDeletePopupOpen ? (
        <SolaModal
          className="!max-w-2xl"
          onClose={() => {
            setIsDeletePopupOpen(false);
            setMenuAnchorEl(null);
          }}
          open={isDeletePopupOpen}
          showCloseIcon={false}
        >
          <Typography className="font-bold" variant="h6">
            Are you sure do you want to delete this branch?
          </Typography>
          <p className="mt-11 mb-5 text-info-dark">Branch Name: {edge.label}</p>
          <p className="font-xs">
            The blocks from this branch will be deleted with all their actions
            and variables and won’t be able in future executions. Previous
            executions will be saved.
          </p>
          <div className="flex mt-11 gap-4">
            <Button
              className="max-w-max"
              color="error"
              fullWidth
              onClick={() => {
                setIsDeletePopupOpen(false);
                onDelete();
              }}
              variant="contained"
            >
              yes, Delete branch
            </Button>
            <Button
              className="max-w-max"
              color="secondary"
              fullWidth
              onClick={onCancel}
              variant="text"
            >
              cancel
            </Button>
          </div>
        </SolaModal>
      ) : null}
    </>
  );
}
