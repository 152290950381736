import type { TooltipProps } from '@mui/material/Tooltip';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';

type Props = Pick<
  TooltipProps,
  'title' | 'children' | 'placement' | 'hidden' | 'classes' | 'arrow'
>;

export default function CustomTooltip({ children, ...props }: Props) {
  return (
    <Tooltip placement={props.placement ?? 'top'} {...props}>
      <span className="flex">{children}</span>
    </Tooltip>
  );
}
