import {
  HelpOutlineOutlined,
  MapOutlined,
  NearMeOutlined,
  PanToolOutlined,
} from 'assets-shared';
import { clsx } from 'clsx';
import React, { useState } from 'react';
import {
  ControlButton,
  Controls,
  MiniMap,
  useStore,
} from 'types-shared/reactflow';
import { contactModalEventChannel } from './ContactModal';

interface Props {
  navMode: 'pan' | 'trackpad';
  setNavMode: (mode: 'pan' | 'trackpad') => void;
}

function FlowViewControls({ navMode, setNavMode }: Props) {
  const [showMinimap, setShowMinimap] = useState<boolean>(false);
  const zoomLevel = useStore((store) => store.transform[2]);

  return (
    <>
      <div className="bg-white absolute left-2 top-1/2 -translate-y-1/2 rounded-lg overflow-hidden z-[6]">
        <ControlButton
          onClick={() => {
            setNavMode('trackpad');
          }}
        >
          <NearMeOutlined
            className={clsx('!max-w-7 !max-h-7 !w-6 !h-6', {
              '!text-info': navMode === 'trackpad',
            })}
          />
        </ControlButton>
        <ControlButton
          className="!border-t !border-t-gray-300 !border-solid !border-x-0"
          onClick={() => {
            setNavMode('pan');
          }}
        >
          <PanToolOutlined
            className={clsx('!max-w-7 !max-h-7 !w-5 !h-6', {
              '!text-info': navMode === 'pan',
            })}
          />
        </ControlButton>
      </div>
      <div
        className={clsx(
          'absolute right-2 bottom-2 z-[6] w-50 rounded-lg overflow-hidden',
          {
            '!bg-white': showMinimap,
            '!bg-transparent': !showMinimap,
          },
        )}
      >
        {showMinimap ? (
          <MiniMap className="!static !m-0" pannable zoomable />
        ) : null}
        <Controls
          className="flex !static !m-0 !shadow-none"
          showFitView={false}
          showInteractive={false}
        >
          <ControlButton
            className="order-1 mr-2 rounded-lg group"
            onClick={() => {
              setShowMinimap(!showMinimap);
            }}
          >
            <MapOutlined
              className={clsx('!max-w-5 !max-h-5 group-hover:text-info', {
                '!text-info': showMinimap,
              })}
            />
          </ControlButton>
          <ControlButton className="order-3 !rounded-none !cursor-auto group">
            <span className="text-xs mx-4 font-medium">
              {Math.round(zoomLevel * 100)}%
            </span>
          </ControlButton>
          <ControlButton
            className="order-5 ml-2 rounded-lg group"
            onClick={() => {
              contactModalEventChannel.emit('open');
            }}
          >
            <HelpOutlineOutlined className="!max-w-5 !max-h-5 group-hover:text-info" />
          </ControlButton>
        </Controls>
      </div>
    </>
  );
}

export default FlowViewControls;
