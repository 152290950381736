"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExecutionAction = exports.ExecutionRun = exports.ExecutionBody = exports.ExecutionWorkflowData = exports.ExecutionItem = exports.ExecutionBase = exports.ExecutionStatus = exports.ExecutionTrigger = exports.ExecutionTriggerEnum = exports.ExecutionStatusEnum = void 0;
var zod_1 = require("zod");
var helper_types_1 = require("../helper-types");
var workflowTypes_1 = require("../workflowTypes");
var ExecutionStatusEnum;
(function (ExecutionStatusEnum) {
    ExecutionStatusEnum["Queued"] = "QUEUED";
    ExecutionStatusEnum["PendingUser"] = "PENDING_USER";
    ExecutionStatusEnum["PendingSystem"] = "PENDING_SYSTEM";
    ExecutionStatusEnum["Canceled"] = "CANCELED";
    ExecutionStatusEnum["Running"] = "RUNNING";
    ExecutionStatusEnum["Success"] = "SUCCESS";
    ExecutionStatusEnum["Failed"] = "FAILED";
    ExecutionStatusEnum["Terminated"] = "TERMINATED";
    ExecutionStatusEnum["Paused"] = "PAUSED";
})(ExecutionStatusEnum || (exports.ExecutionStatusEnum = ExecutionStatusEnum = {}));
var ExecutionTriggerEnum;
(function (ExecutionTriggerEnum) {
    ExecutionTriggerEnum["Manual"] = "MANUAL";
    ExecutionTriggerEnum["Scheduled"] = "SCHEDULED";
    ExecutionTriggerEnum["Webhook"] = "WEBHOOK";
    ExecutionTriggerEnum["API"] = "API";
})(ExecutionTriggerEnum || (exports.ExecutionTriggerEnum = ExecutionTriggerEnum = {}));
exports.ExecutionTrigger = zod_1.z.nativeEnum(ExecutionTriggerEnum);
exports.ExecutionStatus = zod_1.z.nativeEnum(ExecutionStatusEnum);
exports.ExecutionBase = zod_1.z.object({
    executionId: helper_types_1.uuidSchema,
    workflowId: helper_types_1.uuidSchema,
    setId: helper_types_1.uuidSchema,
    createdAt: zod_1.z.string(),
    status: exports.ExecutionStatus,
    recordId: zod_1.z.string(),
    trigger: exports.ExecutionTrigger,
    adminRun: zod_1.z.boolean().optional().default(false),
    startedAt: zod_1.z.string().optional(),
    lastUpdatedAt: zod_1.z.string().optional(),
    statusDescr: zod_1.z.string().optional(),
});
// TODO(michael): Potentially delete this???
exports.ExecutionItem = exports.ExecutionBase.extend({
    createdAt: zod_1.z.date(),
    updatedAt: zod_1.z.date().optional(),
    startedAt: zod_1.z.date().optional(),
    endedAt: zod_1.z.date().optional(),
});
exports.ExecutionWorkflowData = workflowTypes_1.WorkflowData.pick({
    nodes: true,
    edges: true,
}).extend({
    variables: workflowTypes_1.VariableMap,
    targets: workflowTypes_1.TargetMap,
});
exports.ExecutionBody = exports.ExecutionBase.extend({
    recordData: zod_1.z.record(zod_1.z.string(), zod_1.z.unknown()).optional(),
});
exports.ExecutionRun = exports.ExecutionBody.extend({
    currentNodeId: helper_types_1.uuidSchema.nullable(),
    currentStepId: helper_types_1.uuidSchema.nullable(),
    passedNodeId: helper_types_1.uuidSchema.nullable().default(null),
    passedStepId: helper_types_1.uuidSchema.nullable().default(null),
    scrapeData: zod_1.z.record(helper_types_1.uuidSchema, zod_1.z.unknown()).optional(),
    branchOptions: zod_1.z
        .array(zod_1.z.object({ id: helper_types_1.uuidSchema, name: zod_1.z.string() }))
        .nullable()
        .default(null),
    selectedBranch: helper_types_1.uuidSchema.nullable().default(null),
});
exports.ExecutionAction = workflowTypes_1.WorkflowAction.extend({
    target: workflowTypes_1.Target,
    variable: helper_types_1.uuidSchema,
});
