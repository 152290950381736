import type { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React from 'react';

export default function PuzzleIcon(props: SvgIconProps) {
  return (
    <svg
      {...props}
      fill="none"
      height="61"
      viewBox="0 0 61 61"
      width="61"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50.8333 17.7917H45.3653C44.1008 17.7917 43.2083 16.5145 43.2083 15.25C43.2083 11.0388 39.7945 7.625 35.5833 7.625C31.3722 7.625 27.9583 11.0388 27.9583 15.25C27.9583 16.5145 27.0658 17.7917 25.8013 17.7917H20.3333C18.9296 17.7917 17.7917 18.9296 17.7917 20.3333V25.8013C17.7917 27.0658 16.5145 27.9583 15.25 27.9583C11.0388 27.9583 7.625 31.3722 7.625 35.5833C7.625 39.7945 11.0388 43.2083 15.25 43.2083C16.5145 43.2083 17.7917 44.1008 17.7917 45.3653V50.8333C17.7917 52.2371 18.9296 53.375 20.3333 53.375L50.8333 53.3751C52.2371 53.3751 53.375 52.237 53.375 50.8333V45.3653C53.375 44.1008 52.0978 43.2083 50.8333 43.2083C46.6222 43.2083 43.2083 39.7945 43.2083 35.5833C43.2083 31.3722 46.6222 27.9583 50.8333 27.9583C52.0978 27.9583 53.375 27.0658 53.375 25.8013L53.3751 20.3333C53.3751 18.9296 52.237 17.7917 50.8333 17.7917Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
}
