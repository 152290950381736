import React, { useEffect, useState } from 'react';
import isNil from 'lodash/isNil';
import type {
  WorkflowEdge,
  WorkflowFreeformNode,
  WorkflowNode,
} from 'types-shared';
import { NodeStatusEnum, NodeTypesEnum } from 'types-shared';
import { v4 as uuid } from 'uuid';
import { ConditionalBlock } from './ConditionalBlock';
import { FreeFormBlock } from './FreeFormBlock';
import { OptionsBlock } from './OptionsBlock';
import { findSiblingNodeIds, getAllNodesAfter } from '../../utils/helper';
import set from 'lodash/set';

interface Props {
  nodeId: string;
  nodes: WorkflowNode[];
  edges: WorkflowEdge[];
  setNodes: (nodes: WorkflowNode[]) => void;
  setEdges: (edges: WorkflowEdge[]) => void;
  updateNode: (node: WorkflowNode) => void;
  addNodes: (nodes: WorkflowNode[]) => void;
  distanceBetweenNodes: number;
  onCancel: () => void;
}

export function EditNodePanel({
  nodeId,
  nodes,
  edges,
  setEdges,
  updateNode,
  addNodes,
  setNodes,
  distanceBetweenNodes,
  onCancel,
}: Props) {
  const [currentNodeType, setCurrentNodeType] = useState<string | null>(null);
  const [editingEdge, setEditingEdge] = useState<WorkflowEdge>();
  const selectedNode = nodes.find((node) => node.id === nodeId);

  const fixSiblingNodePositions = (
    sourceNode: WorkflowNode,
    _nodes: WorkflowNode[],
    _edges: WorkflowEdge[],
  ) => {
    const siblingNodeIds = findSiblingNodeIds(sourceNode, _edges);

    const noOfSiblings = siblingNodeIds.length;

    const gap = 300;
    const startY = sourceNode.position.y - ((noOfSiblings - 1) / 2) * gap;

    const yCoordinates = Array.from(
      { length: noOfSiblings },
      (_, index) => startY + index * gap,
    );

    siblingNodeIds.forEach((siblingNodeId, siblingIndex) => {
      const foundNode = _nodes.find((n) => n.id === siblingNodeId);
      const newYPosition = yCoordinates[siblingIndex];

      if (foundNode) {
        // update y coordinates of this node
        const foundNodeUpdated = {
          ...foundNode,
          position: {
            ...foundNode.position,
            y: newYPosition,
          },
        };
        updateNode(foundNodeUpdated);

        // find all nodes after this sibling node and update y coordinates of all of them
        const hasNodesAfter = getAllNodesAfter(
          foundNodeUpdated,
          _nodes,
          _edges,
        );
        if (hasNodesAfter.length > 0) {
          fixSiblingNodePositions(foundNodeUpdated, _nodes, _edges);
        }
      }
    });
  };

  const insertNode = (sourceId: string, onlyAddIfEmpty = false) => {
    const sourceNode = nodes.find((node) => node.id === sourceId);

    if (!sourceNode) {
      throw Error('sourceNode not found!');
    }

    if (onlyAddIfEmpty && sourceNode.type === NodeTypesEnum.Conditional) {
      const edge = edges.find((e) => e.source === sourceId);

      // if there is an exiting edge, don't create a new branch
      if (edge) {
        throw Error('existingEdge found!');
      }
    }

    const newNodeId = uuid();
    const newPositionX = sourceNode.position.x + distanceBetweenNodes;

    const newNode: WorkflowNode = {
      id: newNodeId,
      position: {
        ...sourceNode.position,
        x: newPositionX,
      },
      type: NodeTypesEnum.New,
      data: {
        nodeStatus: NodeStatusEnum.NotViewed,
      },
    };

    addNodes([newNode]);

    const siblingNodeIds = findSiblingNodeIds(sourceNode, edges);
    const noOfExistingSiblings = siblingNodeIds.length;

    const updatedEdges = [
      ...edges,
      {
        id: uuid(),
        source: sourceId,
        target: newNodeId,
        label: `Branch ${noOfExistingSiblings + 1}`,
      },
    ];
    setEdges(updatedEdges);

    fixSiblingNodePositions(sourceNode, [...nodes, newNode], updatedEdges);
  };

  const setNodeType = (nodeType: string) => {
    if (isNil(selectedNode)) {
      throw Error('selectedNode node not found!');
    }

    switch (nodeType) {
      case 'conditional': {
        updateNode({
          ...selectedNode,
          type: NodeTypesEnum.Conditional,
          name: 'New Conditional Block',
        });
        insertNode(selectedNode.id, true);
        break;
      }
      case 'freeform': {
        updateNode({
          ...selectedNode,
          type: NodeTypesEnum.Freeform,
          name: 'New Freeform Block',
        });
        break;
      }
    }

    setCurrentNodeType(nodeType);
  };

  const updateNodeProps = (key: string, value: string) => {
    setNodes(
      nodes.map((_node) => {
        if (_node.id === selectedNode?.id) {
          return set(_node, key, value);
        }
        return _node;
      }),
    );
  };

  const updateBranchName = (name: string) => {
    if (!editingEdge) {
      throw Error('editingEdge not found!');
    }

    setEdges(
      edges.map((edge) => {
        if (edge.id === editingEdge.id) {
          return {
            ...edge,
            label: name,
          };
        }
        return edge;
      }),
    );
    setEditingEdge(undefined);
  };

  const deleteBranch = () => {
    if (!editingEdge || !selectedNode) return;

    const updatedEdges = edges.filter((edge) => edge.id !== editingEdge.id);
    setEdges(updatedEdges);
    const updatedNodes = nodes.filter((n) => n.id !== editingEdge.target);
    setNodes(updatedNodes);
    setEditingEdge(undefined);

    fixSiblingNodePositions(selectedNode, updatedNodes, updatedEdges);
  };

  useEffect(() => {
    const currentNode = nodes.find((n) => n.id === nodeId);
    setCurrentNodeType(currentNode?.type ?? 'continue');
  }, [nodeId, nodes]);

  const handleOnCancel = () => {
    onCancel();
    setCurrentNodeType(null);
  };

  return nodeId ? (
    <>
      {currentNodeType !== 'conditional' && currentNodeType !== 'freeform' ? (
        <OptionsBlock onCancel={handleOnCancel} onContinue={setNodeType} />
      ) : null}

      {currentNodeType === 'conditional' && selectedNode ? (
        <ConditionalBlock
          deleteBranch={deleteBranch}
          edges={edges}
          editingEdge={editingEdge}
          insertNode={insertNode}
          node={selectedNode}
          onCancel={handleOnCancel}
          setEdges={setEdges}
          setEditingEdge={setEditingEdge}
          setNodes={setNodes}
          updateBranchName={updateBranchName}
          updateNodeName={(val: string) => {
            updateNodeProps('name', val);
          }}
        />
      ) : null}

      {currentNodeType === 'freeform' && selectedNode ? (
        <FreeFormBlock
          node={selectedNode as WorkflowFreeformNode}
          onCancel={handleOnCancel}
          updateNodeInstructions={(val: string) => {
            updateNodeProps('data.instructions', val);
          }}
          updateNodeName={(val: string) => {
            updateNodeProps('name', val);
          }}
          updateNodeStatus={(status) => {
            updateNodeProps('data.nodeStatus', status);
          }}
        />
      ) : null}
    </>
  ) : null;
}
