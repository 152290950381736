import 'types-shared/reactflow/dist/style.css';

import { clsx } from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import type {
  EdgeTypes,
  NodeTypes,
  ReactFlowInstance,
} from 'types-shared/reactflow';
import {
  Background,
  ConnectionLineType,
  ReactFlow,
  ReactFlowProvider,
  SelectionMode,
} from 'types-shared/reactflow';
import { Spinner } from 'ui-kit';
import { useShallow } from 'zustand/react/shallow';

import {
  useFetchDatasourceTable,
  useGetDatasourceForWorkflow,
} from '../Datasource/hooks';
import Container from './components/Container';
import CustomEdge from './components/EdgeElement/CustomEdge';
import FlowViewControls from './components/FlowViewControls';
import DatasourceNode from './components/NodeElement/DatasourceNode';
import ImageNode from './components/NodeElement/ImageNode';
import PlaceholderNode from './components/NodeElement/PlaceholderNode';
import Toolbar from './components/Toolbar';
import {
  useAutolinkTaskPoller,
  useFetchNodesImage,
  useGetRefData,
  useGetWorkflowData,
  useUpdateStoreAutolinkData,
} from './hooks';
import type { EditorStoreProps } from './store/EditorState';
import { EditorStore } from './store/EditorState';
import {
  DISTANCE_BETWEEN_NODES,
  editorMaxZoomLevel,
  editorMinZoomLevel,
} from './utils/constants';
import { appendDatasourceNode } from './utils/helper';
import ActionView from './components/ActionView';
import ContactModal from './components/ContactModal';
import ActionsHeader from './components/ActionsHeader';
import type { WorkflowNode } from 'types-shared';
import { NodeStatusEnum, NodeTypesEnum } from 'types-shared';
import { useFetchWorkflowMetadata } from '../Workflows/hooks';
import { WorkflowStatusEnum } from 'api-types-shared';
import ConditionalNode from './components/NodeElement/ConditionalNode';
import FreeformNode from './components/NodeElement/FreeFormNode';
import { EditNodePanel, useEditingNodeId } from 'editor-shared';
import Connector from './components/EdgeElement/Connector';

const nodeTypes: NodeTypes = {
  image: ImageNode,
  datasource: DatasourceNode,
  new: PlaceholderNode,
  conditional: ConditionalNode,
  freeform: FreeformNode,
};

const edgeTypes: EdgeTypes = {
  default: CustomEdge,
};
const DEFAULT_ZOOM = 0.7;

function Editor(): JSX.Element {
  const { workflowId } = useParams();
  if (!workflowId) {
    throw new Error('Workflow ID not provided');
  }

  const { data: workflowMetadata } = useFetchWorkflowMetadata(workflowId);
  const navigate = useNavigate();

  const {
    nodes,
    edges,
    datasourceMetadata,
    tableData,
    setNodes,
    setEdges,
    updateNode,
    addNodes,
    setWorkflowId,
    resetWorkflow,
    setTargets,
    resetTargets,
    setVariables,
    resetVariables,
    setDatasourceMetadata,
    setDatasourceTable,
    resetDatasource,
    onNodesChange,
    onEdgesChange,
    onConnect,
    selectedNode,
  } = EditorStore(
    useShallow((state: EditorStoreProps) => ({
      selectedNode: state.selectedNode,
      nodes: state.nodes,
      edges: state.edges,
      datasourceMetadata: state.datasourceMetadata,
      tableData: state.tableData,

      setNodes: state.setNodes,
      setEdges: state.setEdges,
      setWorkflowId: state.setWorkflowId,
      resetWorkflow: state.resetWorkflow,

      updateNode: state.updateNode,
      addNodes: state.addNodes,

      setTargets: state.setTargets,
      resetTargets: state.resetTargets,

      setVariables: state.setVariables,
      resetVariables: state.resetVariables,

      setDatasourceMetadata: state.setDatasourceMetadata,
      setDatasourceTable: state.setDatasourceTable,
      resetDatasource: state.resetDatasource,

      onNodesChange: state.onNodesChange,
      onEdgesChange: state.onEdgesChange,
      onConnect: state.onConnect,
    })),
  );

  const hasPersistedData = workflowId
    ? Boolean(localStorage.getItem(workflowId))
    : false;
  const { editingNodeId, setEditingNodeId } = useEditingNodeId();

  useEffect(() => {
    if (EditorStore.persist.getOptions().name !== workflowId) {
      EditorStore.persist.setOptions({
        name: 'root',
      });

      resetWorkflow();
      resetTargets();
      resetVariables();
      resetDatasource();

      void EditorStore.persist.rehydrate();

      EditorStore.persist.setOptions({
        name: workflowId,
        skipHydration: false,
      });
    }
  }, [
    workflowId,
    setNodes,
    setWorkflowId,
    resetWorkflow,
    resetTargets,
    resetVariables,
    resetDatasource,
  ]);

  const reactFlowRef = useRef<HTMLDivElement>(null);
  const [navMode, setNavMode] = useState<'pan' | 'trackpad'>('pan');

  const { data: nodesWithImage, isFetching: fetchingNodesImage } =
    useFetchNodesImage(workflowId, nodes);

  const handleDefaultViewport = (instance: ReactFlowInstance) => {
    if (reactFlowRef.current) {
      const allNodes =
        reactFlowRef.current.getElementsByClassName('react-flow__node');
      const firstNode = allNodes.length > 0 ? allNodes[0] : null;
      const nodeDimensions = firstNode
        ? firstNode.getBoundingClientRect()
        : { height: 0, width: 0 };
      const nodeHeight = nodeDimensions.height;
      const nodeWidth = nodeDimensions.width;
      const wrapperRect = reactFlowRef.current.getBoundingClientRect();
      const centerX = wrapperRect.width / 2 - (nodeWidth * DEFAULT_ZOOM) / 2;
      const centerY = wrapperRect.height / 2 - (nodeHeight * DEFAULT_ZOOM) / 2;

      instance.setViewport({ x: centerX, y: centerY, zoom: DEFAULT_ZOOM });
    }
  };

  const { data: workflowData, isFetching: isFetchingWorkflowData } =
    useGetWorkflowData(workflowId, hasPersistedData);

  const { data: nodeViewData, isFetching: isFetchingNodeData } = useGetRefData(
    workflowId,
    hasPersistedData,
  );

  const {
    data: datasourceMetadataRes,
    isFetching: isFetchingDatasourceMetadata,
  } = useGetDatasourceForWorkflow(workflowId);

  const { data: datasourceTableData } = useFetchDatasourceTable(
    datasourceMetadata?.datasourceId,
    Boolean(datasourceMetadata) && tableData === null,
  );

  const onApplyChanges = () => {
    const filteredNodes = nodes.map((node) => {
      if (node.type !== NodeTypesEnum.Image) return node;

      return {
        ...node,
        data: {
          ...node.data,
          selected: false,
          nodeStatus: node.data.selected
            ? NodeStatusEnum.Checked
            : NodeStatusEnum.NotViewed,
        },
      };
    }) as WorkflowNode[];
    setNodes(filteredNodes);
  };

  const onEnableSelectionMode = () => {
    const filteredNodes = nodes.map((node) => {
      if (node.type !== NodeTypesEnum.Image) return node;

      return {
        ...node,
        data: {
          ...node.data,
          selected: node.data.nodeStatus === NodeStatusEnum.Checked, // select the checked nodes by default
        },
      };
    });
    setNodes(filteredNodes);
  };

  const onCancelNodeSelection = () => {
    const filteredNodes = nodes.map((node) => ({
      ...node,
      data: {
        ...node.data,
        selected: false,
      },
    })) as WorkflowNode[];
    setNodes(filteredNodes);
  };

  useEffect(() => {
    if (workflowData) {
      const updatedWorkflowData = appendDatasourceNode(workflowData);
      setNodes(updatedWorkflowData.nodes);
      setEdges(updatedWorkflowData.edges);
    }
  }, [workflowData, setNodes, setEdges, resetWorkflow]);

  useEffect(() => {
    if (nodeViewData) {
      setTargets(nodeViewData.targetData);
      setVariables(nodeViewData.variableData);
    }
  }, [nodeViewData, setTargets, setVariables, resetTargets, resetVariables]);

  useEffect(() => {
    const dsMeta = datasourceMetadataRes?.at(0);
    if (dsMeta) {
      setDatasourceMetadata(dsMeta);
    }
  }, [setDatasourceMetadata, resetDatasource, datasourceMetadataRes]);

  useEffect(() => {
    if (datasourceTableData) {
      setDatasourceTable(datasourceTableData);
    }
  }, [datasourceTableData, setDatasourceTable]);

  const [autolinkTaskId, setAutolinkTaskId] = useState<string | undefined>();
  const { data: autolinkData } = useAutolinkTaskPoller(autolinkTaskId);
  useUpdateStoreAutolinkData(autolinkData?.data);

  useEffect(() => {
    if (
      autolinkData?.data.status &&
      ['finished', 'expired'].includes(autolinkData.data.status)
    ) {
      setAutolinkTaskId(undefined);
    }
  }, [autolinkData?.data]);

  useEffect(() => {
    if (nodesWithImage) {
      setNodes(nodesWithImage);
    }
  }, [nodesWithImage, setNodes]);

  useEffect(() => {
    if (workflowMetadata?.status === WorkflowStatusEnum.ProcessingImport) {
      navigate('/workflows');
    }
  }, [navigate, workflowMetadata]);

  return (
    <Container
      loading={
        isFetchingWorkflowData ||
        isFetchingNodeData ||
        isFetchingDatasourceMetadata
      }
    >
      <ReactFlowProvider>
        <div className={clsx('flex-1 h-full w-full relative flex flex-col')}>
          {!selectedNode ? (
            <Toolbar
              autolinkLoading={Boolean(autolinkTaskId)}
              setAutolinkTaskId={setAutolinkTaskId}
              workflowId={workflowId}
            />
          ) : null}
          {isFetchingWorkflowData ||
          isFetchingNodeData ||
          fetchingNodesImage ||
          isFetchingDatasourceMetadata ? (
            <div className="w-full h-full flex items-center justify-center">
              <Spinner className="!text-black" size={32} />
            </div>
          ) : (
            <ReactFlow
              attributionPosition="top-right"
              className="relative"
              connectionLineComponent={Connector}
              connectionLineType={ConnectionLineType.Bezier}
              edgeTypes={edgeTypes}
              edges={edges}
              maxZoom={editorMaxZoomLevel}
              minZoom={editorMinZoomLevel}
              nodeTypes={nodeTypes}
              nodes={nodes}
              onConnect={onConnect}
              onEdgesChange={onEdgesChange}
              onInit={(instance) => {
                handleDefaultViewport(instance);
              }}
              onNodesChange={onNodesChange}
              panOnDrag={navMode !== 'trackpad'}
              panOnScroll={navMode === 'trackpad'}
              ref={reactFlowRef}
              selectionMode={SelectionMode.Partial}
              selectionOnDrag={navMode === 'trackpad'}
            >
              <Background className="bg-flow-view" />
              <ActionsHeader
                nodes={nodes}
                onApplyChanges={onApplyChanges}
                onCancel={onCancelNodeSelection}
                onEnableSelectionMode={onEnableSelectionMode}
              />
              {!selectedNode ? (
                <FlowViewControls navMode={navMode} setNavMode={setNavMode} />
              ) : null}
              {editingNodeId ? (
                <EditNodePanel
                  addNodes={addNodes}
                  distanceBetweenNodes={DISTANCE_BETWEEN_NODES}
                  edges={edges}
                  nodeId={editingNodeId}
                  nodes={nodes}
                  onCancel={() => {
                    setEditingNodeId(undefined);
                  }}
                  setEdges={setEdges}
                  setNodes={setNodes}
                  updateNode={updateNode}
                />
              ) : null}
            </ReactFlow>
          )}
        </div>
      </ReactFlowProvider>
      {selectedNode ? <ActionView /> : null}
      <ContactModal />
    </Container>
  );
}

export default Editor;
