import type { ChangeEvent, MouseEvent } from 'react';
import { useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowLeftIcon, Logo, FilePlaceholderIcon, Clear } from 'assets-shared';
import { UploadUrlContentTypeEnum } from 'api-types-shared';
import type { DatasourceTable } from 'types-shared';
import { AlertVariant, Button, IconButton, Input, notify } from 'ui-kit';

import { EditorStore } from '../Editor/store/EditorState';
import { formatFileSize } from '../../utils/helper';
import { DATASOURCE_FILE_MAX_SIZE } from '../../constants';
import { parse, type ParseResult } from 'papaparse';
import DatasourcePreviewTable from './DatasourcePreviewTable';
import { useCreateDatasource, useUploadFile } from './hooks';

interface DatasourceFormData {
  workflowId: string;
  name: string;
  description: string;
}

const initialDatasourceData: DatasourceFormData = {
  workflowId: 'mockWorkflowId',
  name: '',
  description: '',
};

export default function Datasource(): JSX.Element {
  const { setDatasourceMetadata } = EditorStore();
  const { workflowId } = useParams<{ workflowId: string }>();
  if (!workflowId) {
    throw new Error('workflowId not found!');
  }

  const { mutateAsync: createDatasource } = useCreateDatasource();
  const { mutateAsync: uploadFile } = useUploadFile();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const navigate = useNavigate();

  const [file, setFile] = useState<File | null>(null);
  const [datasourceData, setDatasourceData] = useState(initialDatasourceData);
  const [parsedData, setParsedData] = useState<DatasourceTable>();
  const [previewModeEnabled, setPreviewModeEnabled] = useState<boolean>(false);

  const allowCreateDatabase = useMemo(
    () => datasourceData.name && datasourceData.description && file,
    [datasourceData, file],
  );

  const onCreateDatasource = async () => {
    const datasourceResponse = await createDatasource({
      workflowId,
      name: datasourceData.name,
      description: datasourceData.description,
    });
    const { datasourceId } = datasourceResponse;
    if (datasourceId && file) {
      await uploadFile({
        file,
        datasourceId,
      });
      setDatasourceMetadata(datasourceResponse);
      notify({
        message: 'Data source created successfully!',
        variant: AlertVariant.SUCCESS,
      });
      navigate(-1);
    }
  };

  const onFileSelect = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const selectedFile = files[0];
      if (selectedFile.size <= DATASOURCE_FILE_MAX_SIZE) {
        setFile(selectedFile);
      } else {
        notify({
          message: 'File size should be less than 10 MB',
          variant: AlertVariant.ERROR,
        });
      }
    }
  };

  const previewUploadedFile = () => {
    if (!file) return;

    parse<File>(file, {
      complete: (result: ParseResult<unknown>) => {
        const columns = result.meta.fields?.map((item) => {
          return {
            field: item,
          };
        }) as { field: string }[];
        const rows = result.data as Record<string, unknown>[];

        setParsedData({
          columnDefinitions: columns,
          rowData: rows,
        });
        setPreviewModeEnabled(true);
      },
      error(error: Error) {
        notify({
          message: error.message,
          variant: AlertVariant.ERROR,
          debug: true,
        });
      },
      header: true,
      skipEmptyLines: true,
    });
  };

  return (
    <div className="h-full w-full relative">
      <div className="flex flex-col px-6 py-5 w-max ">
        <input
          accept={UploadUrlContentTypeEnum.CSV}
          hidden
          onChange={onFileSelect}
          onClick={(e: MouseEvent<HTMLInputElement>) => {
            e.currentTarget.value = '';
          }}
          ref={inputRef}
          type="file"
        />
        <div className="flex items-center space-x-6 h-10">
          <Logo className="!w-8 !h-8" />
          <div className="flex items-center space-x-6">
            <IconButton
              className="!border !border-solid !border-info !rounded-lg !p-0"
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowLeftIcon className="text-info !h-10 !w-10" />
            </IconButton>
            <div className="flex flex-col text-xs">
              <span className="text-gray-500">Workflow - {workflowId}</span>
              <span className="text-info font-medium">Database</span>
            </div>
          </div>
        </div>
        <div className="pl-2">
          <h2 className="mt-12 text-3xl font-semibold">Add new database</h2>
          <p className="mt-2 mb-8 text-gray-500 text-sm font-medium">
            Upload a spreadsheet from your files.
          </p>
          <Input
            classes={{ wrapper: 'mt-8' }}
            label="Name"
            onChange={(val: string) => {
              setDatasourceData((data) => ({
                ...data,
                name: val,
              }));
            }}
            value={datasourceData.name}
          />
          <Input
            classes={{ wrapper: 'mt-2' }}
            label="Description"
            onChange={(val: string) => {
              setDatasourceData((data) => ({
                ...data,
                description: val,
              }));
            }}
            value={datasourceData.description}
          />
          <h3 className="mt-12 text-lg font-medium">Local spreadsheet</h3>
          <p className="mt-2 text-gray-500 font-medium text-sm">
            Upload a spreadsheet from your files.
            <br />
            <b>Supported formats:</b> CSV, XLS, XLSX.
            <br />
            <b>Maximum size:</b> 10mb
          </p>
          {file ? (
            <div className="mt-4 p-4 border rounded flex items-start space-x-2 w-full">
              <FilePlaceholderIcon className="!w-6 !h-6" />
              <div className="flex flex-col text-sm">
                <b className="text-sm">{file.name}</b>
                <span className="text-gray-500">
                  {formatFileSize(file.size)}
                </span>
              </div>
              <IconButton
                className="!ml-auto !-mr-3 !my-auto"
                onClick={() => {
                  setFile(null);
                }}
              >
                <Clear className="!w-5 !h-5 !text-black" />
              </IconButton>
            </div>
          ) : (
            <Button
              className="!mt-8"
              color="secondary"
              onClick={() => {
                inputRef.current?.click();
              }}
              variant="outlined"
            >
              Upload File
            </Button>
          )}

          <div className="flex items-center space-x-4 mt-14">
            <Button
              className="!flex-1"
              color="secondary"
              disabled={!allowCreateDatabase}
              onClick={previewUploadedFile}
              variant="contained"
            >
              Preview database
            </Button>
            <Button
              className="!flex-1"
              color="secondary"
              onClick={() => {
                navigate(-1);
              }}
              variant="outlined"
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
      {previewModeEnabled && parsedData ? (
        <div className="absolute left-0 top-0 h-full w-full bg-white">
          <DatasourcePreviewTable
            fileName={datasourceData.name}
            isSubmitDisabled={!allowCreateDatabase}
            onBack={() => {
              setPreviewModeEnabled(false);
            }}
            onSubmit={onCreateDatasource}
            previewMode
            tableData={parsedData}
          />
        </div>
      ) : null}
    </div>
  );
}
