import type { MenuProps } from '@mui/material/Menu';
import MuiMenu from '@mui/material/Menu';
import React from 'react';

type Props = Omit<MenuProps, 'anchorEl'> & {
  anchorEl: Element | null;
};

function Menu(props: Props) {
  return <MuiMenu {...props} />;
}

export default Menu;
