import {
  Box,
  FormControl,
  type SelectChangeEvent,
  Typography,
  RadioGroup,
  FormControlLabel,
  Switch,
  Button,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Radio, Input, Select, Flex } from 'ui-kit';
import type { DatasourceStateData } from 'types-shared';

export enum RunType {
  Local = 'Local',
  Remote = 'Remote',
}

interface Props {
  recordIds: number[];
  datasourceState: DatasourceStateData;
  onChange: (val: number[]) => void;
  onConfirmRun: (runType: RunType) => void;
  onCancel: () => void;
}

const getRecordIds = (start: number, end: number) =>
  Array.from({ length: end - start + 1 }, (_, i) => start + i);

export function ConfirmRunAutomation({
  recordIds,
  datasourceState,
  onChange,
  onConfirmRun,
  onCancel,
}: Props) {
  const { datasourceMetadata, tableData } = datasourceState;
  const [runType, setRunType] = useState(RunType.Local);
  const isLocalRun = runType === RunType.Local;
  const [rangeType, setRangeType] = useState('some');
  const [range, setRange] = useState({
    start: 1,
    end: tableData?.rowData.length ?? 1,
  });

  const handleChange = (event: SelectChangeEvent) => {
    setRunType(event.target.value as RunType);
  };

  const validateRange = () => {
    const tableLength = tableData?.rowData.length ?? 1;
    let startValue = range.start || 1;
    let endValue = range.end || 1;

    if (startValue > endValue || startValue > tableLength) {
      startValue = 0;
    }
    if (endValue < startValue || endValue > tableLength) {
      endValue = tableData?.rowData.length ?? 1;
    }
    setRange({ ...range, start: startValue, end: endValue });
  };

  const updateRange = (type: 'start' | 'end') => (value: string) => {
    if (!value) {
      setRange({ ...range, [type]: value });
      return;
    }

    const numericValue = Number(value);

    if (type === 'start' && numericValue <= range.end) {
      setRange({ ...range, [type]: numericValue });
    }
    if (type === 'end') {
      setRange({ ...range, [type]: numericValue });
    }
  };

  useEffect(() => {
    setRangeType(isLocalRun ? 'some' : 'all');
    setRange({
      start: 1,
      end: isLocalRun ? 1 : tableData?.rowData.length ?? 1,
    });
  }, [isLocalRun, tableData?.rowData.length]);

  useEffect(() => {
    if (rangeType === 'all') {
      onChange(getRecordIds(0, tableData ? tableData.rowData.length - 1 : 0));
    }
    if (rangeType === 'some') {
      //Restrict Run automation to only the first row (DEMO)
      // onChange(getRecordIds(range.start - 1, range.end - 1));
      const val = isLocalRun
        ? getRecordIds(0, 0)
        : getRecordIds(range.start - 1, range.end - 1);
      onChange(val);
    }
  }, [rangeType, range, onChange, tableData, isLocalRun]);

  return (
    <Box>
      <Typography className="!font-medium" variant="h5">
        Run settings
      </Typography>
      <Typography className="text-info-dark max-w-md !mt-4" variant="body2">
        Select deployment type and what rows to run before you execute your
        workflow
      </Typography>
      <Box className="mt-7 bg-color-light-blue p-5 rounded-md">
        <Flex className="gap-2">
          <Typography
            className="!font-bold text-info-dark min-w-fit"
            variant="body2"
          >
            Rows to be executed:
          </Typography>
          <Typography className="text-info-dark" variant="body2">
            {recordIds.length}
          </Typography>
        </Flex>
        {datasourceMetadata ? (
          <Flex className="gap-2">
            <Typography
              className="!font-bold text-info-dark min-w-fit"
              variant="body2"
            >
              Data source:
            </Typography>
            <Typography className="text-info-dark" variant="body2">
              {datasourceMetadata.name}
            </Typography>
          </Flex>
        ) : null}
        <Flex className="gap-2">
          <Typography
            className="!font-bold text-info-dark min-w-fit"
            variant="body2"
          >
            Estimated execution time:
          </Typography>
          <Typography className="text-info-dark" variant="body2">
            20 minutes
          </Typography>
        </Flex>
      </Box>
      <Box className="mt-7">
        <FormControl sx={{ margin: 0 }}>
          <div className="relative">
            <Select
              classes={{ select: '!w-56' }}
              getLabel={(opt: string) => opt}
              getValue={(opt: string) => opt}
              label="Select the type of run"
              labelId="select-run-type"
              onChange={handleChange}
              options={[RunType.Local.valueOf(), RunType.Remote.valueOf()]}
              value={runType}
            />
          </div>
        </FormControl>
      </Box>
      {datasourceMetadata ? (
        <Box className="mt-5">
          <FormControl sx={{ m: 0 }}>
            <RadioGroup
              defaultValue="some"
              name="radio-buttons-group"
              onChange={(_, val) => {
                setRangeType(val);
              }}
              value={rangeType}
            >
              <FormControlLabel
                control={<Radio color="secondary" />}
                label="Run a range of rows"
                value="some"
              />
              <Flex className="gap-2 mt-3 mb-8">
                <Input
                  InputProps={{ className: 'w-24', inputMode: 'numeric' }}
                  disabled={rangeType === 'all'}
                  floatingLabel
                  id="range-from"
                  label="From"
                  onBlur={validateRange}
                  onChange={updateRange('start')}
                  type="number"
                  value={range.start}
                />
                <span className="mx-3 text-black text-lg mt-4 !h-4"> – </span>
                <div className="flex flex-col">
                  <Input
                    InputProps={{ className: 'w-24', inputMode: 'numeric' }}
                    disabled={rangeType === 'all'}
                    floatingLabel
                    id="range-to"
                    label="To"
                    onBlur={validateRange}
                    onChange={updateRange('end')}
                    type="number"
                    value={range.end}
                  />
                  <span className="text-xs text-gray-500">
                    Max row: {tableData?.rowData.length}
                  </span>
                </div>
              </Flex>
              <FormControlLabel
                control={<Radio color="secondary" />}
                label="Run all rows"
                value="all"
              />
            </RadioGroup>
          </FormControl>
        </Box>
      ) : null}
      <Box className="mt-3">
        <FormControlLabel
          control={<Switch color="secondary" defaultChecked />}
          label="Close windows after run"
        />
      </Box>
      <Flex className="gap-2 mt-9">
        <Button
          color="secondary"
          disabled={!rangeType}
          onClick={() => {
            onConfirmRun(runType);
          }}
          variant="contained"
        >
          Confirm and run
        </Button>
        <Button color="secondary" onClick={onCancel} variant="text">
          Cancel
        </Button>
      </Flex>
    </Box>
  );
}
