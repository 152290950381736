import { Add } from 'assets-shared';
import { AlertVariant, Button, notify } from 'ui-kit';

import { useQuery } from '../../../hooks/useQuery';
import { useCreateWorkflow } from '../hooks';
import type {
  RecordingImportRequest,
  RecordingImportResponse,
} from 'api-types-shared';
import { sendToBackgroundViaRelay } from '@plasmohq/messaging';
import type { ExtensionData } from 'types-shared/actionTypes';

import { v4 as uuidv4 } from 'uuid';
import { KNOWN_WORKFLOW_IDS } from '../../Editor/utils/constants';

interface MmMetadata {
  'onRecordingImport.handler': {
    req: RecordingImportRequest;
    res: RecordingImportResponse;
  };
}
type MessageTypes = {
  [K in keyof MmMetadata]: MmMetadata[K] extends { req: infer R; res: infer S }
    ? { request: R; response: S }
    : never;
};

declare function sendToBackgroundViaRelayType<
  TName extends keyof MessageTypes,
>(request: { name: TName }): Promise<MessageTypes[TName]['response']>;

interface Props {
  openEditor: (workflowId: string) => void;
}

function ImportWorkflowButton({ openEditor }: Props) {
  const query = useQuery();
  const { mutateAsync: createWorkflow } = useCreateWorkflow();

  let workflowName = query.get('workflowName');

  const handleOnClickImport = async () => {
    if (!workflowName) {
      workflowName = uuidv4();
    }

    const extensionData = (await (
      sendToBackgroundViaRelay as typeof sendToBackgroundViaRelayType
    )({
      name: 'onRecordingImport.handler',
    })) as ExtensionData;

    const workflowId = await createWorkflow({
      workflowName,
      extensionData,
    });

    notify({
      message: `Workflow created successfully.`,
      variant: AlertVariant.SUCCESS,
    });

    if (KNOWN_WORKFLOW_IDS.includes(workflowId)) {
      openEditor(workflowId);
    }
  };

  return (
    <Button color="secondary" onClick={handleOnClickImport} variant="contained">
      <Add />
      Import Workflow
    </Button>
  );
}

export default ImportWorkflowButton;
