import { axios } from 'api-types-shared';
import type { Document } from 'types-shared/generatedTypes';
import type { Variable } from 'types-shared/workflowTypes';
import { apiEndpoints, type NodeEnv } from 'ui-kit';

import { handleException } from 'sentry-browser-shared';

export interface QueueAutolinkTaskRequest {
  document: Document & { datasource_id: string };
  variables: Variable[];
}

export interface QueueAutolinkTaskResponse {
  task_id: string;
}

export interface PollAutolinkTaskReponse {
  status: string;
  data?: string;
  details?: string;
}

export interface AutolinkItem {
  variableId: string;
  datasource: string;
}

export class AutolinkDemoSDK {
  readonly endpoint: string;

  constructor(env: NodeEnv) {
    this.endpoint = apiEndpoints[env].autolinkDemoApi;
  }

  queueAutolinkTask = async (req: QueueAutolinkTaskRequest) => {
    return axios
      .put<QueueAutolinkTaskResponse>(`${this.endpoint}/autolink`, req, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .catch((err) => {
        handleException(err, {
          name: 'Autolink Error',
          source: 'AutolinkDemo',
          userMessage: {
            title: 'Failed to autolink variables',
          },
        });
        throw err;
      });
  };

  pollAutolinkTask = async (taskId: string) => {
    const res = await axios
      .get<PollAutolinkTaskReponse>(`${this.endpoint}/autolink/${taskId}`)
      .catch((err) => {
        handleException(err, {
          name: 'Autolink Error',
          source: 'AutolinkDemo',
          userMessage: {
            title: 'Failed to autolink variables',
          },
        });
        throw err;
      });

    if (res.status !== 200) {
      handleException(res.data, {
        name: 'Autolink Error',
        source: 'AutolinkDemo',
        userMessage: {
          title: 'Failed to autolink variables',
        },
      });
      throw new Error(res.data.details);
    }

    return res;
  };
}
