import 'types-shared/reactflow/dist/style.css';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  AlertVariant,
  Button,
  IconButton,
  notify,
  Tooltip,
  useEnvironment,
} from 'ui-kit';
import { Typography } from '@mui/material';
import {
  ArrowLeftIcon,
  DatabaseIcon,
  Logo,
  AutoFixHigh,
  Save,
  AddBoxOutlined,
  CloudSync,
} from 'assets-shared';
import type { RunType } from 'editor-shared';
import { ConfirmRunAutomation } from 'editor-shared';

import { useUpdateWorkflow, useQueueAutolinkTask } from '../hooks';
import SolaModal from '../../shared/SolaModal';
import { EditorStore } from '../store/EditorState';
import { ExecutionRunner } from 'execution-shared';

interface Props {
  autolinkLoading?: boolean;
  workflowId: string;
  setAutolinkTaskId: React.Dispatch<React.SetStateAction<string | undefined>>;
}

function Toolbar({
  autolinkLoading,
  workflowId,
  setAutolinkTaskId,
}: Props): JSX.Element {
  const { state, pathname } = useLocation() as {
    pathname: string;
    state: {
      databaseCreated: boolean;
    } | null;
  };
  const navigate = useNavigate();
  const databaseCreated = state?.databaseCreated;
  const {
    addWatchedExecutionIds,
    datasourceMetadata,
    tableData,
    nodes,
    edges,
    variables,
    targets,
  } = EditorStore();

  const [recordIds, setRecordIds] = useState<number[]>([0]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmittedModalOpen, setIsSubmittedModalOpen] = useState(false);
  const { selectedEnv } = useEnvironment();
  const executionRunner = useMemo(
    () => new ExecutionRunner(selectedEnv),
    [selectedEnv],
  );

  const { mutateAsync: updateWorkflow } = useUpdateWorkflow();
  const { mutateAsync: queueAutolink, status } = useQueueAutolinkTask();

  const confirmRunHandler = useCallback(
    async (runType: RunType) => {
      const { queuedExecutionIds } = await executionRunner.run({
        runType: runType.valueOf(),
        workflowId,
        recordIds: recordIds.map((id) => id.toString()),
        workflowData: {
          nodes,
          edges,
          variables,
          targets,
        },
        tableData,
      });
      addWatchedExecutionIds(queuedExecutionIds);
      setIsModalOpen(false);
      setIsSubmittedModalOpen(true);
    },
    [
      addWatchedExecutionIds,
      executionRunner,
      workflowId,
      recordIds,
      nodes,
      edges,
      variables,
      targets,
      tableData,
    ],
  );

  const isLoading = status === 'pending' || autolinkLoading;

  const goToWorkflowDetail = () => {
    setIsSubmittedModalOpen(false);
    navigate(`/workflows/${workflowId}`);
  };

  const downloadWorkflow = () => {
    localStorage.removeItem(workflowId);
    window.location.reload();
  };

  const onAutoLink = useCallback(async () => {
    const taskId: string = await queueAutolink({
      datasourceId: datasourceMetadata?.datasourceId ?? null,
      variables: Object.values(variables),
    });
    setAutolinkTaskId(taskId);
  }, [
    queueAutolink,
    datasourceMetadata?.datasourceId,
    setAutolinkTaskId,
    variables,
  ]);

  useEffect(() => {
    if (databaseCreated) {
      notify({
        message:
          'Database connected! You can now Auto-link your data source to variables/actions',
        variant: AlertVariant.SUCCESS,
        action: (
          <Button
            className="!text-white !border-white"
            onClick={onAutoLink}
            variant="outlined"
          >
            Autolink
          </Button>
        ),
      });
      navigate(pathname, { replace: true });
    }
  }, [pathname, navigate, databaseCreated, onAutoLink]);

  return (
    <>
      <header className="py-5 flex justify-between items-center !h-20 px-6">
        <div className="flex items-center space-x-6">
          <Logo className="!w-8 !h-8" />
          <Link
            className="flex !border !border-solid !border-info !rounded-lg"
            replace
            to={`/workflows/${workflowId}`}
          >
            <ArrowLeftIcon className="text-info !h-10 !w-10" />
          </Link>
          <div className="flex flex-col text-xs font-medium !ml-6">
            <span className="text-gray-500">Workflow</span>
            <span>{workflowId}</span>
          </div>
          {datasourceMetadata ? (
            <div className="flex space-x-1 items-center">
              <DatabaseIcon />
              <span className="text-sm font-medium">
                {datasourceMetadata.name}
              </span>
            </div>
          ) : (
            <Link
              className="text-info flex items-center space-x-1.5"
              to={`/datasource/${workflowId}`}
            >
              <AddBoxOutlined className="!w-5 !h-5 !text-secondary-blue" />
              <span className="text-sm !text-secondary-blue">Add Database</span>
            </Link>
          )}
          <span className="rounded p-px !bg-gradient-to-r from-primary-blue to-primary-purple">
            <Button
              className="!uppercase bg-transparent !text-black !text-sm !bg-white !border-0 !rounded"
              color="secondary"
              endIcon={
                !isLoading ? (
                  <AutoFixHigh className="text-xs text-black" />
                ) : null
              }
              loading={isLoading}
              onClick={onAutoLink}
              variant="outlined"
            >
              Autolink
            </Button>
          </span>
          {/* <div className="flex space-x-1 !ml-9 items-center">
            <span className="text-sm font-medium">Database already </span>
            <span className="text-sm font-medium text-secondary-purple">
              auto-linked
            </span>
            <AutoLinkIcon className="!h-4 !w-4 !ml-2" />
          </div> */}
        </div>
        <div className="flex items-center space-x-2">
          <Tooltip title="Save Changes">
            <IconButton
              onClick={() =>
                updateWorkflow({
                  workflowId,
                  editorState: {
                    nodes,
                    edges,
                    variables,
                    targets,
                    datasourceMetadata,
                    tableData,
                  },
                })
              }
            >
              <Save className="!text-info" fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Download Changes">
            <IconButton onClick={downloadWorkflow}>
              <CloudSync className="!text-green-600" fontSize="small" />
            </IconButton>
          </Tooltip>
          <Button
            className="!uppercase !ml-6 !bg-info"
            color="secondary"
            onClick={() => {
              setIsModalOpen(true);
            }}
            variant="contained"
          >
            Run Automation
          </Button>
        </div>
      </header>
      <SolaModal
        className="!max-w-2xl"
        onClose={() => {
          setIsModalOpen(false);
        }}
        open={isModalOpen}
        showCloseIcon={false}
      >
        <ConfirmRunAutomation
          datasourceState={{ datasourceMetadata, tableData }}
          onCancel={() => {
            setIsModalOpen(false);
          }}
          onChange={setRecordIds}
          onConfirmRun={confirmRunHandler}
          recordIds={recordIds}
        />
      </SolaModal>
      <SolaModal
        className="!max-w-2xl -top-20"
        onClose={() => {
          setIsSubmittedModalOpen(false);
        }}
        open={isSubmittedModalOpen}
        showCloseIcon
      >
        <img alt="logo" className="w-32" src="/logo-blue.png" />
        <div className="ml-1">
          <Typography className="!font-medium !mt-7" variant="h5">
            Your run is in progress!
          </Typography>
          <Typography className="!mt-4 !text-info-dark">
            Your workflow is running remotely; the execution(s) can take a few
            minutes. We'll notify you of progress through <b>Slack</b> and in
            the workflow executions page!
          </Typography>
        </div>
        <Button
          className="!text-info !border-info !my-10"
          onClick={() => {
            goToWorkflowDetail();
          }}
          variant="outlined"
        >
          GO TO WORKFLOW DETAILS
        </Button>
      </SolaModal>
    </>
  );
}

export default Toolbar;
