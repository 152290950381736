import { clsx } from 'clsx';
import type { ChangeEvent } from 'react';
import React, { useEffect, useRef } from 'react';

interface Props {
  className?: string;
  value: string;
  disabled?: boolean;
  onChange: (val: string) => void;
}

function Input({ className, onChange, value, disabled = false }: Props) {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (ref.current && ref.current.textContent !== value) {
      ref.current.textContent = value;
    }
  }, [value]);

  const saveValue = (e: ChangeEvent<HTMLDivElement>) => {
    onChange(e.target.textContent ?? '');
  };

  return (
    <div
      className={clsx(
        'flex items-center outline-0 min-w-max max-w-max',
        className,
        { 'text-gray-300': disabled },
      )}
      contentEditable={!disabled}
      onBlur={saveValue}
      onInput={saveValue}
      ref={ref}
    />
  );
}

export default Input;
