import { Button, Divider } from '@mui/material';
import { clsx } from 'clsx';
import { useMemo, useState } from 'react';
import type { EventMap } from 'ui-kit';
import { createEventBus } from 'ui-kit';
import type { WorkflowImageNode, WorkflowNode } from 'types-shared';

interface NodeSelectionEvents extends EventMap {
  onToggleSelectionMode: (isEnabled: boolean) => void;
}

export const nodeSelectEventChannel = createEventBus<NodeSelectionEvents>();

interface Props {
  nodes: WorkflowNode[];
  onEnableSelectionMode: () => void;
  onApplyChanges: () => void;
  onCancel: () => void;
}

export default function ActionsHeader({
  nodes,
  onApplyChanges,
  onCancel,
  onEnableSelectionMode,
}: Props) {
  const [nodeSelectionModeEnabled, setNodeSelectionModeEnabled] =
    useState(false);
  const checkedNodes = useMemo(
    () => nodes.filter((node) => (node as WorkflowImageNode).data.selected),
    [nodes],
  );

  const enableNodeSelectionMode = () => {
    onEnableSelectionMode();
    setNodeSelectionModeEnabled(true);
    nodeSelectEventChannel.emit('onToggleSelectionMode', true);
  };

  const disableNodeSelectionMode = () => {
    setNodeSelectionModeEnabled(false);
    nodeSelectEventChannel.emit('onToggleSelectionMode', false);
  };

  const handleApplyChanges = () => {
    if (nodeSelectionModeEnabled) {
      onApplyChanges();
    }
    disableNodeSelectionMode();
  };

  const handleCancelChanges = () => {
    onCancel();
    disableNodeSelectionMode();
  };

  return (
    <div
      className={clsx(
        nodeSelectionModeEnabled && 'bg-white',
        'px-5 h-[4.5rem] flex items-center absolute top-0 left-0 z-10 w-full border-t transition-all duration-200',
      )}
    >
      <div className="flex gap-2 w-full">
        {nodeSelectionModeEnabled ? (
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center">
              <span className="text-sm font-medium text-info-dark">
                BULK NODE CHECK
              </span>
              <Divider
                orientation="vertical"
                sx={{
                  height: '1rem',
                  borderColor: '#7A859C',
                  mx: 2,
                  borderWidth: '0.5px',
                }}
              />
              <span className="font-medium text-sm text-color-grey">
                {checkedNodes.length} new blocks checked as done
              </span>
            </div>
            <div className="flex items-center gap-2">
              <Button
                color="secondary"
                onClick={handleApplyChanges}
                variant="outlined"
              >
                Apply changes
              </Button>
              <Button
                color="secondary"
                onClick={handleCancelChanges}
                variant="text"
              >
                cancel
              </Button>
            </div>
          </div>
        ) : (
          <Button
            className="h-10 min-w-40 !text-info-dark !rounded !px-4 !bg-white"
            onClick={enableNodeSelectionMode}
            variant="text"
          >
            Bulk node check
          </Button>
        )}
      </div>
    </div>
  );
}
