import type { WorkflowNode } from 'types-shared';
import React from 'react';

interface Props {
  node: WorkflowNode;
}

export function FreeFormNodeCore({ node }: Props) {
  return (
    <>
      <div className="flex-1 rounded-lg border border-indigo-light bg-gray-100 flex justify-center items-center">
        <img alt="logo" className="w-16" src="/logo-blue.png" />
      </div>
      <span className="text-sm font-bold truncate">{node.name}</span>
    </>
  );
}
