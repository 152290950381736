import { zodAxios } from 'api-types-shared';
import type { WorkflowNode } from 'types-shared';
import {
  NodeTypesEnum,
  TargetMap,
  VariableMap,
  WorkflowData,
} from 'types-shared';

import {
  DEFAULT_STUB,
  STUB_WORKFLOW_ID,
  VERCEL_STUB_PATH,
} from '../../constants';
import { getBlobFromS3 } from 'dashboard-shared';
import { handleException } from 'sentry-browser-shared';
import { getImgsFromData } from './utils/helper';

export const getStubWorkflowData = async (workflowId: string) => {
  const stubWorkflowId =
    workflowId === STUB_WORKFLOW_ID ? DEFAULT_STUB : workflowId;
  const workflowData = await zodAxios(
    WorkflowData,
    `${VERCEL_STUB_PATH}/${stubWorkflowId}/state.json`,
    {
      method: 'get',
    },
  ).catch((error) => {
    throw new Error(`Failed to fetch stub workflow data. Error: ${error}`);
  });

  const imgs = getImgsFromData(workflowData.nodes);
  const imgData: Record<string, Blob> = {};
  const promises = imgs.map(async (imageId) => {
    const blob = await getBlobFromS3(
      `${VERCEL_STUB_PATH}/${stubWorkflowId}/thumbnails/${imageId}.png`,
    ).catch((error) => {
      handleException(error, {
        name: 'Fetching thumbnail data failed',
        source: 's3',
      });
      return null;
    });
    if (blob) {
      imgData[imageId] = blob;
    }
  });
  await Promise.all(promises);

  workflowData.nodes.forEach((node: WorkflowNode) => {
    if (node.type === NodeTypesEnum.Image) {
      node.data.imageData.thumbnailData = imgData[node.data.imageData.imageId];
    }
  });

  return workflowData;
};

export const getStubNodeData = async (workflowId: string) => {
  const stubWorkflowId =
    workflowId === STUB_WORKFLOW_ID ? DEFAULT_STUB : workflowId;

  const [variableData, targetData] = await Promise.all([
    zodAxios(
      VariableMap,
      `${VERCEL_STUB_PATH}/${stubWorkflowId}/variable.json`,
      {
        method: 'get',
      },
    ).catch((error) => {
      throw new Error(`Failed to fetch stub variable data. Error: ${error}`);
    }),

    zodAxios(TargetMap, `${VERCEL_STUB_PATH}/${stubWorkflowId}/target.json`, {
      method: 'get',
    }).catch((error) => {
      throw new Error(`Failed to fetch stub target data. Error: ${error}`);
    }),
  ]);

  return { variableData, targetData };
};

export const getStubImageData = async (
  workflowId: string,
  imageIds: string[],
) => {
  const stubWorkflowId =
    workflowId === STUB_WORKFLOW_ID ? DEFAULT_STUB : workflowId;

  const imgData: Record<string, Blob> = {};
  const promises = imageIds.map(async (imageId) => {
    const blob = await getBlobFromS3(
      `${VERCEL_STUB_PATH}/${stubWorkflowId}/images/${imageId}.png`,
    ).catch((error) => {
      handleException(error, {
        name: 'Fetching thumbnail data failed',
        source: 's3',
      });
      return null;
    });
    if (blob) {
      imgData[imageId] = blob;
    }
  });
  await Promise.all(promises);
  return imgData;
};
