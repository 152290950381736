import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import React, { useState } from 'react';
import MuiMenu from '@mui/material/Menu';
import MuiMenuItem from '@mui/material/MenuItem';
import { useAuth0 } from '@auth0/auth0-react';
import PersonIcon from '../../assets/PersonIcon';

export default function Profile() {
  const { user, logout } = useAuth0();
  const logOutUser = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <button
        className="mt-auto flex flex-row items-center p-4 rounded !bg-primarySelected w-full"
        onClick={handleClick}
        type="button"
      >
        <AccountCircleOutlinedIcon className="text-info" />
        <div className="pl-8 text-sm font-normal text-left">
          <p className="text-black">Profile</p>
          <p className="text-secondaryText">{user?.name}</p>
        </div>
      </button>

      <MuiMenu
        BackdropProps={{
          style: {
            backgroundColor: 'transparent',
          },
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '8px',
            paddingTop: 0,
          },
          '& .MuiMenu-list': {
            background:
              'linear-gradient(50.33deg, rgba(184, 220, 252, 0) 68.39%, rgba(184, 220, 252, 0.4) 100%)',
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className="flex flex-row items-center w-full pb-12 px-4 pt-5 border-b border-border">
          <div className="!bg-primarySelected py-1.5 px-2 rounded">
            <PersonIcon className="!fill-none" />
          </div>
          <div className="pl-5 text-sm font-medium text-left">
            <p className="text-infoDark">{user?.name}</p>
            <p className="text-secondaryText">{user?.email}</p>
          </div>
        </div>
        <MuiMenuItem className="group min-w-72 h-12" onClick={logOutUser}>
          <span className="font-normal text-base text-error leading-6 pl-0.5 py-1">
            Logout
          </span>
        </MuiMenuItem>
      </MuiMenu>
    </>
  );
}
