export const EMPTY_DATA_URL = 'data:,';

export const VERCEL_STUB_PATH =
  'https://j1vv3wdqxwe6zvfn.public.blob.vercel-storage.com/apps/sola-dashboard/public/stubs';
export const DEFAULT_STUB = 'UpdatedBusinessFilingStub';
export const STUB_WORKFLOW_ID = 'stub';

export const DEFAULT_REFETCH_INTERVAL = 60 * 1000;
export const DEFAULT_CACHE_TIME = 3 * 60 * 1000;

export const DATASOURCE_FILE_MAX_SIZE = 10 * 1000 * 1000;
