"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExtensionAction = exports.ExtensionScrapeAction = exports.ExtensionMultiChoiceAction = exports.ExtensionKeydownAction = exports.ExtensionSelectAction = exports.ExtensionInputAction = exports.ExtensionActionBase = exports.Actions = exports.ActionsEnum = void 0;
var zod_1 = require("zod");
var query_path_1 = require("./query-path");
var ActionsEnum;
(function (ActionsEnum) {
    ActionsEnum["Click"] = "Click";
    ActionsEnum["Input"] = "Input";
    ActionsEnum["Wait"] = "Wait";
    ActionsEnum["Scrape"] = "Scrape";
    ActionsEnum["Keydown"] = "Keydown";
    ActionsEnum["Select"] = "Select";
    ActionsEnum["MultiChoice"] = "MultiChoice";
    ActionsEnum["Download"] = "Download";
})(ActionsEnum || (exports.ActionsEnum = ActionsEnum = {}));
exports.Actions = zod_1.z.nativeEnum(ActionsEnum);
exports.ExtensionActionBase = zod_1.z.object({
    target: query_path_1.QueryPaths,
    actionType: zod_1.z.literal(exports.Actions.enum.Click),
    coordinates: query_path_1.RawCoordinates,
});
exports.ExtensionInputAction = exports.ExtensionActionBase.extend({
    actionType: zod_1.z.literal(exports.Actions.enum.Input),
    currentText: zod_1.z.string().optional(),
});
exports.ExtensionSelectAction = exports.ExtensionActionBase.extend({
    actionType: zod_1.z.literal(exports.Actions.enum.Select),
    target: query_path_1.SelectQueryPaths,
    currentSelected: query_path_1.SelectOption,
});
exports.ExtensionKeydownAction = exports.ExtensionInputAction.extend({
    actionType: zod_1.z.literal(exports.Actions.enum.Keydown),
    keyPress: zod_1.z.string(),
});
exports.ExtensionMultiChoiceAction = exports.ExtensionActionBase.extend({
    actionType: zod_1.z.literal(exports.Actions.enum.MultiChoice),
    target: query_path_1.MultiChoiceQueryPaths,
});
exports.ExtensionScrapeAction = exports.ExtensionActionBase.extend({
    actionType: zod_1.z.literal(exports.Actions.enum.Scrape),
    scrapedText: zod_1.z.string().optional(),
});
exports.ExtensionAction = zod_1.z.union([
    exports.ExtensionActionBase,
    exports.ExtensionInputAction,
    exports.ExtensionSelectAction,
    exports.ExtensionMultiChoiceAction,
    exports.ExtensionScrapeAction,
]);
