import TableSortLabel from '@mui/material/TableSortLabel';
import React, { useEffect, useState } from 'react';

import type { TableColumn } from '../index';
import { getSortOptions } from '../utils/helper';

enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

interface Props<T> {
  setSortBy: (val: string) => void;
  sortBy?: string;
  column: TableColumn<T>;
}

export default function SortableHeader<T>({
  setSortBy,
  sortBy = '',
  column,
}: Props<T>) {
  const [data, setData] = useState<SortOrder>(SortOrder.Asc);

  const sortOn = column.sortType ?? column.id;

  useEffect(() => {
    const [sortOrder, sortField] = getSortOptions(sortBy || '');
    if (sortField === sortOn) setData(sortOrder as SortOrder);
  }, [sortBy, sortOn]);

  const handleClick = () => {
    const value = data === SortOrder.Asc ? SortOrder.Desc : SortOrder.Asc;
    setSortBy(`${value}_${sortOn}`);
    setData(value);
  };

  return column.sortable ? (
    <TableSortLabel
      active={sortBy.includes(sortOn)}
      className="flex items-center space-x-1 text-sm"
      direction={data}
      onClick={handleClick}
    >
      {column.title}
    </TableSortLabel>
  ) : (
    <span>{column.title}</span>
  );
}
