"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.uploadScrapemapSchema = void 0;
var types_shared_1 = require("types-shared");
exports.uploadScrapemapSchema = {
    request: types_shared_1.z.object({
        body: types_shared_1.z.object({
            executionId: types_shared_1.z.string(),
        }),
        sender: types_shared_1.z.object({
            tab: types_shared_1.z
                .object({
                windowId: types_shared_1.z.number(),
                tabId: types_shared_1.z.number(),
            })
                .optional(),
        }),
    }),
    response: types_shared_1.z.object({
        status: types_shared_1.z.boolean(),
    }),
};
