import React, { Component } from 'react';

import type { ContainerProps } from '../types/common';
import Button from './Button';

interface ErrorBoundaryState {
  hasError: boolean;
}

export default class ErrorBoundary extends Component<
  ContainerProps,
  ErrorBoundaryState
> {
  constructor(props: ContainerProps) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error: Error) {
    // log errors
    return { hasError: true, error };
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <div className="relative bg-white text-black h-[100vh]">
          <div className="flex flex-col space-y-10 justify-center items-center h-full">
            <h1 className="text-xl font-semibold">
              Oops, Something Went Wrong
            </h1>
            <Button
              color="primary"
              onClick={() => {
                window.location.reload();
              }}
              type="button"
              variant="contained"
            >
              Restart App
            </Button>
          </div>
        </div>
      );
    }

    return children;
  }
}
