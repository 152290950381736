import SvgIcon from '@mui/material/SvgIcon';
import type { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React from 'react';

export default function PersonIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6666 17.5C16.6666 15.1988 13.6818 13.3333 9.99992 13.3333C6.31802 13.3333 3.33325 15.1988 3.33325 17.5M9.99992 10.8333C7.69873 10.8333 5.83325 8.96785 5.83325 6.66667C5.83325 4.36548 7.69873 2.5 9.99992 2.5C12.3011 2.5 14.1666 4.36548 14.1666 6.66667C14.1666 8.96785 12.3011 10.8333 9.99992 10.8333Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </SvgIcon>
  );
}
