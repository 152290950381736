import type { TableCellProps } from '@mui/material/TableCell';
import TableCell from '@mui/material/TableCell';
import { clsx } from 'clsx';
import type { ReactElement } from 'react';
import React from 'react';

import type { TableColumn } from '../index';

interface CellRendererProps {
  columnValue: string;
}

function Default({ columnValue }: CellRendererProps) {
  return <span>{columnValue || '-'}</span>;
}

interface Props<T> {
  column: TableColumn<T>;
  values: T;
  columnValue: string;
  cellKey: string;
  showVerticalDivider: boolean;
  cellRenderer?: (val: T) => ReactElement;
  onClick?: (row: T) => void;
}

export default function TableCellRenderer<T>({
  column,
  values,
  columnValue,
  cellKey,
  showVerticalDivider,
  onClick,
  cellRenderer,
}: Props<T>) {
  const tableCellClass = clsx(
    '!text-xs !font-normal !py-2.5 !px-3 !flex-1 first-of-type:!pl-2 last-of-type:!pr-0',
    { '': showVerticalDivider },
  );
  const commonProps = {
    key: cellKey,
    className: tableCellClass,
    size: 'small',
    onClick: column.clickable && onClick,
  } as Partial<TableCellProps>;

  if (cellRenderer) {
    return (
      <TableCell {...commonProps} className={column.className}>
        {cellRenderer(values)}
      </TableCell>
    );
  }

  if (column.cellRenderer) {
    const CellRenderer = column.cellRenderer;
    return (
      <TableCell {...commonProps} className={column.className}>
        <CellRenderer columnValue={columnValue} values={values} />
      </TableCell>
    );
  }

  return (
    <TableCell {...commonProps} className={column.className}>
      <Default columnValue={columnValue} />
    </TableCell>
  );
}
