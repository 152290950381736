import type {
  SelectVariable,
  TemplateVariable,
  Variable,
  WorkflowAction,
  WorkflowActionsOptions,
} from 'types-shared';
import { clsx } from 'clsx';
import { ActionsEnum, VariableTypeEnum } from 'types-shared';
import { Chip, IconButton, Tooltip } from 'ui-kit';
import { EditIcon, VisibilityOff, Person } from 'assets-shared';

import ActionMenu from './Menu';
import { imageNodeEventChannel } from '../../NodeElement/SelectedImageNodeContent';
import type { VariableData } from '../EditAction';
import MultiChoicePreview from '../../MultipleChoice/Preview';

interface Props {
  variablesMap: Record<string, Variable>;
  onEditClick: (action: WorkflowAction) => void;
  onUpdateActionOptions: (
    action: WorkflowAction,
    options: WorkflowActionsOptions,
  ) => void;
  action: WorkflowAction;
  i: number;
}

const actionTypeToTitleMapping: Record<string, string> = {
  [ActionsEnum.MultiChoice]: 'Multiple Choice',
};

const actionTypeToPreviewTitleMapping: Record<string, string> = {
  [ActionsEnum.Input]: 'Input',
  [ActionsEnum.Select]: 'Select',
};

function Action({
  action,
  onEditClick,
  onUpdateActionOptions,
  variablesMap,
  i,
}: Props) {
  const {
    actionType,
    options: { hidden, hitl } = {},
    title,
    variableId = '',
    targetId,
  } = action;
  const variable = variablesMap[variableId];
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const { name } = variable ?? {};

  const highlightAnnotation = () => {
    if (targetId) {
      imageNodeEventChannel.emit('onActionHover', targetId);
    }
  };

  const removeAnnotationHighlight = () => {
    imageNodeEventChannel.emit('onBlur');
  };

  return (
    <div
      className={clsx(
        'px-3 py-5 bg-gray-100 border rounded hover:border-primary-blue cursor-pointer hover:shadow-primary transition',
        {
          'border-yellow-300 !bg-yellow-50': hitl,
          'opacity-50': hidden,
        },
      )}
      onBlur={removeAnnotationHighlight}
      onFocus={highlightAnnotation}
      onMouseLeave={removeAnnotationHighlight}
      onMouseOver={highlightAnnotation}
    >
      <div className="flex justify-between items-center">
        <div className="flex space-x-4 items-center">
          <span className="text-xs bg-gray-300 rounded-full h-6 w-6 flex justify-center items-center">
            {i}
          </span>
          <span className="font-semibold text-sm">
            {actionTypeToTitleMapping[actionType] ?? actionType}
          </span>
        </div>
        <div className="flex space-x-4 items-center">
          {actionType !== ActionsEnum.Click && !hidden && !hitl ? (
            <IconButton
              className="!p-0"
              onClick={() => {
                onEditClick(action);
              }}
            >
              <EditIcon className="!w-5 !h-5 text-black" />
            </IconButton>
          ) : null}
          {!hitl && hidden ? (
            <Tooltip title="Hidden Action">
              <VisibilityOff className="!w-5 !h-5" />
            </Tooltip>
          ) : null}
          {hitl ? (
            <Tooltip title="Action handled manually">
              <Person className="!w-5 !h-5" />
            </Tooltip>
          ) : null}
          <ActionMenu
            action={action}
            onUpdateActionOptions={onUpdateActionOptions}
          />
        </div>
      </div>
      {actionType !== ActionsEnum.Click && (
        <div className="mt-8 border border-gray-300 rounded-lg flex text-xs">
          <span className="p-3 border-r border-gray-300">
            {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
            {title ?? actionTypeToPreviewTitleMapping[actionType] ?? 'Value'}
          </span>
          {variable.type === VariableTypeEnum.Scrape ? (
            <Chip
              className="!ml-3 !my-auto"
              color="secondary"
              label={name}
              size="small"
            />
          ) : null}
          {variable.type === VariableTypeEnum.Template ? (
            <VariableInputs
              dataLike={(variable as TemplateVariable).data}
              label={name}
              variablesMap={variablesMap}
            />
          ) : null}
          {variable.type === VariableTypeEnum.Select ? (
            <VariableInputs
              dataLike={(variable as SelectVariable).data}
              label={name}
              variablesMap={variablesMap}
            />
          ) : null}
          {variable.type === VariableTypeEnum.MultiChoice ? (
            <MultiChoicePreview variable={variable} />
          ) : null}
        </div>
      )}
    </div>
  );
}

export default Action;

interface VariableInputsProps {
  variablesMap: Record<string, Variable>;
  label: string | undefined;
  dataLike: unknown;
}

function VariableInputs({
  dataLike,
  label,
  variablesMap,
}: VariableInputsProps) {
  if (Array.isArray(dataLike as unknown[])) {
    return (
      <div className="px-3 flex items-center flex-wrap space-x-1">
        {(dataLike as VariableData[]).map((val: VariableData) => {
          if (typeof val === 'string') {
            return (
              <span key={val}>
                {label === 'Password' ? val.replace(/\w/g, '*') : val}
              </span>
            );
          }
          return (
            <Chip
              className="!my-auto"
              color="secondary"
              key={val.id}
              label={variablesMap[val.id].name}
              size="small"
            />
          );
        })}
      </div>
    );
  }

  const val = dataLike as { datasourceId: string; key: string };

  return (
    <Chip
      className="!my-auto"
      color="secondary"
      key={val.key}
      label={val.key}
      size="small"
    />
  );
}
