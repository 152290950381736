import type { WorkflowNode } from 'types-shared';
import { ConditionalIcon } from 'assets-shared';
import React from 'react';

interface Props {
  node: WorkflowNode;
  onClick?: () => void;
}

export function ConditionalNodeCore({ node, onClick }: Props) {
  return (
    <>
      <button
        className="flex-1 rounded-lg border border-indigo-light bg-gray-100 flex justify-center items-center"
        onClick={onClick}
        type="button"
      >
        <ConditionalIcon className="!w-16 !h-16" />
      </button>
      <span className="text-sm font-bold truncate">{node.name}</span>
    </>
  );
}
