import type {
  DatasourceMetadata,
  DatasourceTable,
  DatasourceStateData,
} from 'types-shared';
import type { StateCreator } from 'zustand';

export const initialDatasourceState: DatasourceStateData = {
  datasourceMetadata: null,
  tableData: null,
};

export interface DatasourceStateActions {
  setDatasourceMetadata: (datasourceMetadata: DatasourceMetadata) => void;
  setDatasourceTable: (tableData: DatasourceTable) => void;
  resetDatasource: () => void;
}

export const DatasourceState: StateCreator<
  DatasourceStateData & DatasourceStateActions
> = (set) => ({
  ...initialDatasourceState,
  setDatasourceTable: (tableData: DatasourceTable) => {
    set({ tableData });
  },
  setDatasourceMetadata: (datasourceMetadata: DatasourceMetadata) => {
    set({ datasourceMetadata });
  },
  clearDatasource: () => {
    set({ datasourceMetadata: null });
  },
  resetDatasource: () => {
    set(initialDatasourceState);
  },
});
