import { Actions, WorkflowAction, handleZodCheck } from 'types-shared';
import type { ParseActionsReturnType } from './parseActions';
import deepClone from 'lodash/cloneDeep';

export const combineDownloadActions = (
  actionsArray: ParseActionsReturnType['actionDataArray'],
) => {
  let lastExtensionAction: { nodeIndex: number; actionId: string } | null =
    null;
  const clonedActionsArray = deepClone(actionsArray);

  clonedActionsArray.forEach((subArray, subArrayIndex) => {
    const actionIdsToRemove = new Set<string>();

    subArray.actionOrder.forEach((actionId: string) => {
      const action = subArray.actionData[actionId];

      handleZodCheck(action, WorkflowAction, (parsedAction) => {
        if (parsedAction.actionType !== Actions.enum.Download) {
          lastExtensionAction = { nodeIndex: subArrayIndex, actionId };
        } else if (lastExtensionAction) {
          const lastActionData =
            clonedActionsArray[lastExtensionAction.nodeIndex].actionData[
              lastExtensionAction.actionId
            ];
          lastActionData.options = {
            ...lastActionData.options,
            download: [
              ...(lastActionData.options?.download || []),
              ...(parsedAction.options?.download || []),
            ],
          };
          actionIdsToRemove.add(lastExtensionAction.actionId);
        }
      });
    });
    subArray.actionOrder = subArray.actionOrder.filter(
      (actionId) => !actionIdsToRemove.has(actionId),
    );
    actionIdsToRemove.forEach((actionId) => {
      Reflect.deleteProperty(subArray.actionData, actionId);
    });
  });

  return clonedActionsArray;
};
