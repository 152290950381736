import SvgIcon from '@mui/material/SvgIcon';
import type { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React from 'react';

export default function DatabaseIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      fill="none"
      viewBox="0 0 21 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.7637 9.99992V14.1666C15.7637 15.5473 13.5251 16.6666 10.7637 16.6666C8.00225 16.6666 5.76367 15.5473 5.76367 14.1666V9.99992M15.7637 9.99992V5.83325M15.7637 9.99992C15.7637 11.3806 13.5251 12.4999 10.7637 12.4999C8.00225 12.4999 5.76367 11.3806 5.76367 9.99992M15.7637 5.83325C15.7637 4.45254 13.5251 3.33325 10.7637 3.33325C8.00225 3.33325 5.76367 4.45254 5.76367 5.83325M15.7637 5.83325C15.7637 7.21396 13.5251 8.33325 10.7637 8.33325C8.00225 8.33325 5.76367 7.21396 5.76367 5.83325M5.76367 9.99992V5.83325"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </SvgIcon>
  );
}
