import { clsx } from 'clsx';
import React from 'react';

export type BadgeVariant =
  | 'new'
  | 'success'
  | 'failed'
  | 'running'
  | 'pending'
  | 'active';

export interface BadgeProps {
  variant?: string;
  children?: React.ReactNode;
  className?: string;
}

const variantStyles: Record<BadgeVariant, string> = {
  success: 'bg-green-500',
  new: 'bg-teal-500',
  failed: 'bg-amber-500 ',
  running: 'bg-blue-100',
  pending: 'bg-blue-100',
  active: 'bg-primaryBlue',
};

export default function Badge({
  variant = 'success',
  children,
  className,
}: BadgeProps) {
  const classes = clsx(
    'px-1.5 py-1 leading-none inline-flex align-baseline text-xs font-bold text-white whitespace-nowrap truncate rounded capitalize',
    className,
    variantStyles[variant as BadgeVariant],
  );

  return <span className={classes}>{children}</span>;
}
