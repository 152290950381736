import SvgIcon from '@mui/material/SvgIcon';
import type { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React from 'react';

export default function QuestionAnswerIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3333 6.66665H16.6667C17.1269 6.66665 17.5 7.03974 17.5 7.49998V16.6666L14.7225 14.3591C14.5729 14.2348 14.384 14.1666 14.1895 14.1666H7.5C7.03976 14.1666 6.66667 13.7936 6.66667 13.3333V10.8333M13.3333 6.66665V4.16665C13.3333 3.70641 12.9602 3.33331 12.5 3.33331H3.33333C2.8731 3.33331 2.5 3.70641 2.5 4.16665V13.3336L5.27751 11.0257C5.42712 10.9014 5.61603 10.8333 5.81055 10.8333H6.66667M13.3333 6.66665V9.99998C13.3333 10.4602 12.9602 10.8333 12.5 10.8333H6.66667"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </SvgIcon>
  );
}
