import type { TabsProps } from '@mui/material/Tabs';
import MuiTabs from '@mui/material/Tabs';
import { clsx } from 'clsx';
import React from 'react';

type Props = TabsProps;

export default function Tabs({ className, ...props }: Props) {
  const classes = clsx('custom-tabs', className);

  return <MuiTabs {...props} className={classes} />;
}
