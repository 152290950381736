import type { UseMutationResult, UseQueryResult } from '@tanstack/react-query';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type {
  RawAxiosResponse,
  SendSlackMessageResponse,
  SendSlackMessageRequestPayload,
  LlmTransformResponse,
} from 'api-types-shared';
import { stripImgsFromData } from 'execution-shared';
import type {
  DatasourceVariable,
  TargetMap,
  VariableMap,
  WorkflowAction,
  WorkflowData,
  WorkflowNode,
} from 'types-shared';
import type { Variable } from 'types-shared/workflowTypes';
import { AlertVariant, notify } from 'ui-kit';
import type {
  AutolinkItem,
  PollAutolinkTaskReponse,
  QueueAutolinkTaskRequest,
  QueueAutolinkTaskResponse,
} from 'dashboard-shared';
import { handleException } from 'sentry-browser-shared';

import { STUB_WORKFLOW_ID } from '../../constants';
import { useAPI } from '../../hooks/useApi';
import {
  getStubImageData,
  getStubNodeData,
  getStubWorkflowData,
} from './stub_hooks';
import type { EditorWorkflowDataProps } from './store/EditorState';
import { EditorStore } from './store/EditorState';
import { useEffect, useRef } from 'react';
import values from 'lodash/values';
import {
  NodeStatusEnum,
  CommitWorkflowState,
  WorkflowImageNode,
  NodeTypesEnum,
} from 'types-shared';

export const useFetchNodesImage = (
  workflowId: string,
  nodes: WorkflowNode[],
): UseQueryResult<WorkflowNode[]> => {
  const { workflowSDK: sdk } = useAPI();
  const imageNodes = nodes.filter((n) => n.type === NodeTypesEnum.Image);

  return useQuery<WorkflowNode[]>({
    queryKey: ['nodeImages', workflowId, imageNodes.map((n) => n.id).join(',')],
    queryFn: () => {
      return sdk.addImageToNodes(workflowId, nodes);
    },
    enabled:
      imageNodes.length > 0 &&
      imageNodes.every((n: WorkflowNode) => {
        const parsedImageNode = WorkflowImageNode.safeParse(n);
        if (parsedImageNode.success) {
          const imageNode = parsedImageNode.data;
          return !imageNode.data.imageData.thumbnailData;
        }
        return true;
      }),
    retry: false,
  });
};

export const useGetWorkflowData = (
  workflowId: string,
  hasPersistedData: boolean,
): UseQueryResult<WorkflowData | null> => {
  const { workflowSDK: sdk } = useAPI();

  return useQuery<WorkflowData | null>({
    queryKey: ['workflowData', workflowId],
    queryFn: async () => {
      if (workflowId === STUB_WORKFLOW_ID) {
        return getStubWorkflowData(workflowId);
      }
      const workflowData = await sdk.getWorkflowStateData(workflowId);
      if (workflowData?.nodes) {
        const nodesWithImage = await sdk.addImageToNodes(
          workflowId,
          workflowData.nodes,
        );
        workflowData.nodes = nodesWithImage;
      }
      return workflowData;
    },
    enabled: !hasPersistedData,
  });
};

interface GetRefProps {
  targetData: TargetMap;
  variableData: VariableMap;
}

export const useGetRefData = (
  workflowId: string,
  hasPersistedData: boolean,
): UseQueryResult<GetRefProps> => {
  const { workflowSDK: sdk } = useAPI();

  return useQuery<GetRefProps>({
    queryKey: ['nodeData', workflowId],
    queryFn: async () => {
      if (workflowId === STUB_WORKFLOW_ID) {
        return getStubNodeData(workflowId);
      }
      const [variableData, targetData] = await Promise.all([
        sdk.getWorkflowVariableData(workflowId),
        sdk.getWorkflowTargetData(workflowId),
      ]);
      return { variableData, targetData };
    },
    enabled: !hasPersistedData,
  });
};

export const useGetOriginalImageData = (
  workflowId: string | undefined,
  imageId: string,
  focused: boolean,
): UseQueryResult<Blob | null> => {
  const { workflowSDK: sdk } = useAPI();

  return useQuery<Blob | null>({
    queryKey: ['image', focused, workflowId, imageId],
    queryFn: async () => {
      if (!focused || !workflowId) {
        return null;
      }
      if (workflowId === STUB_WORKFLOW_ID) {
        const imageMap = await getStubImageData(workflowId, [imageId]);
        return imageMap[imageId];
      }
      const imageMap = await sdk.getImageData(workflowId, [imageId], true);
      return imageMap[imageId];
    },
  });
};

export function useUpdateWorkflow(): UseMutationResult<
  string,
  Error,
  { workflowId: string; editorState: EditorWorkflowDataProps }
> {
  const { workflowSDK: sdk } = useAPI();
  const queryClient = useQueryClient();
  return useMutation<
    string,
    Error,
    { workflowId: string; editorState: EditorWorkflowDataProps }
  >({
    mutationFn: async ({
      workflowId,
      editorState,
    }: {
      workflowId: string;
      editorState: EditorWorkflowDataProps;
    }) => {
      const committedWorkflowState = CommitWorkflowState.safeParse(editorState);
      if (!committedWorkflowState.success) {
        notify({
          message: `Workflow update failed: ${committedWorkflowState.error.message}`,
          variant: AlertVariant.ERROR,
          debug: true,
        });
        return workflowId;
      }
      await sdk.updateAllWorkflowData(workflowId, committedWorkflowState.data);

      await queryClient.invalidateQueries({
        queryKey: ['workflowStateData', workflowId],
      });
      notify({
        message: `Workflow updated successfully`,
        variant: AlertVariant.SUCCESS,
      });
      return workflowId;
    },
  });
}

export function useUpdateWorkflowState(): UseMutationResult<
  string,
  Error,
  { workflowId: string; workflowData: WorkflowData }
> {
  const { workflowSDK: sdk } = useAPI();
  const queryClient = useQueryClient();
  return useMutation<
    string,
    Error,
    { workflowId: string; workflowData: WorkflowData }
  >({
    mutationFn: async ({
      workflowId,
      workflowData,
    }: {
      workflowId: string;
      workflowData: WorkflowData;
    }) => {
      const strippedWorkflowData = stripImgsFromData(workflowData);
      await sdk.updateWorkflowStateData(workflowId, strippedWorkflowData);
      await queryClient.invalidateQueries({
        queryKey: ['workflowStateData', workflowId],
      });
      notify({
        message: `Workflow updated successfully`,
        variant: AlertVariant.SUCCESS,
        debug: true,
      });
      return workflowId;
    },
  });
}

export function useUpdateWorkflowVariableData(): UseMutationResult<
  string,
  Error,
  { workflowId: string; variableData: VariableMap }
> {
  const { workflowSDK: sdk } = useAPI();
  const queryClient = useQueryClient();
  return useMutation<
    string,
    Error,
    { workflowId: string; variableData: VariableMap }
  >({
    mutationFn: async ({
      workflowId,
      variableData,
    }: {
      workflowId: string;
      variableData: VariableMap;
    }) => {
      await sdk.updateWorkflowVariableData(workflowId, variableData);
      await queryClient.invalidateQueries({
        queryKey: ['workflowVariableData', workflowId],
      });
      notify({
        message: `Workflow updated successfully`,
        variant: AlertVariant.SUCCESS,
      });
      return workflowId;
    },
  });
}

export function useUpdateWorkflowTargetData(): UseMutationResult<
  string,
  Error,
  { workflowId: string; targetData: TargetMap }
> {
  const { workflowSDK: sdk } = useAPI();
  const queryClient = useQueryClient();
  return useMutation<
    string,
    Error,
    { workflowId: string; targetData: TargetMap }
  >({
    mutationFn: async ({
      workflowId,
      targetData,
    }: {
      workflowId: string;
      targetData: TargetMap;
    }) => {
      await sdk.updateWorkflowTargetData(workflowId, targetData);
      await queryClient.invalidateQueries({
        queryKey: ['workflowTargetData', workflowId],
      });
      notify({
        message: `Workflow updated successfully`,
        variant: AlertVariant.SUCCESS,
      });
      return workflowId;
    },
  });
}

export function useAutolinkTask(
  req: QueueAutolinkTaskRequest,
): UseQueryResult<Promise<RawAxiosResponse<QueueAutolinkTaskResponse> | null>> {
  const { autolinkDemoSDK: sdk } = useAPI();
  return useQuery<Promise<RawAxiosResponse<QueueAutolinkTaskResponse> | null>>({
    queryKey: [
      'autolinkTask',
      req.variables.map<string>((v) => v.id).join('-'),
    ],
    queryFn: async () => {
      return sdk.queueAutolinkTask(req);
    },
  });
}

export function useAutolinkTaskPoller(taskId?: string | undefined) {
  const { autolinkDemoSDK: sdk } = useAPI();
  const resp = useQuery<RawAxiosResponse<PollAutolinkTaskReponse>>({
    queryKey: ['taskId', taskId],
    queryFn: async () => {
      return sdk.pollAutolinkTask(String(taskId));
    },
    refetchInterval: (data) => {
      return ['expired', 'finished'].includes(data.state.status) ? false : 1000;
    },
    enabled: Boolean(taskId),
  });
  if (resp.data?.data.status === 'expired') {
    handleException(new Error(`Autolink task ${taskId} expired`), {
      name: 'Autolink Error',
      source: 'AutolinkDemo',
    });
  }

  return resp;
}

export function useUpdateStoreAutolinkData(
  autolinkData?: PollAutolinkTaskReponse,
) {
  const { addVariable, updateVariableData, nodes, updateNode } = EditorStore();
  const nodesRef = useRef<WorkflowNode[]>([]);

  useEffect(() => {
    if (nodes.length !== 0) {
      nodesRef.current = nodes;
    }
  }, [nodes]);

  useEffect(() => {
    if (autolinkData?.status === 'finished') {
      const data = JSON.parse(autolinkData.data ?? '[]') as AutolinkItem[];
      const templateVarIds: string[] = [];
      data.forEach(({ variableId, datasource }: AutolinkItem) => {
        if (datasource) {
          templateVarIds.push(variableId);
          const datasourceVariable = JSON.parse(
            datasource,
          ) as DatasourceVariable;
          datasourceVariable.name = datasourceVariable.data.key;
          addVariable(datasourceVariable);

          updateVariableData(variableId, {
            data: [datasourceVariable],
          });
        }
      });
      if (templateVarIds.length > 0) {
        nodesRef.current.forEach((node) => {
          if (node.type === NodeTypesEnum.Image) {
            values(node.data.actionData).forEach((action: WorkflowAction) => {
              if (
                action.variableId &&
                templateVarIds.includes(action.variableId)
              ) {
                updateNode({
                  ...node,
                  data: {
                    ...node.data,
                    nodeStatus: NodeStatusEnum.Autolinked,
                  },
                });
              }
            });
          }
        });
      }
    }
  }, [addVariable, autolinkData, updateNode, updateVariableData]);
}

export function useSendSlackMessage() {
  const { miscSDK: sdk } = useAPI();
  return useMutation<
    SendSlackMessageResponse,
    Error,
    SendSlackMessageRequestPayload
  >({
    mutationFn: async (request) => {
      const data = await sdk.sendSlackMessage(request);
      notify({
        message: `Your query has been submitted to our support team. We will get back to you soon!`,
        variant: AlertVariant.INFO,
      });
      return data;
    },
  });
}

export const useQueueAutolinkTask = (): UseMutationResult<
  string,
  Error,
  { datasourceId: string | null; variables: Variable[] }
> => {
  const { autolinkDemoSDK: autolinkSdk, datasourceSDK } = useAPI();

  const queryClient = useQueryClient();
  return useMutation<
    string,
    Error,
    { datasourceId: string | null; variables: Variable[] }
  >({
    mutationFn: async ({ datasourceId, variables }) => {
      if (!datasourceId) {
        throw new Error('Datasource not found!');
      }

      const datasourceData = await datasourceSDK.getDatasource({
        params: { datasourceId },
        query: { csvReq: true },
        body: {},
      });

      const csvUrl = datasourceData.csvUrl;

      if (!csvUrl) {
        throw new Error('CSV URL not found');
      }

      const response = await autolinkSdk.queueAutolinkTask({
        document: {
          datasource_id: datasourceId,
          media_type: 'text/csv',
          uri: csvUrl,
        },
        variables: Object.values(variables),
      });

      await queryClient.invalidateQueries({
        queryKey: ['datasources', datasourceId],
      });

      return response.data.task_id;
    },
  });
};

export function useTransformData() {
  const { transformSDK: sdk } = useAPI();
  return useMutation<
    LlmTransformResponse | null,
    Error,
    { data: string; prompt: string }
  >({
    mutationFn: async (request) => {
      const data = await sdk.transform(request.data, request.prompt);
      return data;
    },
  });
}
