import type {
  ActionMap,
  ExtensionData,
  Target,
  TargetMap,
  Variable,
  VariableMap,
  WorkflowAction,
} from 'types-shared';
import {
  Actions,
  BrowserDownloadAction,
  BrowserUrlAction,
  ExtensionAction,
  ExtensionInputAction,
  ExtensionMultiChoiceAction,
  ExtensionScrapeAction,
  ExtensionSelectAction,
  VariableType,
  handleZodCheck,
} from 'types-shared';
import { v4 as uuidv4 } from 'uuid';
import {
  calculateRelativeCoordinates,
  stitchStateImages,
} from './imageHelpers';

// Define the structure of the return type of parseActions
export interface ParseActionsReturnType {
  variableStore: VariableMap;
  targetStore: TargetMap;
  actionDataArray: {
    actionData: ActionMap;
    nodeUrls: string[];
    actionOrder: string[];
  }[];
}

export const parseActions = (
  actions: ExtensionData['actions'],
  stateDims: { width: number; height: number }[],
): ParseActionsReturnType => {
  const variableStore: VariableMap = {};
  const targetStore: TargetMap = {};

  let scrapeIx = 1;

  const actionDataArray = actions.map((subArray, index) => {
    const actionData: ActionMap = {};
    const nodeUrls: string[] = [];
    const actionOrder: string[] = [];

    const nodeTargetSet = new Set<string>();
    let currentTarget: string | null = null;

    subArray.reverse().forEach((extensionSubdata) => {
      handleZodCheck(extensionSubdata, BrowserUrlAction, (parsedData) => {
        const { url } = parsedData.data;
        nodeUrls.push(url);
        nodeTargetSet.clear();
      });

      handleZodCheck(extensionSubdata, BrowserDownloadAction, (parsedData) => {
        const newAction: WorkflowAction = {
          actionType: Actions.enum.Download,
          id: uuidv4(),
          options: {
            download: [parsedData.data],
          },
        };
        actionData[newAction.id] = newAction;
        actionOrder.push(newAction.id);
      });

      handleZodCheck(
        extensionSubdata,
        ExtensionAction,
        (parsedExtensionData) => {
          const { target, coordinates } = parsedExtensionData;
          const relativeCoordinates = calculateRelativeCoordinates(
            stateDims[index],
            coordinates,
          );

          const targetPath = target.labelText?.cssPath || target.cssPath;

          if (!nodeTargetSet.has(targetPath) || currentTarget !== targetPath) {
            nodeTargetSet.add(targetPath);
            currentTarget = targetPath;

            const newTarget: Target = {
              id: uuidv4(),
              name: target.name,
              ref: target,
              coordinates: relativeCoordinates,
            };
            targetStore[newTarget.id] = newTarget;

            let newVariable: Variable = {
              id: uuidv4(),
              type: VariableType.enum.Template,
              data: [''],
            };
            let newActionType = Actions.enum.Click;

            handleZodCheck(
              extensionSubdata,
              ExtensionMultiChoiceAction,
              (parsedData) => {
                const { target: multiChoiceTarget } = parsedData;
                const multiChoiceOptions = multiChoiceTarget.multiChoiceOptions;
                const selectedChoiceIndex = multiChoiceOptions.findIndex(
                  (option) => option.cssPath === multiChoiceTarget.cssPath,
                );
                newVariable = {
                  id: uuidv4(),
                  type: VariableType.enum.MultiChoice,
                  multiChoiceOptions,
                  selectedChoiceIx:
                    selectedChoiceIndex !== -1 ? selectedChoiceIndex : null,
                  data: [String(selectedChoiceIndex)],
                };
                newActionType = Actions.enum.MultiChoice;
              },
            );

            handleZodCheck(
              extensionSubdata,
              ExtensionSelectAction,
              (parsedData) => {
                newVariable = {
                  id: uuidv4(),
                  type: VariableType.enum.Select,
                  selectOptions: parsedData.target.selectOptions,
                  data: [parsedData.currentSelected.text || ''],
                };
                newActionType = Actions.enum.Select;
              },
            );

            handleZodCheck(
              extensionSubdata,
              ExtensionInputAction,
              (parsedData) => {
                newVariable = {
                  id: uuidv4(),
                  type: VariableType.enum.Template,
                  data: [parsedData.currentText || ''],
                };
                newActionType = Actions.enum.Input;
              },
            );

            handleZodCheck(
              extensionSubdata,
              ExtensionScrapeAction,
              (parsedData) => {
                newVariable = {
                  id: uuidv4(),
                  type: VariableType.enum.Scrape,
                  data: {
                    scrapedText: parsedData.scrapedText,
                    selector: parsedData.target,
                  },
                  name: target.name || `Scrape ${scrapeIx++}`,
                };
                newActionType = Actions.enum.Scrape;
              },
            );

            variableStore[newVariable.id] = newVariable;

            const newAction: WorkflowAction = {
              actionType: newActionType,
              targetId: newTarget.id,
              variableId: newVariable.id,
              id: uuidv4(),
            };
            actionData[newAction.id] = newAction;
            actionOrder.push(newAction.id);
          }
        },
      );
    });

    return {
      actionData,
      nodeUrls: nodeUrls.reverse(),
      actionOrder: actionOrder.reverse(),
    };
  });

  return { variableStore, targetStore, actionDataArray };
};

export const extensionDataParse = async ({
  actions: originalActions,
  scans: originalScans,
}: ExtensionData) => {
  // remove empty arrays
  const actions = originalActions;
  const scans = originalScans.filter((scan) => scan.length > 0);
  const stitchedImages = await stitchStateImages(scans);
  const parsedActions = parseActions(actions, stitchedImages);
  return { actions: parsedActions, states: stitchedImages };
};
