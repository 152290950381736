import {
  type ZodFetcher,
  type CreateDatasourceRequest,
  type CreateDatasourceResponse,
  createDatasourceSchema,
  createUploadUrlSchema,
  type CreateUploadUrlsRequest,
  type CreateUploadUrlsResponse,
  type GetDatasourceRequest,
  type GetDatasourceResponse,
  getDatasourceSchema,
  type ListDatasourcesRequest,
  type ListDatasourcesResponse,
  listDatasourcesSchema,
  zodAxios,
} from 'api-types-shared';
import type { KyInstance, Options } from 'ky';
import { DatasourceTable } from 'types-shared/datasourceTypes';
import { apiEndpoints, type NodeEnv } from 'ui-kit';
import { handleException } from 'sentry-browser-shared';

import { createZodKyFetcher } from '../fetcher';

export class DatasourceSDK {
  private _kyFetcher: ZodFetcher<KyInstance>;
  readonly endpoint: string;

  constructor(env: NodeEnv, kyOpts?: Options) {
    this.endpoint = apiEndpoints[env].datasourceApiV1;
    this._kyFetcher = createZodKyFetcher(kyOpts);
  }

  fetchDatasourcesList = async (
    requestData: ListDatasourcesRequest,
  ): Promise<ListDatasourcesResponse> => {
    const {
      query: { workflowId },
    } = listDatasourcesSchema.request.parse(requestData);

    const url = workflowId
      ? `${this.endpoint}?workflowId=${workflowId}`
      : this.endpoint;

    return this._kyFetcher(listDatasourcesSchema.response, url, {
      method: 'get',
    }).catch((err) => {
      handleException(err, {
        userMessage: { title: 'Failed to fetch datasources' },
      });
      return { datasourceMetas: [], googleSheetMetas: [] };
    });
  };

  createDatasource = async (
    requestData: CreateDatasourceRequest,
  ): Promise<CreateDatasourceResponse> => {
    const parsedRequestData = createDatasourceSchema.request.parse(requestData);

    const response = await this._kyFetcher(
      createDatasourceSchema.response,
      this.endpoint,
      {
        method: 'post',
        body: JSON.stringify(parsedRequestData.body),
      },
    ).catch((error) => {
      handleException(error, {
        userMessage: { title: 'Failed to create datasource' },
        extra: { data: parsedRequestData },
      });
    });

    return createDatasourceSchema.response.parse(response);
  };

  getDatasource = async (
    requestData: GetDatasourceRequest,
  ): Promise<GetDatasourceResponse> => {
    const parsedRequestData = getDatasourceSchema.request.parse(requestData);

    // TODO(michael): Replace kyFetcher with authentication headers
    const response = await zodAxios(
      getDatasourceSchema.response,
      `${this.endpoint}/${parsedRequestData.params.datasourceId}`,
      { method: 'get', params: parsedRequestData.query },
    ).catch((error) => {
      handleException(error, {
        userMessage: { title: 'Failed to get datasource' },
      });
    });

    return getDatasourceSchema.response.parse(response);
  };

  getDatasourceTable = async (
    tableUrl: string,
  ): Promise<DatasourceTable | undefined> => {
    return zodAxios(DatasourceTable, tableUrl, {
      method: 'get',
    }).catch((error) => {
      handleException(error, {
        userMessage: { title: 'Failed to fetch datasource table' },
        source: 's3',
      });
      return undefined;
    });
  };

  createUploadUrl = async (
    requestData: CreateUploadUrlsRequest,
  ): Promise<CreateUploadUrlsResponse> => {
    const { params, body, query } =
      createUploadUrlSchema.request.parse(requestData);

    const url = `${this.endpoint}/${params.datasourceId}/upload-url`;

    // TODO(michael): Replace kyFetcher with authentication headers
    const response = await zodAxios(createUploadUrlSchema.response, url, {
      method: 'post',
      data: body,
      params: query,
    }).catch((error) => {
      handleException(error, {
        userMessage: { title: 'Failed to create upload url' },
      });
    });

    return createUploadUrlSchema.response.parse(response);
  };
}
