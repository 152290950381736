import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { clsx } from 'clsx';
import isNil from 'lodash/isNil';
import orderBy from 'lodash/orderBy';
import type { ReactElement } from 'react';
import React, { useEffect, useMemo, useState } from 'react';

import SortableHeader from './SortableHeader';
import TableLoadingSkeleton from './TableLoadingSkeleton';
import TableRowRenderer from './TableRowRenderer';
import { SortOrder } from './utils/constants';
import { getSortOptions } from './utils/helper';

export interface TableColumn<T> {
  id: string;
  title: string;
  cellRenderer?: (row: { values: T; columnValue: string }) => ReactElement;
  onClick?: (row: T) => void;
  isHidden?: boolean;
  clickable?: boolean;
  className?: string;
  sortType?: string | null;
  sortable?: boolean;
}

interface Classes {
  root?: string;
  table?: string;
  loader?: string;
}

interface Props<T> {
  columns?: TableColumn<T>[];
  rows: T[];
  columnKey: keyof T;
  showVerticalDivider?: boolean;
  classes?: Classes;
  onRowClick?: (row: T) => void;
  stickyHeader?: boolean;
  loading?: boolean;
  rowRenderer?: ({ values }: { values: T }) => ReactElement;
  defaultSorting?: string;
  isError?: boolean;
  errorMessage?: string;
  placeholderMessage?: string;
}

function Table<T>({
  columnKey,
  columns = [],
  rows = [],
  classes,
  showVerticalDivider = false,
  defaultSorting,
  onRowClick,
  stickyHeader = false,
  loading,
  rowRenderer,
  isError,
  errorMessage,
  placeholderMessage,
}: Props<T>) {
  const [sortBy, setSortBy] = useState('');
  const messageClasses =
    'flex w-full justify-center p-8 empty-placeholder-label';
  const tableClasses = clsx('flex flex-col h-fit', classes?.root);
  const tableHeaderClasses =
    '!text-sm !text-primary !font-semibold !py-4 !truncate';

  useEffect(() => {
    setSortBy(defaultSorting ?? '');
  }, [defaultSorting]);

  const [sortOrder, sortField] = useMemo(
    () => getSortOptions(sortBy),
    [sortBy],
  );

  const sortedRows = useMemo(
    () =>
      orderBy(
        rows,
        sortField,
        sortOrder === 'asc' ? SortOrder.ASC : SortOrder.DESC,
      ),
    [rows, sortField, sortOrder],
  );

  return (
    <>
      <TableContainer className={tableClasses}>
        <MuiTable className={classes?.table} stickyHeader={stickyHeader}>
          <TableHead>
            <TableRow>
              {columns.map(
                (col: TableColumn<T>) =>
                  !col.isHidden && (
                    <TableCell
                      className={clsx(tableHeaderClasses, col.className)}
                      key={col.title}
                    >
                      <SortableHeader
                        column={col}
                        setSortBy={setSortBy}
                        sortBy={sortBy}
                      />
                    </TableCell>
                  ),
              )}
            </TableRow>
          </TableHead>
          {!isError && !isNil(sortedRows) && sortedRows.length > 0 && (
            <TableBody>
              {sortedRows.map((row: T) => {
                if (rowRenderer) {
                  const RowRenderer = rowRenderer;
                  return <RowRenderer key={String(row)} values={row} />;
                }

                return (
                  <TableRowRenderer<T>
                    columns={columns}
                    key={String(row[columnKey])}
                    onRowClick={onRowClick}
                    row={row as T & Record<string, string>}
                    showVerticalDivider={showVerticalDivider}
                  />
                );
              })}
            </TableBody>
          )}
        </MuiTable>
        {!loading && !isError && !sortedRows.length && (
          <p className={messageClasses}>
            {placeholderMessage ?? 'No data found'}
          </p>
        )}
        {isError ? (
          <p className={messageClasses}>
            {errorMessage ?? 'Failed to load data'}
          </p>
        ) : null}
      </TableContainer>
      {loading ? (
        <TableLoadingSkeleton
          className={classes?.loader}
          cols={columns.length - 1}
          count={4}
        />
      ) : null}
    </>
  );
}

export default Table;
