"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.popExecutionsSchema = void 0;
var types_shared_1 = require("types-shared");
var extension_execution_schema_1 = require("./extension-execution.schema");
exports.popExecutionsSchema = {
    request: types_shared_1.z.object({
        query: types_shared_1.z.object({}).nullable(),
        body: types_shared_1.z.object({
            numExecutions: types_shared_1.z.number().default(1),
            bypassSQS: types_shared_1.z.boolean().default(false),
            setId: types_shared_1.uuidSchema.optional(),
        }),
    }),
    response: extension_execution_schema_1.startExecutionBodySchema,
};
