import ClearIcon from '@mui/icons-material/Clear';
import type { DialogProps } from '@mui/material/Dialog';
import MuiDialog from '@mui/material/Dialog';
import { clsx } from 'clsx';
import noop from 'lodash/noop';
import React from 'react';

import Button from '../Button/index';

interface Classes {
  dialog?: string;
  content?: string;
}

export type Props = DialogProps & {
  classes?: Classes;
  title?: string;
  open: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  confirmText?: string;
  disableBackdropClick?: boolean;
  disableConfirmButton?: boolean;
  confirmLoading?: boolean;
  showActions?: boolean;
  hideCloseBtn?: boolean;
};

function Dialog({
  classes,
  title,
  onConfirm = noop,
  disableBackdropClick,
  disableConfirmButton,
  confirmLoading,
  confirmText,
  showActions,
  hideCloseBtn,
  ...props
}: Props) {
  const cootentClasses = clsx(
    'py-4 px-6 flex flex-col space-y-4 relative text-sm',
    classes?.content,
  );
  const dialogClasses = clsx('custom-dialog', classes?.dialog);

  return (
    <MuiDialog
      {...props}
      className={dialogClasses}
      onClose={(event, reason) => {
        if (reason === 'backdropClick' && disableBackdropClick) return;
        props.onClose();
      }}
    >
      <div className={cootentClasses}>
        {title ? <h2 className="text-lg font-semibold">{title}</h2> : null}
        {!hideCloseBtn && (
          <Button
            className="!absolute top-4 right-3 !m-0 !p-0 !min-w-max !mr-1"
            color="inherit"
            onClick={props.onClose}
            type="button"
            variant="text"
          >
            <ClearIcon />
          </Button>
        )}
        {props.children}
        {showActions ? (
          <div className="flex justify-end items-center space-x-2 !mt-8">
            <Button onClick={props.onClose} type="button" variant="outlined">
              Cancel
            </Button>
            <Button
              color="primary"
              disabled={disableConfirmButton}
              loading={confirmLoading}
              onClick={onConfirm}
              type="submit"
              variant="contained"
            >
              {confirmText ?? 'Proceed'}
            </Button>
          </div>
        ) : null}
      </div>
    </MuiDialog>
  );
}

export default Dialog;
