import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React from 'react';

export default function DeleteIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6666 8.33333V14.1667M8.33325 8.33333V14.1667M4.99992 5V14.8333C4.99992 15.7668 4.99992 16.2331 5.18157 16.5897C5.34136 16.9033 5.59615 17.1587 5.90975 17.3185C6.26592 17.5 6.73241 17.5 7.66401 17.5H12.3358C13.2674 17.5 13.7332 17.5 14.0894 17.3185C14.403 17.1587 14.6587 16.9033 14.8184 16.5897C14.9999 16.2335 14.9999 15.7675 14.9999 14.8359V5M4.99992 5H6.66659M4.99992 5H3.33325M6.66659 5H13.3333M6.66659 5C6.66659 4.22343 6.66659 3.83534 6.79345 3.52905C6.96261 3.12067 7.28685 2.79602 7.69523 2.62687C8.00152 2.5 8.39002 2.5 9.16659 2.5H10.8333C11.6098 2.5 11.9981 2.5 12.3044 2.62687C12.7128 2.79602 13.0371 3.12067 13.2063 3.52905C13.3332 3.83534 13.3333 4.22343 13.3333 5M13.3333 5H14.9999M14.9999 5H16.6666"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </SvgIcon>
  );
}
