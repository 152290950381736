import { withAuthenticationRequired } from '@auth0/auth0-react';
import React from 'react';
import type { ComponentType } from 'react';

import Spinner from '../Spinner';

interface Props<P> {
  component: ComponentType<P>;
  componentProps?: P;
}

export default function AuthenticationGuard<P extends object>({
  component: Component,
  componentProps: props = {} as P,
}: Props<P>) {
  if (!process.env.REACT_APP_AUTH0_DOMAIN) {
    return <Component {...props} />;
  }

  const AuthedComponent = withAuthenticationRequired(Component, {
    // eslint-disable-next-line react/no-unstable-nested-components
    onRedirecting: () => <Spinner />,
  });

  return <AuthedComponent {...props} />;
}
