import { clsx } from 'clsx';
import size from 'lodash/size';
import React, { type MouseEvent, useRef, useState } from 'react';
import { ActionsIcon } from 'assets-shared';
import type { WorkflowNodeProps } from 'types-shared';
import { NodeStatusEnum } from 'types-shared';
import { IconButton, Menu, MenuItem } from 'ui-kit';
import { MoreVertRounded } from '@mui/icons-material';

interface Props {
  workflowData: WorkflowNodeProps;
  showFullContent: boolean;
  isAdminMode?: boolean;
  handleAction?: (action: 'copyNode' | 'deleteNode' | 'updateImage') => void;
}

export function ImageNodeCore({
  workflowData,
  showFullContent,
  isAdminMode,
  handleAction,
}: Props) {
  const {
    data: { imageData, actionData, nodeStatus: status },
  } = workflowData;

  const imgRef = useRef<HTMLImageElement>(null);
  const { thumbnailData } = imageData;

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchorEl);
  const actionDataSize = size(actionData);

  const openMenu = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  };

  const closeMenu = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setMenuAnchorEl(null);
  };

  return (
    <>
      {showFullContent ? (
        <>
          <div className="relative flex-1 rounded-lg overflow-hidden border border-indigo-light w-full">
            <img
              alt=""
              className={clsx('h-full w-full object-cover', {
                'bg-gray-300': !thumbnailData,
              })}
              ref={imgRef}
              src={
                thumbnailData instanceof Blob
                  ? URL.createObjectURL(thumbnailData)
                  : undefined
              }
            />
          </div>
          <div className="flex items-center space-x-0.5">
            <ActionsIcon className="text-info -ml-1" />
            <span className="text-sm font-bold">
              {actionDataSize}
              {status === NodeStatusEnum.NotViewed
                ? ` ${actionDataSize > 1 ? 'actions' : 'action'} to review`
                : null}
            </span>
          </div>
          {isAdminMode && handleAction ? (
            <div className="absolute right-0 bottom-1">
              <IconButton aria-haspopup="true" onClick={openMenu}>
                <MoreVertRounded />
              </IconButton>
              <Menu anchorEl={menuAnchorEl} onClose={closeMenu} open={menuOpen}>
                <MenuItem
                  key="copy-node"
                  onClick={(e) => {
                    closeMenu(e);
                    handleAction('copyNode');
                  }}
                >
                  Copy Node
                </MenuItem>
                <MenuItem
                  key="delete-node"
                  onClick={(e) => {
                    closeMenu(e);
                    handleAction('deleteNode');
                  }}
                >
                  Delete Node
                </MenuItem>
                <MenuItem
                  key="update-image"
                  onClick={(e) => {
                    closeMenu(e);
                    handleAction('updateImage');
                  }}
                >
                  Update Image
                </MenuItem>
              </Menu>
            </div>
          ) : null}
        </>
      ) : (
        <img
          alt=""
          className={clsx('h-full w-full object-cover', {
            'bg-gray-300': !thumbnailData,
          })}
          ref={imgRef}
          src={
            thumbnailData instanceof Blob
              ? URL.createObjectURL(thumbnailData)
              : undefined
          }
        />
      )}
    </>
  );
}
