import React, { useEffect, useMemo, useState } from 'react';
import type { WorkflowImageNode, WorkflowNodeProps } from 'types-shared';
import { NodeTypesEnum } from 'types-shared';
import { useStore } from 'types-shared/reactflow';

import { minZoomLevelForFullContent } from '../../utils/constants';
import NodeElement from './index';
import { nodeSelectEventChannel } from '../ActionsHeader';
import { EditorStore, type EditorStoreProps } from '../../store/EditorState';
import { useShallow } from 'zustand/react/shallow';
import { ImageNodeCore } from 'editor-shared';
import Checkbox from '@mui/material/Checkbox';
import { CheckCircleIcon } from 'ui-kit';
import { RadioButtonUncheckedOutlined } from 'assets-shared';

const zoomSelector = (s: { transform: number[] }) =>
  s.transform[2] >= minZoomLevelForFullContent;

export default function ImageNode(workflowData: WorkflowNodeProps) {
  const { id } = workflowData;
  const { nodes, setNodes, setSelectedNode } = EditorStore(
    useShallow((state: EditorStoreProps) => ({
      nodes: state.nodes,
      setNodes: state.setNodes,
      setSelectedNode: state.setSelectedNode,
    })),
  );

  const selectedNode: WorkflowImageNode | undefined = useMemo(() => {
    const workflowNode = nodes.find((node) => node.id === id);
    if (workflowNode?.type === NodeTypesEnum.Image)
      return workflowNode as WorkflowImageNode;
    return undefined;
  }, [id, nodes]);

  const showFullContent = useStore(zoomSelector);

  const [nodeSelectionModeEnabled, setNodeSelectionModeEnabled] =
    useState<boolean>(false);

  useEffect(() => {
    const unsubscribe = nodeSelectEventChannel.on(
      'onToggleSelectionMode',
      (isEnabled) => {
        setNodeSelectionModeEnabled(isEnabled);
      },
    );

    return () => {
      unsubscribe();
    };
  }, []);

  const toggleNodeSelection = () => {
    const filteredNodes = nodes.map((node) => {
      if (node.id !== id || node.type !== NodeTypesEnum.Image) return node;

      return {
        ...node,
        data: {
          ...node.data,
          selected: !(node as WorkflowImageNode).data.selected,
        },
      };
    });
    setNodes(filteredNodes);
  };

  return (
    <div>
      <NodeElement
        onClick={() => {
          setSelectedNode(workflowData.id);
        }}
        showAddButton
        workflowData={workflowData}
      >
        <ImageNodeCore
          showFullContent={showFullContent}
          workflowData={workflowData}
        />
      </NodeElement>
      <div className="mt-4 w-full flex justify-center">
        {nodeSelectionModeEnabled ? (
          <div
            className="bg-white aspect-square rounded-xl absolute !cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              toggleNodeSelection();
            }}
            role="presentation"
          >
            <Checkbox
              checked={selectedNode?.data.selected || false}
              checkedIcon={
                <CheckCircleIcon className="!fill-none" strokeColor="#246bfa" />
              }
              className="!p-4"
              icon={<RadioButtonUncheckedOutlined />}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}
