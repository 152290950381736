"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkflowData = exports.WorkflowEdge = exports.WorkflowNode = exports.WorkflowContinueNode = exports.WorkflowFreeformNode = exports.WorkflowConditionalNode = exports.WorkflowNewNode = exports.WorkflowDatasourceNode = exports.WorkflowImageNode = exports.NodeData = exports.NodeStatus = exports.NodeStatusEnum = exports.ActionMap = exports.NodeImageData = exports.SelectedAction = exports.TargetStateData = exports.TargetMap = exports.Target = exports.WorkflowAction = exports.WorkflowActionsOptions = exports.NodeTypes = exports.NodeTypesEnum = void 0;
var zod_1 = require("zod");
var actionTypes_1 = require("../actionTypes");
var react_flow_schema_1 = require("./react-flow.schema");
var NodeTypesEnum;
(function (NodeTypesEnum) {
    NodeTypesEnum["Image"] = "image";
    NodeTypesEnum["Datasource"] = "datasource";
    NodeTypesEnum["New"] = "new";
    NodeTypesEnum["Conditional"] = "conditional";
    NodeTypesEnum["Freeform"] = "freeform";
    NodeTypesEnum["Continue"] = "continue";
})(NodeTypesEnum || (exports.NodeTypesEnum = NodeTypesEnum = {}));
exports.NodeTypes = zod_1.z.nativeEnum(NodeTypesEnum);
exports.WorkflowActionsOptions = zod_1.z.object({
    hidden: zod_1.z.boolean().optional(),
    hitl: zod_1.z.boolean().optional(),
    download: zod_1.z.array(actionTypes_1.DownloadData).optional(),
});
exports.WorkflowAction = zod_1.z.object({
    id: zod_1.z.string(),
    actionType: actionTypes_1.Actions,
    targetId: zod_1.z.string().optional(),
    variableId: zod_1.z.string().optional(),
    title: zod_1.z.string().optional(),
    options: exports.WorkflowActionsOptions.optional(),
});
exports.Target = zod_1.z.object({
    id: zod_1.z.string(),
    name: zod_1.z.string().optional(),
    ref: actionTypes_1.QueryPaths,
    coordinates: actionTypes_1.RelativeCoordinates,
});
exports.TargetMap = zod_1.z.record(exports.Target);
exports.TargetStateData = zod_1.z.object({
    targets: exports.TargetMap,
});
exports.SelectedAction = zod_1.z.object({
    nodeId: zod_1.z.string(),
    actionId: zod_1.z.string(),
});
exports.NodeImageData = zod_1.z.object({
    imageId: zod_1.z.string(),
    originalData: zod_1.z.instanceof(Blob).nullable().optional(),
    thumbnailData: zod_1.z.instanceof(Blob).nullable().optional(),
});
exports.ActionMap = zod_1.z.record(exports.WorkflowAction);
var NodeStatusEnum;
(function (NodeStatusEnum) {
    NodeStatusEnum["NotViewed"] = "NotViewed";
    NodeStatusEnum["Viewed"] = "Viewed";
    NodeStatusEnum["Checked"] = "Checked";
    NodeStatusEnum["Autolinked"] = "Autolinked";
    NodeStatusEnum["Warning"] = "Warning";
    NodeStatusEnum["Error"] = "Error";
})(NodeStatusEnum || (exports.NodeStatusEnum = NodeStatusEnum = {}));
exports.NodeStatus = zod_1.z.nativeEnum(NodeStatusEnum);
exports.NodeData = zod_1.z.object({
    imageData: exports.NodeImageData,
    actionData: exports.ActionMap,
    actionOrder: zod_1.z.array(zod_1.z.string()),
    nodeUrls: zod_1.z.array(zod_1.z.string()),
    nodeStatus: exports.NodeStatus,
    selected: zod_1.z.boolean().optional(),
});
exports.WorkflowImageNode = react_flow_schema_1.RfNode.extend({
    type: zod_1.z.literal(NodeTypesEnum.Image),
    data: exports.NodeData,
});
exports.WorkflowDatasourceNode = react_flow_schema_1.RfNode.extend({
    type: zod_1.z.literal(NodeTypesEnum.Datasource),
    data: exports.NodeData.pick({ nodeStatus: true }),
});
exports.WorkflowNewNode = react_flow_schema_1.RfNode.extend({
    type: zod_1.z.literal(NodeTypesEnum.New),
    data: exports.NodeData.pick({ nodeStatus: true }),
});
exports.WorkflowConditionalNode = react_flow_schema_1.RfNode.extend({
    type: zod_1.z.literal(NodeTypesEnum.Conditional),
    data: exports.NodeData.pick({ nodeStatus: true }),
});
exports.WorkflowFreeformNode = react_flow_schema_1.RfNode.extend({
    type: zod_1.z.literal(NodeTypesEnum.Freeform),
    data: exports.NodeData.pick({ nodeStatus: true }).extend({
        instructions: zod_1.z.string().optional(),
    }),
});
exports.WorkflowContinueNode = react_flow_schema_1.RfNode.extend({
    type: zod_1.z.literal(NodeTypesEnum.Continue),
    data: exports.NodeData.pick({ nodeStatus: true }),
});
exports.WorkflowNode = zod_1.z.union([
    exports.WorkflowImageNode,
    exports.WorkflowDatasourceNode,
    exports.WorkflowNewNode,
    exports.WorkflowConditionalNode,
    exports.WorkflowFreeformNode,
    exports.WorkflowContinueNode,
]);
exports.WorkflowEdge = react_flow_schema_1.RfEdge.extend({
    data: zod_1.z
        .object({
        actionIndex: zod_1.z.number(),
    })
        .optional(),
});
exports.WorkflowData = zod_1.z.object({
    nodes: zod_1.z.array(exports.WorkflowNode),
    edges: zod_1.z.array(exports.WorkflowEdge),
    selectedAction: exports.SelectedAction.nullable().default(null).optional(),
    selectedNode: zod_1.z.string().nullable().default(null).optional(),
    bulkSelectMode: zod_1.z.boolean().default(false).optional(),
    workflowId: zod_1.z.string().optional(),
});
