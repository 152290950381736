import type { LlmTransformResponse } from 'api-types-shared';
import { llmTransformSchema, zodAxios } from 'api-types-shared';
import { handleException } from 'sentry-browser-shared';
import type { NodeEnv } from 'ui-kit';
import { apiEndpoints } from 'ui-kit';

export class TransformSDK {
  readonly endpoint: string;

  constructor(env: NodeEnv) {
    this.endpoint = apiEndpoints[env].transformApiV1;
  }

  async transform(
    data: string,
    prompt: string,
    cache = false,
  ): Promise<LlmTransformResponse | null> {
    const request = llmTransformSchema.request.parse({
      params: {},
      query: {},
      body: {
        data,
        prompt,
        cache,
      },
    });
    const response = await zodAxios(
      llmTransformSchema.response,
      this.endpoint,
      // Todo(rafic): switch to using API Gateway
      // `${this.endpoint}/llm-process`,
      {
        method: 'post',
        data: request.body,
      },
    ).catch((err) => {
      handleException(err, {
        userMessage: { title: 'Failed to transform data' },
      });
      return null;
    });
    return response;
  }
}
