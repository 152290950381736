import type {
  Variable,
  WorkflowData,
  WorkflowEdge,
  WorkflowNode,
} from 'types-shared';
import { NodeStatusEnum, NodeTypesEnum } from 'types-shared';
import { v4 as uuid } from 'uuid';

export const formatLabelString = (variableData: Variable): string => {
  const { type } = variableData;
  switch (type) {
    default:
      return JSON.stringify(variableData);
  }
};

export const getImgsFromData = (nodes: WorkflowNode[]) => {
  const imgs: string[] = [];
  nodes.forEach((node: WorkflowNode) => {
    if (node.type === NodeTypesEnum.Image) {
      imgs.push(node.data.imageData.imageId);
    }
  });

  return imgs;
};

export const isUUID = (str: string) => {
  const uuidRegex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidRegex.test(str);
};

export const appendDatasourceNode = (
  workflowData: WorkflowData,
): WorkflowData => {
  if (!workflowData.nodes.every((node) => node.type === NodeTypesEnum.Image)) {
    return workflowData;
  }
  const dataSourceNodeId = uuid();
  const nodes: WorkflowNode[] = [
    {
      id: dataSourceNodeId,
      position: {
        x: -400,
        y: 0,
      },
      type: NodeTypesEnum.Datasource,
      width: 1719,
      height: 5127,
      data: {
        nodeStatus: NodeStatusEnum.NotViewed,
      },
    },
    ...workflowData.nodes,
  ];
  const edges: WorkflowEdge[] = [
    {
      id: uuid(),
      source: dataSourceNodeId,
      target: workflowData.nodes[0].id,
    },
    ...workflowData.edges,
  ];
  return {
    ...workflowData,
    nodes,
    edges,
  };
};
