import { ExecutionStatusEnum, ExecutionTriggerEnum, NodeTypesEnum, } from 'types-shared';
import { v4 as uuidv4 } from 'uuid';
import { sendToBackgroundViaRelay } from '@plasmohq/messaging';
import { stripImgsFromData } from './utils';
export const executeWorkflowWithExtension = ({ workflowId, recordIds, workflowData, tableData, setId, executionIds = [], }) => {
    const executionSetId = setId ?? uuidv4();
    const { nodes, edges, variables, targets } = workflowData;
    const { nodes: executionNodes, edges: executionEdges } = keepImageNodes(stripImgsFromData({
        nodes,
        edges,
    }));
    const executions = recordIds.map((recordId, index) => {
        return {
            trigger: ExecutionTriggerEnum.Manual,
            createdAt: new Date().toISOString(),
            executionId: executionIds[index] ?? uuidv4(),
            workflowId,
            setId: executionSetId,
            recordId: recordId.toString(),
            status: ExecutionStatusEnum.Running,
            recordData: tableData?.rowData[parseInt(recordId)] || {},
            adminRun: false,
        };
    });
    const startExecutionBody = {
        data: {
            [workflowId]: {
                workflowData: {
                    nodes: executionNodes,
                    edges: executionEdges,
                    variables,
                    targets,
                },
            },
        },
        setId: executionSetId,
        executions,
    };
    return sendExecutionMessageToExtension(startExecutionBody);
};
export const sendExecutionMessageToExtension = (startExecutionBody) => {
    return sendToBackgroundViaRelay({
        name: 'onExecution.handler',
        body: startExecutionBody,
    });
};
const keepImageNodes = ({ nodes, edges, }) => {
    const imgNodes = nodes.filter((node) => node.type === NodeTypesEnum.Image);
    const imgEdges = edges.filter((edge) => imgNodes.some((node) => node.id === edge.source) &&
        imgNodes.some((node) => node.id === edge.target));
    return { nodes: imgNodes, edges: imgEdges };
};
