import isNil from 'lodash/isNil';
import { useNavigate } from 'react-router-dom';
import type { WorkflowNodeProps } from 'types-shared';
import { useStore } from 'types-shared/reactflow';

import { EditorStore } from '../../store/EditorState';
import { minZoomLevelForFullContent } from '../../utils/constants';
import NodeElement from './index';
import { DatasourceNodeCore } from 'editor-shared';

const zoomSelector = (s: { transform: number[] }) =>
  s.transform[2] >= minZoomLevelForFullContent;

export default function DatasourceNode(workflowData: WorkflowNodeProps) {
  const showFullContent = useStore(zoomSelector);
  const { datasourceMetadata, workflowId, tableData } = EditorStore();
  const hasDatasource = !isNil(datasourceMetadata);
  const navigate = useNavigate();

  const openDatasourcePage = () => {
    navigate(`/datasource/${workflowId}`);
  };
  const handleNodeClick = () => {
    if (!hasDatasource) {
      openDatasourcePage();
      return;
    }
    navigate(`/datasource/${workflowId}/preview`);
  };

  return (
    <NodeElement onClick={handleNodeClick} workflowData={workflowData}>
      <DatasourceNodeCore
        datasourceMetadata={datasourceMetadata}
        openDatasourcePage={openDatasourcePage}
        showFullContent={showFullContent}
        tableData={tableData}
      />
    </NodeElement>
  );
}
