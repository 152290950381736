import { Checkbox } from 'ui-kit';
import { NodeStatusEnum } from 'types-shared';
import React from 'react';
import { clsx } from 'clsx';
import { CheckCircle, RadioButtonUnchecked } from 'assets-shared';

interface Props {
  isChecked: boolean;
  updateNodeStatus: (status: NodeStatusEnum) => void;
}

export function NodeCheck({ isChecked, updateNodeStatus }: Props) {
  return (
    <div
      className={clsx(
        'border border-info p-4 pr-1 rounded-lg flex justify-between items-center ring-8 ring-transparent space-x-4',
        {
          'border-checked-green ring-primary-light-green': isChecked,
        },
      )}
    >
      <div className="flex flex-col space-y-0.5 text-sm">
        <span className="font-medium">Ready to Run</span>
        <span className="text-gray-500">
          If all actions are configured, mark as reviewed!
        </span>
      </div>
      <Checkbox
        checked={isChecked}
        checkedIcon={<CheckCircle />}
        color="secondary"
        icon={<RadioButtonUnchecked className="!text-info" />}
        onChange={() => {
          updateNodeStatus(
            isChecked ? NodeStatusEnum.NotViewed : NodeStatusEnum.Checked,
          );
        }}
      />
    </div>
  );
}
