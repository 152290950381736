import type { Item } from '../VariableInput';
import { Button } from 'ui-kit';
import { EditorStore } from '../../store/EditorState';
import { Fragment, useMemo } from 'react';
import { VariableTypeEnum } from 'types-shared';
import type { ScrapeVariable } from 'types-shared';

interface Props {
  onCancel: () => void;
  onEdit: () => void;
  variable: Item | ScrapeVariable;
}

function PreviewVariable({ variable, onCancel, onEdit }: Props) {
  const { variables, datasourceMetadata, tableData } = EditorStore();
  const isScrape = variable.type === VariableTypeEnum.Scrape;

  const variableData = useMemo(() => {
    if (!isScrape) {
      return variables[(variable.value as { id: string }).id];
    }
    return null;
  }, [variable, isScrape, variables]);

  const variableExample = useMemo(() => {
    if (variableData) {
      const [defaultRow = {}] = tableData?.rowData ?? [];
      return defaultRow[(variableData.data as { key: string }).key] as string;
    }
    return null;
  }, [tableData?.rowData, variableData]);

  return (
    <div className="flex-1">
      <div className="border my-6 -mx-4" />
      <div className="text-sm mb-10">
        <p className="font-medium mb-2 text-info">Variable Preview</p>
        <p className="text-gray-500">
          {variableExample ?? 'This is a subtitle helping users to understand'}
        </p>
      </div>
      <div className="text-sm mb-5">
        <p className="font-medium mb-2 text-info">Variable Name</p>
        <p className="text-gray-500">
          {isScrape ? variable.name : variableData?.name}
        </p>
      </div>
      <div className="text-sm mb-5">
        <p className="font-medium mb-2 text-info">Source</p>
        <p className="text-gray-500">
          {datasourceMetadata?.name ?? 'Scraped during the run'}
        </p>
      </div>

      {!isScrape ? (
        <div className="text-sm mb-5">
          <p className="font-medium mb-2 text-info">Column name</p>
          <p className="text-gray-500">
            {variableData ? (variableData.data as { key: string }).key : ''}
          </p>
        </div>
      ) : null}

      <div className="text-sm mb-5">
        <p className="font-medium mb-2 text-info">Transformation applied</p>
        <p className="text-gray-500">
          {variableData?.previewTransform ?? 'No transformations'}
        </p>
      </div>
      <div className="text-sm mb-14">
        <p className="font-medium mb-2 text-info">Value example</p>
        <p className="text-gray-500">
          {isScrape
            ? variable.data.scrapedText?.split('\n').map((line: string) => (
                <Fragment key={line}>
                  {line}
                  <br />
                </Fragment>
              ))
            : variableExample}
        </p>
      </div>

      <div className="flex flex-row justify-between gap-4 mt-auto">
        <Button
          className="flex-1"
          color="secondary"
          onClick={onEdit}
          variant="contained"
        >
          EDIT VARIABLE
        </Button>
        <Button
          className="flex-1"
          color="secondary"
          onClick={onCancel}
          variant="outlined"
        >
          CANCEL
        </Button>
      </div>
    </div>
  );
}

export default PreviewVariable;
