import type { MenuItemProps } from '@mui/material/MenuItem';
import MuiMenuItem from '@mui/material/MenuItem';
import { clsx } from 'clsx';
import React from 'react';

function MenuItem(props: MenuItemProps) {
  return (
    <MuiMenuItem
      {...props}
      className={clsx('!px-3 !py-2.5 !text-sm', props.className)}
    >
      {props.children}
    </MuiMenuItem>
  );
}

export default MenuItem;
