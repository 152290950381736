import ActionsManager from './ActionsManager';
import { useParams } from 'react-router-dom';
import SelectedImageNodeContent from './NodeElement/SelectedImageNodeContent';
import { EditorStore, type EditorStoreProps } from '../store/EditorState';
import { useShallow } from 'zustand/react/shallow';
import { IconButton } from '@mui/material';
import { ChevronRight, ChevronLeft } from 'assets-shared';
import { useMemo, useState } from 'react';
import { WorkflowImageNode } from 'types-shared';
import { AlertVariant, notify } from 'ui-kit';

export default function ActionView() {
  const { workflowId } = useParams();
  if (!workflowId) {
    throw new Error('Workflow ID not provided');
  }

  const {
    selectedNode: selectedNodeId,
    nodes,
    setSelectedNode,
  } = EditorStore(
    useShallow((state: EditorStoreProps) => ({
      selectedNode: state.selectedNode,
      nodes: state.nodes,
      setSelectedNode: state.setSelectedNode,
    })),
  );
  const imageNodes = useMemo(() => nodes.slice(1), [nodes]); // skip the datasource node
  const selectedNode = useMemo(() => {
    const _selectedNode = selectedNodeId
      ? imageNodes.find((n) => n.id === selectedNodeId)
      : null;
    const parsedSelectedNode = WorkflowImageNode.safeParse(_selectedNode);
    if (!parsedSelectedNode.success) {
      notify({
        message: `Selected node is not a valid image node: ${JSON.stringify(
          parsedSelectedNode.error,
        )}`,
        variant: AlertVariant.ERROR,
        debug: true,
      });
      return null;
    }
    return parsedSelectedNode.data;
  }, [selectedNodeId, imageNodes]);

  const { isFirst, isLast, currentIndex } = useMemo(() => {
    const selectedIndex: number = imageNodes.findIndex(
      (n) => n.id === selectedNodeId,
    );
    return {
      isFirst: selectedIndex === 0,
      isLast: selectedIndex === imageNodes.length - 1,
      currentIndex: selectedIndex,
    };
  }, [imageNodes, selectedNodeId]);

  const [hasSelectedAction, setHasSelectedAction] = useState<boolean>(false);

  if (!selectedNode) return null;

  return (
    <div className="fixed inset-0">
      <div className="relative w-full h-full flex">
        <ActionsManager setHasSelectedAction={setHasSelectedAction} />
        <div className="w-full flex justify-center items-center dotted-bg">
          {!isFirst && !hasSelectedAction ? (
            <IconButton
              className="!bg-white !mr-6"
              onClick={() => {
                const previousNode = imageNodes[currentIndex - 1];
                setSelectedNode(previousNode.id);
              }}
            >
              <ChevronLeft />
            </IconButton>
          ) : (
            <div className="w-[40px] mr-6" role="none" />
          )}
          <div className="relative !w-4/5">
            <span className="absolute left-1/2 -top-12 -translate-x-1/2 text-sm font-semibold bg-white px-3 py-1 rounded-lg min-w-max">
              {selectedNode.id}
            </span>
            <SelectedImageNodeContent
              className="!w-full max-h-[80vh] h-auto"
              nodeData={selectedNode.data}
            />
          </div>
          {!isLast && !hasSelectedAction ? (
            <IconButton
              className="!bg-white !ml-6"
              onClick={() => {
                const nextNode = imageNodes[currentIndex + 1];
                setSelectedNode(nextNode.id);
              }}
            >
              <ChevronRight />
            </IconButton>
          ) : (
            <div className="w-[40px] ml-6" role="none" />
          )}
        </div>
      </div>
    </div>
  );
}
