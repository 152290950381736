"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.listDatasourcesSchema = void 0;
var types_shared_1 = require("types-shared");
var utils_schema_1 = require("../utils.schema");
exports.listDatasourcesSchema = {
    request: types_shared_1.z.object({
        query: types_shared_1.z.object({
            workflowId: types_shared_1.z.string().optional(),
            sheetsReq: utils_schema_1.booleanStringTransform, // TODO(michael): Stop using this pattern ...
        }),
    }),
    response: types_shared_1.z.object({
        datasourceMetas: types_shared_1.z.array(types_shared_1.DatasourceMetadata),
        googleSheetMetas: types_shared_1.z.array(types_shared_1.GoogleDriveFileMetadata),
    }),
};
