"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.uploadGoogleSheetsSchema = exports.createUploadUrlSchema = exports.UploadUrlContentType = exports.UploadUrlContentTypeEnum = exports.UploadUrlType = exports.UploadUrlTypeEnum = void 0;
var types_shared_1 = require("types-shared");
var UploadUrlTypeEnum;
(function (UploadUrlTypeEnum) {
    UploadUrlTypeEnum["CSV"] = "CSV";
    UploadUrlTypeEnum["Table"] = "Table";
    UploadUrlTypeEnum["Document"] = "Document";
})(UploadUrlTypeEnum || (exports.UploadUrlTypeEnum = UploadUrlTypeEnum = {}));
exports.UploadUrlType = types_shared_1.z.nativeEnum(UploadUrlTypeEnum);
var UploadUrlContentTypeEnum;
(function (UploadUrlContentTypeEnum) {
    UploadUrlContentTypeEnum["PNG"] = "image/png";
    UploadUrlContentTypeEnum["PDF"] = "application/pdf";
    UploadUrlContentTypeEnum["JSON"] = "application/json";
    UploadUrlContentTypeEnum["CSV"] = "text/csv";
})(UploadUrlContentTypeEnum || (exports.UploadUrlContentTypeEnum = UploadUrlContentTypeEnum = {}));
exports.UploadUrlContentType = types_shared_1.z.nativeEnum(UploadUrlContentTypeEnum);
exports.createUploadUrlSchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.DatasourceMetadata.pick({
            datasourceId: true,
        }),
        query: types_shared_1.z.object({
            uploadUrlType: exports.UploadUrlType,
        }),
        body: types_shared_1.z.object({
            filename: types_shared_1.z.string(),
            contentType: exports.UploadUrlContentType,
        }),
    }),
    response: types_shared_1.z.object({
        method: types_shared_1.z.literal('PUT'),
        url: types_shared_1.z.string(),
        fields: types_shared_1.z.object({}),
    }),
};
exports.uploadGoogleSheetsSchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.DatasourceMetadata.pick({ datasourceId: true }),
        query: types_shared_1.z.object({}).optional(),
        body: types_shared_1.z.object({
            sheetId: types_shared_1.z.string(),
        }),
    }),
    response: types_shared_1.z.object({
        success: types_shared_1.z.boolean(),
    }),
};
