import { CloseIcon } from 'assets-shared';
import { Button, FormControlLabel, Radio, RadioGroup } from 'ui-kit';
import React, { useState } from 'react';

interface Props {
  onContinue: (nodeType: string) => void;
  onCancel: () => void;
}

export function OptionsBlock({ onCancel, onContinue }: Props) {
  const [nodeType, setNodeType] = useState<string>('continue-recording');

  return (
    <div className="absolute left-2 top-2 bottom-2 w-120 bg-white rounded-lg z-[10] p-8 flex flex-col items-stretch space-y-5">
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-medium text-info-dark">Block Types</h2>
        <button
          className="border border-info rounded-lg h-10 w-10 flex justify-center items-center"
          onClick={onCancel}
          type="button"
        >
          <CloseIcon className="text-info" />
        </button>
      </div>
      <div className="flex-1">
        <p className="text-sm font-bold text-info-dark mb-2">
          Select the actions or block type
        </p>
        <RadioGroup
          name="node-types-group-2"
          onChange={(e, v) => {
            setNodeType(v);
          }}
          value={nodeType}
        >
          <FormControlLabel
            control={<Radio color="secondary" />}
            label="Continue recording"
            value="continue-recording"
          />
          <FormControlLabel
            control={<Radio color="secondary" />}
            label="Conditional logic"
            value="conditional"
          />
          <FormControlLabel
            control={<Radio color="secondary" />}
            label="Freeform block"
            value="freeform"
          />
        </RadioGroup>
      </div>
      <div className="flex w-full gap-9">
        <Button
          className="!flex-1"
          color="secondary"
          onClick={() => {
            onContinue(nodeType);
          }}
          variant="contained"
        >
          Continue
        </Button>
        <Button
          className="!flex-1"
          color="secondary"
          onClick={onCancel}
          variant="outlined"
        >
          Cancel
        </Button>
      </div>
    </div>
  );
}
