import { MoreVert } from 'assets-shared';
import Switch from '@mui/material/Switch';
import { clsx } from 'clsx';
import type { Item } from '../VariableInput';
import VariableInput from '../VariableInput';
import MultipleChoice from '../MultipleChoice';
import Select from '../Select';
import values from 'lodash/values';
import { useEffect, useMemo, useState } from 'react';
import type {
  MultiChoiceVariable,
  ScrapeVariable,
  SelectVariable,
  Variable,
  VariableRef,
  WorkflowAction,
} from 'types-shared';
import { VariableTypeEnum, ActionsEnum } from 'types-shared';
import { Button, IconButton, Chip } from 'ui-kit';
import { v4 as uuidv4 } from 'uuid';

import { EditorStore } from '../../store/EditorState';
import ActionHeader from './ActionHeader';
import AddVariable from './AddVariable';
import PreviewVariable from './PreviewVariable';
import { imageNodeEventChannel } from '../NodeElement/SelectedImageNodeContent';
import { handleException } from 'sentry-browser-shared';

interface Props {
  i: number;
  action: WorkflowAction;
  setSelectedAction: (val: null) => void;
}

export type VariableData = string | VariableRef;

const transformVariableData = (
  variable: Variable,
  actionType: ActionsEnum,
): Item[] =>
  (variable.data as VariableData[]).map((item: VariableData) => ({
    id: uuidv4(),
    type: typeof item === 'string' ? 'string' : 'variable',
    value:
      actionType === ActionsEnum.MultiChoice
        ? (variable as MultiChoiceVariable).selectedChoiceIx?.toString() ?? ''
        : item,
  }));

function EditAction({ i, action, setSelectedAction }: Props) {
  const {
    datasourceMetadata,
    variables: variablesMap,
    updateVariable,
  } = EditorStore();
  const isScrape = action.actionType === ActionsEnum.Scrape;
  const hasDatasource = Boolean(datasourceMetadata);
  const variable = action.variableId ? variablesMap[action.variableId] : null;
  const [variableData, setVariableData] = useState<Item[]>([]);
  const [previewVariableIndex, setPreviewVariableIndex] = useState<
    number | null
  >(null);
  const [editVariableIndex, setEditVariableIndex] = useState<number | null>(
    null,
  );

  const data: Item[] = useMemo(
    () =>
      variable && !isScrape
        ? transformVariableData(variable as Variable, action.actionType)
        : [],
    [variable, isScrape, action.actionType],
  );

  const variables = useMemo(() => {
    const selectedVariableIds = variableData
      .filter((item: Item) => item.type !== 'string')
      .map((item: Item) => (item.value as VariableRef).id);
    return values(variablesMap).filter(
      ({ id, type }: Variable) =>
        [VariableTypeEnum.Scrape, VariableTypeEnum.Datasource].includes(type) &&
        !selectedVariableIds.includes(id),
    );
  }, [variablesMap, variableData]);

  const saveVariableChanges = () => {
    const formattedVariableData = variableData.map((item: Item) => item.value);
    let updatedVariable = {
      ...variable,
    };
    if (action.actionType === ActionsEnum.MultiChoice) {
      updatedVariable = {
        ...updatedVariable,
        selectedChoiceIx: formattedVariableData[0]
          ? Number(formattedVariableData[0])
          : null,
      };
    } else {
      updatedVariable = {
        ...updatedVariable,
        data: formattedVariableData,
      };
    }
    updateVariable(updatedVariable as Variable);
  };

  useEffect(() => {
    if (action.targetId) {
      imageNodeEventChannel.emit('onActionHover', action.targetId);
    }

    return () => {
      imageNodeEventChannel.emit('onBlur');
    };
  }, [action.targetId]);

  useEffect(() => {
    setVariableData(data);
  }, [data]);

  const editVariableData = useMemo(() => {
    if (editVariableIndex !== null && !isScrape) {
      const variableId = (data[editVariableIndex].value as { id: string }).id;
      return variablesMap[variableId];
    }
  }, [data, editVariableIndex, variablesMap, isScrape]);

  const [openAddNew, setOpenAddNew] = useState<boolean>(false);
  const toggleOpenAddNew = (state: boolean) => () => {
    setOpenAddNew(state);
  };

  const onAddNewVariable = () => {
    saveVariableChanges();
    setOpenAddNew(true);
  };

  const onPreviewVariable = (selectedIndex: number) => {
    !isScrape && saveVariableChanges();
    setPreviewVariableIndex(selectedIndex);
  };

  const onClosePreviewVariable = () => {
    setPreviewVariableIndex(null);
  };

  const handleEditVariable = (index: number) => () => {
    setPreviewVariableIndex(null);
    setEditVariableIndex(index);
  };

  const onCloseEditVariable = () => {
    setEditVariableIndex(null);
  };

  if (!variable) {
    handleException(new Error('Variable not found in action manager'), {});
    return null;
  }

  return (
    <>
      <ActionHeader isAddNewVariable={openAddNew} selectedAction={action} />
      <div className="flex-1 flex flex-col">
        <div className="flex justify-between items-center">
          <h2 className="flex items-center space-x-3">
            <span
              className={clsx(
                'text-xs text-white rounded-full h-6 w-6 flex justify-center items-center',
                {
                  'text-gray-600 bg-gray-300 ': openAddNew,
                  ' bg-gray-800': !openAddNew,
                },
              )}
            >
              {i}
            </span>
            <span
              className={clsx('font-medium text-lg', {
                'text-gray-500': openAddNew,
              })}
            >
              {openAddNew
                ? 'Text entry: Name'
                : `${action.actionType}${variable.name ? `: ${variable.name}` : ''}`}
            </span>
          </h2>
          <IconButton className="!p-0">
            <MoreVert className="!w-5 !h-5 text-black" />
          </IconButton>
        </div>
        <div className="my-10 border rounded-lg px-4 py-6 text-sm flex flex-col">
          <span className="font-medium mb-2 text-info">
            {editVariableIndex !== null ? 'Editing variable' : 'Value'}
          </span>
          {!openAddNew &&
          previewVariableIndex === null &&
          editVariableIndex === null &&
          action.actionType !== ActionsEnum.MultiChoice &&
          !isScrape ? (
            <span className="text-gray-500 mb-6">
              You can modify the selected option by adding variables that match
              the items in the dropdown.
            </span>
          ) : null}

          {isScrape ? (
            <span className="text-gray-500 mb-6"> Scraped during the run </span>
          ) : null}

          {editVariableIndex === null &&
          action.actionType === ActionsEnum.Input ? (
            <VariableInput
              allowAddVariable={hasDatasource}
              className="mt-4"
              data={variableData}
              disabled={openAddNew || previewVariableIndex !== null}
              label={openAddNew ? 'Name' : variable.name ?? ''}
              onAddNew={onAddNewVariable}
              onChange={setVariableData}
              onPreview={onPreviewVariable}
              previewVariableIndex={previewVariableIndex}
              variables={variables}
              variablesMap={variablesMap}
            />
          ) : null}
          {action.actionType === ActionsEnum.MultiChoice ? (
            <MultipleChoice
              className="mt-4"
              data={variableData}
              disabled={openAddNew || previewVariableIndex !== null}
              onChange={setVariableData}
              options={(variable as MultiChoiceVariable).multiChoiceOptions}
            />
          ) : null}
          {editVariableIndex === null &&
          action.actionType === ActionsEnum.Select ? (
            <Select
              allowAddVariable={hasDatasource}
              className="mt-4"
              data={variableData}
              disabled={openAddNew || previewVariableIndex !== null}
              label={openAddNew ? 'Name' : variable.name ?? ''}
              onAddNew={onAddNewVariable}
              onChange={setVariableData}
              onPreview={onPreviewVariable}
              options={(variable as SelectVariable).selectOptions}
              previewVariableIndex={previewVariableIndex}
              variables={variables}
              variablesMap={variablesMap}
            />
          ) : null}
          {editVariableIndex !== null || isScrape ? (
            <div className="">
              <Chip
                clickable={isScrape}
                color="secondary"
                label={isScrape ? variable.name : editVariableData?.name}
                onClick={() => {
                  isScrape && onPreviewVariable(1);
                }}
                size="small"
                sx={{ '& .MuiChip-deleteIcon': { display: 'none' } }}
              />
            </div>
          ) : null}

          {openAddNew ? (
            <AddVariable action={action} onCancel={toggleOpenAddNew(false)} />
          ) : null}
          {previewVariableIndex !== null ? (
            <PreviewVariable
              onCancel={onClosePreviewVariable}
              onEdit={handleEditVariable(previewVariableIndex)}
              variable={
                isScrape
                  ? (variable as ScrapeVariable)
                  : data[previewVariableIndex]
              }
            />
          ) : null}
          {editVariableIndex !== null ? (
            <AddVariable
              onCancel={onCloseEditVariable}
              variable={
                isScrape
                  ? (variable as ScrapeVariable)
                  : data[editVariableIndex]
              }
            />
          ) : null}
        </div>

        {!openAddNew &&
        previewVariableIndex === null &&
        editVariableIndex === null ? (
          <>
            <div className="border rounded-lg px-4 py-6 flex justify-between items-center text-sm font-medium">
              {isScrape ? (
                <div className="flex flex-col">
                  <span className="text-info">Output of a run</span>
                  <span className="text-gray-500 font-normal">
                    This is an explanatory subtitle
                  </span>
                </div>
              ) : (
                <span>Add conditional logic</span>
              )}
              <Switch size="small" />
            </div>
            <span className="flex-1" />
            <Button
              className="!text-info !border-info !mt-5"
              color="secondary"
              onClick={() => {
                !isScrape && saveVariableChanges();
                setSelectedAction(null);
              }}
              variant="outlined"
            >
              {isScrape ? 'Back to actions' : 'Save & Exit'}
            </Button>
          </>
        ) : null}
      </div>
    </>
  );
}

export default EditAction;
