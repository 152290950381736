import { CheckedCheckmarkIcon, Flex } from 'ui-kit';

interface Props {
  onClick?: () => void;
  platform: 'google' | '';
  isConnected?: boolean;
}
export default function IntegrationTile({
  onClick,
  platform,
  isConnected = false,
}: Props) {
  return (
    <Flex
      className="min-w-80 p-7 relative transition max-w-80 h-52 justify-center items-center rounded-lg border-2 border-color-light-blue hover:border-primary-blue cursor-pointer"
      onClick={onClick}
    >
      {platform === 'google' && (
        <img alt="G-suite" className="w-auto" src="/gsuite.png" />
      )}
      {isConnected ? (
        <CheckedCheckmarkIcon
          className="absolute top-4 right-4"
          color="success"
        />
      ) : null}
    </Flex>
  );
}
