import type { IconButtonProps } from '@mui/material/IconButton';
import MuiIconButton from '@mui/material/IconButton';
import React from 'react';

type Props = Pick<
  IconButtonProps,
  'className' | 'disabled' | 'onClick' | 'children' | 'color'
>;

export default function IconButton({ children, ...props }: Props) {
  return <MuiIconButton {...props}>{children}</MuiIconButton>;
}
