import { enableMapSet } from 'immer';
import type {
  DatasourceStateData,
  TargetStateData,
  VariableStateData,
  WorkflowData,
} from 'types-shared';
import { create } from 'zustand';
import type {
  PersistStorage,
  StateStorage,
  StorageValue,
} from 'zustand/middleware';
import { devtools, persist, subscribeWithSelector } from 'zustand/middleware';

import type { ExecutionStateActions, ExecutionStateData } from 'editor-shared';
import { ExecutionState } from 'editor-shared';

import type { DatasourceStateActions } from './DatasourceState';
import { DatasourceState } from './DatasourceState';
import { TargetState, type TargetStateActions } from './TargetState';
import { VariableState, type VariableStateActions } from './VariableState';
import { WorkflowState, type WorkflowStateActions } from './WorkflowState';
import type {
  IntegrationStateActions,
  IntegrationStateData,
} from './IntegrationState';
import { IntegrationState } from './IntegrationState';
import pickBy from 'lodash/pickBy';
import isFunction from 'lodash/isFunction';

enableMapSet();

const getWorkflowIdFromPath = () => {
  const urlPathnameArray = window.location.pathname.split('/');
  return urlPathnameArray[urlPathnameArray.length - 1];
};

export function createEditorStorage<S>(): PersistStorage<S> | undefined {
  const storage = localStorage as StateStorage;
  const persistStorage: PersistStorage<S> = {
    getItem: async (name) => {
      const urlPathnameArray = window.location.pathname.split('/');
      const workflowId = urlPathnameArray[urlPathnameArray.length - 1];
      if (name !== workflowId && name !== 'root') {
        return null;
      }
      const parse = (str: string | null) => {
        if (str === null) {
          return null;
        }
        return JSON.parse(str, (key, value: unknown) => {
          return value;
        }) as StorageValue<S>;
      };
      const str = storage.getItem(workflowId) ?? null;
      if (str instanceof Promise) {
        return str.then(parse);
      }
      return parse(str);
    },
    setItem: (name, newValue) => {
      const workflowId = getWorkflowIdFromPath();
      if (name !== workflowId) {
        return;
      }
      return storage.setItem(
        workflowId,
        JSON.stringify(newValue, (key, value: unknown) => {
          if (value instanceof Blob) {
            return null;
          }
          return value;
        }),
      );
    },
    removeItem: (name) => storage.removeItem(name),
  };
  return persistStorage;
}

export type EditorWorkflowDataProps = WorkflowData &
  VariableStateData &
  TargetStateData &
  DatasourceStateData;

export type EditorStoreDataProps = EditorWorkflowDataProps &
  IntegrationStateData &
  ExecutionStateData;

export type EditorStoreActionsProps = WorkflowStateActions &
  VariableStateActions &
  TargetStateActions &
  DatasourceStateActions &
  IntegrationStateActions &
  ExecutionStateActions;

export type EditorStoreProps = EditorStoreDataProps & EditorStoreActionsProps;

export const EditorStore = create<EditorStoreProps>()(
  subscribeWithSelector(
    persist(
      devtools(
        (...args) => ({
          ...WorkflowState(...args),
          ...VariableState(...args),
          ...TargetState(...args),
          ...DatasourceState(...args),
          ...IntegrationState(...args),
          ...ExecutionState(...args),
        }),
        { enabled: process.env.NODE_ENV === 'development' },
      ),
      {
        name: 'root',
        skipHydration: false,
        storage: createEditorStorage(),
        merge: (persistedState, currentState: EditorStoreProps) => {
          const _state = persistedState as EditorStoreProps | undefined;
          const workflowId = getWorkflowIdFromPath();
          const newState =
            workflowId === _state?.workflowId ? _state : currentState;
          const functions = pickBy(currentState, isFunction);
          return {
            ...newState,
            ...functions,
            workflowId,
          };
        },
      },
    ),
  ),
);
