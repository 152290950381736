import { MarkerType } from 'types-shared/reactflow';

export const defaultMarker = {
  type: MarkerType.Arrow,
  width: 10,
  height: 10,
  strokeWidth: 2,
  color: '#000',
};

export const editorMaxZoomLevel = 5;
export const zoomLevelForNode = 2.5;
export const editorMinZoomLevel = 0.1;
export const minZoomLevelForFullContent = 0.4;

// Known websites => WorflowIds
export const KNOWN_WORKFLOW_URLS_TO_WORKFLOW_IDS: Record<string, string> = {
  //'https://egov.maryland.gov': '00000000-0000-0000-0000-000000000000',
  // 'https://maps.cdtfa.ca.gov': '6e38cde6-a937-4559-bff5-c8742f214e31',
  // 'https://www.tax.ny.gov': '2e323a34-94d7-4f3b-8e93-e17999bb4e51',
  //'https://mtc.dor.state.ma.us': '45ae0bf8-4682-4c7f-9d4a-af374f67f13c',
};

export const KNOWN_WORKFLOW_IDS = Object.values(
  KNOWN_WORKFLOW_URLS_TO_WORKFLOW_IDS,
);

export const CONTACT_SLACK_CHANNEL_ID = 'C06G8CTGLLD';

export const DISTANCE_BETWEEN_NODES = 400;
