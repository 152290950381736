import SvgIcon from '@mui/material/SvgIcon';
import type { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React from 'react';

export default function Conditional(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      fill="none"
      viewBox="0 0 60 60"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 17.5H20.2352C21.0611 17.5 21.4723 17.5 21.8433 17.6185C22.1717 17.7233 22.4755 17.895 22.7344 18.1226C23.0269 18.3798 23.2397 18.734 23.6646 19.4421L36.3341 40.5581C36.759 41.2663 36.9717 41.6199 37.2642 41.8771C37.523 42.1047 37.826 42.2767 38.1543 42.3816C38.5251 42.5 38.9409 42.5 39.7656 42.5H52.5009M37.5 17.5H52.5"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
      />
    </SvgIcon>
  );
}
