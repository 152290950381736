import type { Components } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { theme as defaultTheme } from 'ui-kit';

interface ExtendedTypography {
  primary: string;
  secondary: string;
  tertiary: string;
  info: string;
}
const theme = createTheme({
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.60)',
      tertiary: 'rgb(122 133 156 / 90%)',
      info: '#103D61',
    } as ExtendedTypography,
  },
  components: {
    MuiDataGrid: {
      defaultProps: {
        disableColumnMenu: true,
        disableColumnSelector: true,
        pageSizeOptions: [10, 25, 50, 100],
        initialState: {
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        },
        rowSelection: false,
      },
      styleOverrides: {
        root: {
          border: 'none',
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-cell:focus-within': {
            outline: 'none',
          },
          '& .MuiDataGrid-columnHeader:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-columnHeader:focus-within': {
            outline: 'none',
          },
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          overflow: 'auto',
        },
        backdrop: {
          backgroundColor: 'rgba(0, 0, 0, 0.40)',
        },
      },
    },
  } as Components,
});

export default theme;
