import { executeWorkflowWithExtension } from './execute-with-extension';
import { ExecutionSDK } from './execution-sdk';
export class ExecutionRunner {
    env;
    _sdk;
    constructor(env) {
        this.env = env;
        this._sdk = new ExecutionSDK(env);
    }
    run = async ({ runType, workflowId, recordIds, workflowData, tableData, }) => {
        switch (runType) {
            case 'Local':
                return this.runLocal(workflowId, recordIds, workflowData, tableData);
            case 'Remote':
                return this.runRemote(workflowId, recordIds);
            default:
                throw new Error(`Unsupported runType: ${runType}`);
        }
    };
    runLocal = async (workflowId, recordIds, workflowData, tableData) => {
        if (recordIds.length !== 1) {
            throw new Error('Local runs only support one record at a time!');
        }
        if (!workflowData) {
            throw new Error('WorkflowData must be provided for local run');
        }
        const resp = await this._sdk.queueExecutions({
            params: {
                workflowId,
            },
            body: {
                setRecordKeys: recordIds,
                bypassSQS: true,
            },
        });
        if (process.env.REACT_APP_DEBUG) {
            // Queue another execution to execution as admin for observability
            const adminExecResp = await this._sdk.queueExecutions({
                params: {
                    workflowId,
                },
                body: {
                    setRecordKeys: recordIds,
                    adminRun: true,
                },
            });
            console.log(JSON.stringify(adminExecResp));
        }
        await executeWorkflowWithExtension({
            workflowId,
            recordIds,
            workflowData,
            tableData,
            setId: resp.executionSetId,
            executionIds: resp.queuedExecutionIds,
        });
        return resp;
    };
    runRemote = (workflowId, recordIds) => {
        return this._sdk.queueExecutions({
            params: {
                workflowId,
            },
            body: {
                setRecordKeys: recordIds,
                bypassSQS: false,
            },
        });
    };
    fetchExecutionMetas = (executionIds) => {
        return this._sdk.getMultipleExecutions({
            body: { executionIds },
        });
    };
}
