"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.listExecutionsSchema = void 0;
var types_shared_1 = require("types-shared");
exports.listExecutionsSchema = {
    request: types_shared_1.z.object({
        query: types_shared_1.z.object({
            workflowId: types_shared_1.z.string(),
            setId: types_shared_1.z.string().optional(),
        }),
    }),
    response: types_shared_1.z.array(types_shared_1.ExecutionBase),
};
