import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import type { Variable, SelectOption } from 'types-shared';
import Chip from '@mui/material/Chip';

interface Props {
  anchorEl: Element | null;
  open: boolean;
  onClose: () => void;
  onSelect: (val: Variable | string) => void;
  onAddNew?: () => void;
  options: SelectOption[];
  variables: Variable[];
  allowAddVariable: boolean;
}

function SelectMenu({
  anchorEl,
  open,
  onClose,
  onSelect,
  onAddNew,
  variables = [],
  options = [],
  allowAddVariable = true,
}: Props) {
  return (
    <Menu
      anchorEl={anchorEl}
      classes={{
        paper: '!max-w-none',
        list: '!p-0',
      }}
      disablePortal
      onClose={onClose}
      open={open}
      slotProps={{
        paper: {
          sx: {
            width: `${anchorEl?.clientWidth}px`,
          },
        },
      }}
      sx={{ '& .MuiBackdrop-root': { backgroundColor: 'transparent' } }}
    >
      {options.map(({ text, value }: SelectOption) => (
        <MenuItem
          className="!px-3 !py-2.5"
          divider
          key={value}
          onClick={() => {
            onSelect(text);
            onClose();
          }}
        >
          {text}
        </MenuItem>
      ))}
      {variables.map((variable: Variable, i) => (
        <MenuItem
          className="!px-3 !py-2.5"
          divider={i === variables.length - 1}
          key={variable.name}
          onClick={() => {
            onSelect(variable);
            onClose();
          }}
        >
          <Chip color="secondary" label={variable.name} size="small" />
        </MenuItem>
      ))}
      {allowAddVariable ? (
        <MenuItem
          className="!border-t !border-t-gray-300 !text-blue-600 !px-3 !py-2.5"
          onClick={() => onAddNew?.()}
        >
          Add New Variable
        </MenuItem>
      ) : null}
    </Menu>
  );
}

export default SelectMenu;
