import { clsx } from 'clsx';
import type { ReactNode } from 'react';
import React from 'react';
import { IconButton, Typography, Flex } from 'ui-kit';

import { LeftChevron } from 'assets-shared';

interface Props {
  onBack: () => void;
  pageInfo?: string | ReactNode;
  children?: ReactNode;
  className?: string;
}
export function Toolbar({ onBack, pageInfo, children, className }: Props) {
  return (
    <div className={clsx('px-6 py-5 !h-20 flex', className)}>
      <Flex alignItems="center">
        <Flex className="p-0 mr-6">
          <img alt="Logo" className="w-8 min-w-8" src="/logo.png" />
        </Flex>
        <IconButton
          className="!border !border-solid !border-info !rounded-lg !p-0"
          onClick={onBack}
        >
          <LeftChevron className="text-info !h-10 !w-10" />
        </IconButton>
        <Flex className="ml-6" flexDirection="column">
          <Typography color="text.secondary" variant="subtitle2">
            Home
          </Typography>
          <Typography
            className="font-medium"
            color="secondary"
            variant="subtitle2"
          >
            {pageInfo}
          </Typography>
        </Flex>
      </Flex>
      {children}
    </div>
  );
}
