import type { GridColDef } from '@mui/x-data-grid';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState, useRef } from 'react';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import { NoDataFound, Toolbar } from 'dashboard-shared';
import { WorkflowStatusEnum } from 'api-types-shared';
import type { ExecutionBase } from 'types-shared';
import { ExecutionStatusEnum } from 'types-shared';
import {
  Button,
  Chip,
  Typography,
  Flex,
  ContentContainer,
  ScrollableContainer,
  DataLoader,
  EditIcon,
} from 'ui-kit';
import type { ChangeEvent } from 'react';

import {
  useFetchWorkflowExecutionDetails,
  useFetchWorkflowMetadata,
  useUpdateWorkflowName,
} from '../hooks';
import { clsx } from 'clsx';

export const getStatusColor = (status: ExecutionStatusEnum) => {
  switch (status) {
    case ExecutionStatusEnum.Running:
      return 'primary';
    case ExecutionStatusEnum.Success:
      return 'success';
    default:
      return 'error';
  }
};

export default function WorkflowDetail() {
  const inputRef = useRef<HTMLInputElement>(null);
  const { workflowId } = useParams();
  if (!workflowId) {
    throw new Error('WorkflowId not found!');
  }

  const { data: workflowMetadata, isLoading: fetchingWorkflowMetadata } =
    useFetchWorkflowMetadata(workflowId);
  const navigate = useNavigate();
  const {
    isLoading: executionsListLoading,
    refetch: refetchExecutions,
    data: executions,
  } = useFetchWorkflowExecutionDetails(workflowId);
  // eslint-disable-next-line no-console
  console.log({ executions });

  const { mutateAsync: updateWorkflowMetadata } = useUpdateWorkflowName();
  const [isEditingName, setIsEditingName] = useState<boolean>(false);
  const [workflowName, setWorkflowName] = useState<string>('');

  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setWorkflowName(event.target.value);
  };

  const openExecutionDetailPage = (executionId: string) => {
    navigate(`/execution/${executionId}`);
  };

  const updateWorkflowName = async () => {
    setIsEditingName(false);
    if (inputRef.current) {
      inputRef.current.scrollLeft = 0;
    }
    if (workflowMetadata?.workflowName !== workflowName) {
      await updateWorkflowMetadata({ workflowId, workflowName });
    }
  };

  const columns: GridColDef<ExecutionBase>[] = [
    {
      field: 'executionId',
      headerName: 'Execution ID',
      flex: 1,
      valueGetter: (params) => params.row.executionId,
    },
    {
      field: 'recordId',
      headerName: 'Record ID',
      flex: 1,
      valueGetter: (params) => params.row.recordId,
    },
    {
      field: 'runId',
      headerName: 'Run ID',
      flex: 1,
      valueGetter: (params) => params.row.setId,
    },
    {
      field: 'startedAt',
      headerName: 'Date/Time',
      flex: 1,
      valueGetter: (params) =>
        dayjs(params.row.startedAt ?? params.row.createdAt).format(
          'MM/DD/YY -  h:mm A',
        ),
    },
    {
      field: 'trigger',
      headerName: 'Trigger',
      flex: 1,
      valueGetter: (params) => params.row.trigger,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => (
        <Chip
          className="!h-6"
          color={getStatusColor(params.row.status)}
          label={params.row.status.toLowerCase()}
          sx={{ '& .MuiChip-label': { textTransform: 'capitalize' } }}
          variant="filled"
        />
      ),
    },
    {
      field: 'action',
      headerName: '',
      align: 'right',
      renderCell: (params) => (
        <Button
          className="!text-primary-light-blue !border-primary-light-blue"
          onClick={() => {
            openExecutionDetailPage(params.row.executionId);
          }}
          variant="outlined"
        >
          View Details
        </Button>
      ),
      flex: 1,
      sortable: false,
    },
  ];

  useEffect(() => {
    if (workflowMetadata?.status === WorkflowStatusEnum.ProcessingImport) {
      navigate('/workflows');
    }
  }, [navigate, workflowMetadata]);

  useEffect(() => {
    if (workflowMetadata?.workflowName) {
      setWorkflowName(workflowMetadata.workflowName);
    }
  }, [workflowMetadata]);

  return (
    <ScrollableContainer>
      <Toolbar
        onBack={() => {
          navigate(-1);
        }}
        pageInfo={`Workflow ID: ${workflowId}`}
      />
      {workflowMetadata ? (
        <ContentContainer withToolbar>
          <Flex alignItems="flex-start" justifyContent="space-between">
            <Flex alignItems="flex-end">
              <EditIcon className="text-indigo-light mb-2 mr-1 !fill-none" />
              <input
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={isEditingName}
                className={clsx(
                  'border-b-2 border-transparent placeholder:text-indigo-light text-4xl font-bold focus:outline-0 !text-ellipsis',
                  {
                    'text-blue-500 !border-primary-blue': isEditingName,
                  },
                )}
                onBlur={updateWorkflowName}
                onChange={onNameChange}
                onClick={() => {
                  setIsEditingName(true);
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    updateWorkflowName();
                    event.currentTarget.blur();
                  }
                }}
                placeholder={workflowMetadata.workflowName}
                readOnly={!isEditingName}
                ref={inputRef}
                value={workflowName}
              />
            </Flex>
            <Button
              color="secondary"
              onClick={() => {
                navigate(`/editor/${workflowId}`);
              }}
              variant="contained"
            >
              Edit workflow
            </Button>
          </Flex>

          <div className="mt-6">
            {executionsListLoading ? (
              <DataLoader loadingText="Fetching workflow execution details" />
            ) : (
              <>
                {executions && executions.length > 0 ? (
                  <>
                    <Flex className="mt-8 mb-4 gap-2" flexDirection="column">
                      <Typography color="text.primary" variant="h5">
                        Run overview
                      </Typography>
                    </Flex>
                    <Flex flexDirection="row">
                      <div className="basis-11/12">
                        <Typography color="text.secondary" variant="body2">
                          Details for all your current and past runs.
                        </Typography>
                      </div>
                      <div className="basis-1/12">
                        <Button
                          color="secondary"
                          onClick={() => refetchExecutions()}
                          variant="outlined"
                        >
                          Refresh
                        </Button>
                      </div>
                    </Flex>
                    <DataGrid
                      columns={columns}
                      getRowId={(row) => row.executionId}
                      rows={executions}
                    />
                  </>
                ) : (
                  <NoDataFound
                    heading="Run reports"
                    subHeading="The workflow has no Runs yet. Edit your workflow, check all the nodes as “done” and execute your first Run!"
                  />
                )}
              </>
            )}
          </div>
        </ContentContainer>
      ) : null}
      {fetchingWorkflowMetadata ? (
        <DataLoader loadingText="Fetching workflow details" />
      ) : null}
    </ScrollableContainer>
  );
}
