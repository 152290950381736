import { clsx } from 'clsx';
import React, { Fragment } from 'react';
import { v4 as uuidv4 } from 'uuid';

interface Props {
  cols: number;
  count: number;
  className?: string;
}

export default function TableLoadingSkeleton({
  cols,
  className,
  count = 1,
}: Props) {
  const classes = clsx('w-full !mt-0', className);

  return (
    <div className={classes}>
      {new Array(count).fill(null).map(() => (
        <div
          className="animate-pulse flex items-center space-x-4 px-1.5 py-2.5 hover:bg-gray-50"
          key={uuidv4()}
        >
          {new Array(cols).fill(null).map(() => (
            <Fragment key={uuidv4()}>
              <div className="h-6 bg-gray-200 flex-1" />
            </Fragment>
          ))}
          <div className="rounded-full bg-gray-200 h-8 w-8" />
        </div>
      ))}
    </div>
  );
}
