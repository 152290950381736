import MuiAlert from '@mui/material/Alert';
import React, { useEffect } from 'react';

export enum AlertVariant {
  SUCCESS = 'success',
  INFO = 'info',
  ERROR = 'error',
  WARNING = 'warning',
}

export interface Props {
  className?: string;
  open?: boolean;
  dismissible?: boolean;
  onClose?: () => void;
  timeoutInMs?: number;
  variant?: AlertVariant;
  action?: React.ReactNode;
  children?: string | React.ReactNode;
}

export default function Alert({
  className,
  variant,
  children,
  open,
  timeoutInMs = 5 * 1000,
  dismissible = true,
  action,
  onClose,
}: Props) {
  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    if (open) {
      timeoutId = setTimeout(() => onClose?.(), timeoutInMs);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [onClose, open, timeoutInMs]);

  return open ? (
    <MuiAlert
      action={action}
      className={className}
      onClose={() => dismissible && onClose?.()}
      severity={variant}
      variant="filled"
    >
      {children}
    </MuiAlert>
  ) : null;
}
