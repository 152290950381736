"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.listWorkflowsSchema = void 0;
var types_shared_1 = require("types-shared");
var workflow_schema_1 = require("./workflow.schema");
exports.listWorkflowsSchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.z.object({}).nullable(),
        query: types_shared_1.z.object({}).nullable(),
        body: types_shared_1.z.object({}).nullable(),
    }),
    response: types_shared_1.z.object({
        userWorkflows: types_shared_1.z.record(types_shared_1.z.string(), workflow_schema_1.WorkflowMetadata),
    }),
};
