import { Toolbar } from 'dashboard-shared';
import { useNavigate, useParams } from 'react-router-dom';
import { Chip, Flex, ScrollableContainer, Typography } from 'ui-kit';
import type { ExecutionBase, ScrapeMap } from 'types-shared';
import type { GridColDef } from '@mui/x-data-grid';
import { DataGrid } from '@mui/x-data-grid';
import { getStatusColor } from './WorkflowDetail';
import { useFetchExecutionDetail } from '../hooks';

interface ExecutionDetailProps {
  metadata: ExecutionBase;
  variables: ScrapeMap | null;
  imageArray: Blob[];
}

const columns: GridColDef<ExecutionDetailProps>[] = [
  {
    field: 'recordId',
    headerName: 'Record ID',
    headerClassName: '!pl-0',
    valueGetter: (params) => params.row.metadata.recordId,
  },
  {
    field: 'executionId',
    headerName: 'Run ID',
    flex: 2,
    valueGetter: (params) => params.row.metadata.executionId,
  },
  {
    field: 'startedAt',
    headerName: 'Date/Time',
    flex: 2,
    valueGetter: (params) => params.row.metadata.lastUpdatedAt,
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    renderCell: (params) => (
      <Chip
        color={getStatusColor(params.row.metadata.status)}
        label={params.row.metadata.status.toUpperCase()}
        variant="filled"
      />
    ),
  },
];

export function ExecutionDetail() {
  const { executionId } = useParams();
  if (!executionId) {
    throw Error('executionId not found!');
  }

  const navigate = useNavigate();
  const { data: executionDetail } = useFetchExecutionDetail(executionId);

  return (
    <ScrollableContainer>
      <Toolbar
        onBack={() => {
          navigate(-1);
        }}
        pageInfo={`Execution ID: ${executionId}`}
      />
      <div className="pt-12 pb-8 px-8 overflow-y-auto relative flex-grow h-[calc(100vh_-_92px)]">
        <Typography className="font-medium" variant="h5">
          Executed record details
        </Typography>
        {executionDetail ? (
          <>
            <DataGrid<ExecutionDetailProps>
              className="max-h-40 mt-10"
              columns={columns}
              getRowId={(row) => row.metadata.executionId}
              hideFooterPagination
              rows={[executionDetail]}
            />
            <Flex className="gap-7 mt-14">
              <Flex className="space-y-8 flex-1" flexDirection="column">
                <span className="font-semibold text-base">Outputs</span>
                <div className="rounded bg-color-light-blue p-9 text-info-dark min-h-[20rem]">
                  {JSON.stringify(executionDetail.variables, null, 2) ||
                    'No scraped data'}
                </div>
              </Flex>
              <Flex className="space-y-8 flex-1" flexDirection="column">
                <span className="font-semibold text-base">
                  Final Screenshot
                </span>
                <img
                  alt="Execution detail"
                  className="block object-contain rounded"
                  src={
                    executionDetail.imageArray.length
                      ? URL.createObjectURL(executionDetail.imageArray[0])
                      : '/screenshot.png'
                  }
                />
              </Flex>
            </Flex>
          </>
        ) : null}
      </div>
    </ScrollableContainer>
  );
}
