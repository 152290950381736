import SvgIcon from '@mui/material/SvgIcon';
import type { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React from 'react';

export default function PersonIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      height="23"
      width="20"
      {...props}
      fill="none"
      viewBox="0 0 20 23"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 21.333C18 18.3875 14.4183 15.9997 10 15.9997C5.58172 15.9997 2 18.3875 2 21.333M10 11.9997C7.05448 11.9997 4.66667 9.61186 4.66667 6.66634C4.66667 3.72082 7.05448 1.33301 10 1.33301C12.9455 1.33301 15.3333 3.72082 15.3333 6.66634C15.3333 9.61186 12.9455 11.9997 10 11.9997Z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
      />
    </SvgIcon>
  );
}
