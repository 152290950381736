import SolaModal from '../../shared/SolaModal';
import type { EventMap } from 'ui-kit';
import { Button, Select, Input, createEventBus } from 'ui-kit';
import { useEffect, useState } from 'react';
import { EditorStore } from '../store/EditorState';
import { useSendSlackMessage } from '../hooks';
import { CONTACT_SLACK_CHANNEL_ID } from '../utils/constants';

const problemTypes: string[] = ['General Issue', 'Incorrect Action'];

interface ContactModalEvents extends EventMap {
  open: (problemType?: string) => void;
}

export const contactModalEventChannel = createEventBus<ContactModalEvents>();

interface FormValues {
  problemType: string;
  file: File | null;
  description: string;
}

const defaultFormValue: FormValues = {
  problemType: 'General Issue',
  file: null,
  description: '',
};

function ContactModal() {
  const { workflowId } = EditorStore();
  const [open, setOpen] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<FormValues>(defaultFormValue);
  const { mutateAsync: sendMessage, status } = useSendSlackMessage();

  const setFormValue = (key: string, value: string) => {
    setFormValues((form) => ({
      ...form,
      [key]: value,
    }));
  };

  const onSubmit = async () => {
    if (workflowId) {
      await sendMessage({
        workflowId,
        channelId: CONTACT_SLACK_CHANNEL_ID,
        text: `Type: ${formValues.problemType} | Description: ${formValues.description}`,
      });
      setFormValues(defaultFormValue);
      setOpen(false);
    }
  };

  useEffect(() => {
    const unsubscribe = contactModalEventChannel.on(
      'open',
      (problemType = 'General Issue') => {
        setOpen(true);
        setFormValue('problemType', problemType);
      },
    );

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <SolaModal
      className="flex item-center justify-items-center max-w-5xl"
      onClose={() => {
        setOpen(false);
      }}
      open={open}
    >
      <div className="text-sm flex flex-row gap-20">
        <div className="flex-1 flex flex-col">
          <p className="font-medium mb-14 text-black">Contact support</p>
          <p className="font-medium mb-2.5 text-lg text-black">
            We can help you
          </p>
          <p className="text-gray-600">
            Are you experiencing issues with actions or do you need some
            specific feature in your workflows? Contact us! We will be in touch
            within 24 hours.
          </p>
        </div>
        <div className="flex-1 max-w-md">
          <div className="mt-14 flex flex-col">
            <Select
              classes={{ select: '!py-4' }}
              disabled
              getLabel={(opt: string) => opt}
              getValue={(opt: string) => opt}
              label="Select the workflow"
              labelId="template-select-variable-source"
              options={workflowId ? [workflowId] : []}
              value={workflowId}
            />
          </div>

          <div className="flex flex-col mt-8">
            <Select
              classes={{ select: '!py-4' }}
              getLabel={(opt: string) => opt}
              getValue={(opt: string) => opt}
              label="Problem type"
              labelId="template-select-variable-source"
              onChange={(e) => {
                setFormValue('problemType', e.target.value);
              }}
              options={problemTypes}
              value={formValues.problemType}
            />
          </div>

          <div className="relative flex flex-col mt-11">
            <p className="mb-5 text-black">
              Upload a screenshot/image of your issue
            </p>
            <Button className="w-40 h-9" color="secondary" variant="outlined">
              UPLOAD IMAGE
            </Button>
          </div>

          <Input
            classes={{ wrapper: 'flex flex-col mt-10' }}
            floatingLabel
            label="Description"
            multiline
            onChange={(description: string) => {
              setFormValue('description', description);
            }}
            placeholder="Write a detailed description of the issue"
            rows={5}
            value={formValues.description}
          />

          <div className="flex flex-row gap-7 mt-10">
            <Button
              className="w-40 h-9"
              color="secondary"
              loading={status === 'pending'}
              onClick={onSubmit}
              variant="contained"
            >
              SUBMIT
            </Button>
            <Button
              className="h-9"
              color="secondary"
              onClick={() => {
                setOpen(false);
              }}
              variant="text"
            >
              CANCEL
            </Button>
          </div>
        </div>
      </div>
    </SolaModal>
  );
}

export default ContactModal;
