"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateExecutionSchema = void 0;
var types_shared_1 = require("types-shared");
// export const updateExecutionCommand = z.record(
//   uuidSchema,
//   z.object({
//     status: ExecutionStatus,
//     metadata: z.record(z.string(), z.unknown()).optional(),
//   }),
// );
exports.updateExecutionSchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.z.object({
            executionId: types_shared_1.uuidSchema,
        }),
        query: types_shared_1.z.object({}).nullable(),
        body: types_shared_1.z.object({
            status: types_shared_1.ExecutionStatus,
            statusDescr: types_shared_1.z.string().optional(),
        }),
    }),
    response: types_shared_1.z.object({
        status: types_shared_1.ExecutionStatus,
        lastUpdatedAt: types_shared_1.z.string(),
    }),
};
