const units = ['bytes', 'KB', 'MB'];

export const formatFileSize = (val: number): string => {
  let i = 0,
    size = val;

  while (size >= 1000 && ++i) {
    size /= 1000;
  }

  return `${size.toFixed(size < 10 && i > 0 ? 1 : 0)} ${units[i]}`;
};
