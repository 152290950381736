import type {
  AddWorkflowRequest,
  AddWorkflowResponse,
  GetWorkflowMetadataResponse,
  GetWorkflowResponse,
  UpdateWorkflowRequest,
  UpdateWorkflowResponse,
  WorkflowMetadataType,
  ZodFetcher,
} from 'api-types-shared';
import {
  addWorkflowSchema,
  getWorkflowMetadataSchema,
  getWorkflowSchema,
  listWorkflowsSchema,
  updateWorkflowSchema,
  WorkflowStatusEnum,
  zodAxios,
} from 'api-types-shared';
import type { KyInstance, Options } from 'ky';
import { handleException } from 'sentry-browser-shared';
import type { CommitWorkflowState, WorkflowNode } from 'types-shared';
import {
  NodeTypesEnum,
  TargetMap,
  VariableMap,
  WorkflowData,
} from 'types-shared';
import type { NodeEnv } from 'ui-kit';
import { apiEndpoints } from 'ui-kit';

import { getBlobFromS3 } from '../utils/blob';
import { getImageDataFromNodes } from '../utils/images';
import { createZodKyFetcher } from '../fetcher';

export class WorkflowSDK {
  readonly endpoint: string;
  private _kyFetcher: ZodFetcher<KyInstance>;

  constructor(env: NodeEnv, kyOpts?: Options) {
    this.endpoint = apiEndpoints[env].workflowApiV1;
    this._kyFetcher = createZodKyFetcher(kyOpts);
  }

  fetchWorkflowsList = async (): Promise<WorkflowMetadataType[]> => {
    const { userWorkflows } = await this._kyFetcher(
      listWorkflowsSchema.response,
      `${this.endpoint}/list`,
      {
        method: 'get',
      },
    ).catch((err) => {
      handleException(err, {
        userMessage: { title: 'Failed to fetch workflows' },
      });
      return { userWorkflows: {} };
    });

    return Object.values(userWorkflows);
  };

  fetchWorkflowMetadata = async (
    workflowId: string,
  ): Promise<GetWorkflowMetadataResponse | null> => {
    return this._kyFetcher(
      getWorkflowMetadataSchema.response,
      `${this.endpoint}/${workflowId}/metadata`,
      { method: 'get' },
    ).catch((err) => {
      handleException(err, {
        userMessage: { title: 'Failed to fetch workflow' },
      });
      return null;
    });
  };

  fetchWorkflowData = (_workflowId: string, _type: 'json' | 'text') => {
    throw new Error('fetchWorkflowData not implemented');
  };

  createWorkflow = async (
    createWorkflowData: AddWorkflowRequest['body'],
    env?: NodeEnv,
  ): Promise<AddWorkflowResponse> => {
    const parsedRequest =
      addWorkflowSchema.request.shape.body.parse(createWorkflowData);
    if (env === 'development') {
      parsedRequest.status = WorkflowStatusEnum.ProcessedImport;
    }

    const response = await this._kyFetcher(
      addWorkflowSchema.response,
      `${this.endpoint}/new`,
      {
        method: 'put',
        body: JSON.stringify(parsedRequest),
      },
    ).catch((error) => {
      handleException(error, {
        userMessage: { title: 'Failed to create workflow' },
        extra: { data: parsedRequest },
      });
    });
    return addWorkflowSchema.response.parse(response);
  };

  deleteWorkflow = (_id: string) => {
    throw new Error('deleteWorkflow not implemented');
  };

  private _getWorkflowUrls = async (
    workflowId: string,
    stateTypes: string[],
    update = false,
  ): Promise<GetWorkflowResponse> => {
    // TODO(michael): Switch to kyFetcher with auth
    return zodAxios(
      getWorkflowSchema.response,
      `${this.endpoint}/${workflowId}`,
      {
        method: update ? 'post' : 'get',
        params: stateTypes.reduce((acc: Record<string, boolean>, stateType) => {
          acc[stateType] = true;
          return acc;
        }, {}),
      },
    );
  };

  getWorkflowUrls = async (
    workflowId: string,
    stateTypes: string[],
  ): Promise<GetWorkflowResponse> => {
    const res = this._getWorkflowUrls(workflowId, stateTypes).catch((err) => {
      handleException(err, {
        userMessage: { title: 'Failed to fetch workflow' },
      });
      return {
        stateUrl: undefined,
        targetUrl: undefined,
        variableUrl: undefined,
      };
    });

    return res;
  };

  updateWorkflowUrls = async (
    workflowId: string,
    stateTypes: string[],
  ): Promise<UpdateWorkflowResponse> => {
    const res = this._getWorkflowUrls(workflowId, stateTypes, true).catch(
      (err) => {
        handleException(err, {
          userMessage: { title: 'Failed to fetch workflow update URLs' },
        });
        return {
          stateUrl: undefined,
          targetUrl: undefined,
          variableUrl: undefined,
        };
      },
    );

    return res;
  };

  getWorkflowStateData = async (
    workflowId: string,
  ): Promise<WorkflowData | null> => {
    const { stateUrl } = await this.getWorkflowUrls(workflowId, ['stateReq']);
    if (!stateUrl) {
      handleException(new Error('State URL is not available'), {
        userMessage: { title: 'Failed to fetch workflow' },
      });
      return null;
    }
    return zodAxios(WorkflowData, stateUrl, {
      method: 'get',
    }).catch((error) => {
      handleException(error, {
        userMessage: { title: 'Failed to fetch workflow' },
        source: 's3',
      });
      return null;
    });
  };

  getWorkflowTargetData = async (workflowId: string): Promise<TargetMap> => {
    const { targetUrl } = await this.getWorkflowUrls(workflowId, ['targetReq']);
    if (!targetUrl) {
      handleException(new Error('Target URL is not available'), {
        userMessage: { title: 'Failed to fetch workflow' },
      });
      return {};
    }
    return zodAxios(TargetMap, targetUrl, {
      method: 'get',
    }).catch((error) => {
      handleException(error, {
        userMessage: { title: 'Failed to fetch workflow' },
        source: 's3',
      });
      return {};
    });
  };

  getWorkflowVariableData = async (
    workflowId: string,
  ): Promise<VariableMap> => {
    const { variableUrl } = await this.getWorkflowUrls(workflowId, [
      'variableReq',
    ]);
    if (!variableUrl) {
      handleException(new Error('Variable URL is not available'), {
        userMessage: { title: 'Failed to fetch workflow' },
      });
      return {};
    }
    return zodAxios(VariableMap, variableUrl, {
      method: 'get',
    }).catch((error) => {
      handleException(error, {
        userMessage: { title: 'Failed to fetch workflow' },
        source: 's3',
      });
      return {};
    });
  };

  updateWorkflowName = async (
    workflowId: string,
    data: Pick<WorkflowMetadataType, 'workflowName'>,
  ) => {
    return this.updateWorkflowMetadata({
      body: data,
      params: { workflowId },
      query: {
        stateReq: false,
        targetReq: false,
        variableReq: false,
      },
    });
  };

  updateWorkflowStatus = async (
    workflowId: string,
    data: Pick<WorkflowMetadataType, 'status'>,
  ) => {
    return this.updateWorkflowMetadata({
      body: data,
      params: { workflowId },
      query: {
        stateReq: false,
        targetReq: false,
        variableReq: false,
      },
    });
  };

  updateWorkflowMetadata = async (req: UpdateWorkflowRequest) => {
    return this._kyFetcher(
      updateWorkflowSchema.response,
      `${this.endpoint}/${req.params.workflowId}`,
      {
        method: 'post',
        body: JSON.stringify(req.body),
      },
    ).catch((error) => {
      handleException(error, {
        userMessage: { title: 'Failed to update workflow' },
      });
    });
  };

  updateAllWorkflowData = async (
    workflowId: string,
    workflowData: CommitWorkflowState,
  ): Promise<{
    state: WorkflowData | null;
    variables: VariableMap;
    targets: TargetMap;
  } | null> => {
    const { stateUrl, variableUrl, targetUrl } = await this.updateWorkflowUrls(
      workflowId,
      ['stateReq', 'variableReq', 'targetReq'],
    );
    if (!stateUrl || !variableUrl || !targetUrl) {
      handleException(
        new Error('State, variable, or target URL is not available'),
        {
          userMessage: { title: 'Failed to update workflow' },
        },
      );
      return null;
    }

    const { state, targets, variables } = workflowData;

    const [updatedState, updatedVariables, updatedTargets] = await Promise.all([
      this.updateWorkflowStateData(workflowId, state, stateUrl),
      this.updateWorkflowVariableData(workflowId, variables, variableUrl),
      this.updateWorkflowTargetData(workflowId, targets, targetUrl),
    ]);

    return {
      state: updatedState,
      variables: updatedVariables,
      targets: updatedTargets,
    };
  };

  updateWorkflowStateData = async (
    workflowId: string,
    workflowData: WorkflowData,
    providedStateUrl?: string,
  ): Promise<WorkflowData | null> => {
    let stateUrl = providedStateUrl;
    if (!stateUrl) {
      ({ stateUrl } = await this.updateWorkflowUrls(workflowId, ['stateReq']));
      if (!stateUrl) {
        handleException(new Error('State URL is not available'), {
          userMessage: { title: 'Failed to update workflow' },
        });
        return null;
      }
    }

    return zodAxios(WorkflowData, stateUrl, {
      method: 'put',
      data: workflowData,
    }).catch((error) => {
      handleException(error, {
        userMessage: { title: 'Failed to update workflow' },
        source: 's3',
      });
      return null;
    });
  };

  updateWorkflowTargetData = async (
    workflowId: string,
    targetData: TargetMap,
    providedTargetUrl?: string,
  ): Promise<TargetMap> => {
    let targetUrl = providedTargetUrl;
    if (!targetUrl) {
      ({ targetUrl } = await this.updateWorkflowUrls(workflowId, [
        'targetReq',
      ]));
      if (!targetUrl) {
        handleException(new Error('Target URL is not available'), {
          userMessage: { title: 'Failed to update workflow' },
        });
        return {};
      }
    }

    return zodAxios(TargetMap, targetUrl, {
      method: 'put',
      data: targetData,
    }).catch((error) => {
      handleException(error, {
        userMessage: { title: 'Failed to update workflow' },
        source: 's3',
      });
      return {};
    });
  };

  updateWorkflowVariableData = async (
    workflowId: string,
    variableData: VariableMap,
    providedVariableUrl?: string,
  ): Promise<VariableMap> => {
    let variableUrl = providedVariableUrl;
    if (!variableUrl) {
      ({ variableUrl } = await this.updateWorkflowUrls(workflowId, [
        'variableReq',
      ]));
      if (!variableUrl) {
        handleException(new Error('Variable URL is not available'), {
          userMessage: { title: 'Failed to update workflow' },
        });
        return {};
      }
    }
    return zodAxios(VariableMap, variableUrl, {
      method: 'put',
      data: variableData,
    }).catch((error) => {
      handleException(error, {
        userMessage: { title: 'Failed to update workflow' },
        source: 's3',
      });
      return {};
    });
  };

  getImageData = async (
    workflowId: string,
    imageIds: string[],
    imageOriginal = false,
  ): Promise<Record<string, Blob | null>> => {
    const response = await zodAxios(
      getWorkflowSchema.response,
      `${this.endpoint}/${workflowId}`,
      {
        params: {
          imageOriginal,
          imageIds,
        },
        method: 'get',
      },
    ).catch((error) => {
      handleException(error, {
        name: 'Fetching thumbnail data failed',
      });
      return { imageUrls: null };
    });

    const { imageUrls } = response;

    if (!imageUrls) {
      throw new Error('Image URLs are not available');
    }

    const blobMap: Record<string, Blob | null> = {};
    const promises = Object.entries(imageUrls).map(
      async ([imageId, dataUrl]) => {
        const blob = await getBlobFromS3(dataUrl).catch((error) => {
          handleException(error, {
            name: 'Fetching thumbnail data failed',
            source: 's3',
          });
          return null;
        });

        if (blob) {
          blobMap[imageId] = blob;
        }
      },
    );

    try {
      await Promise.allSettled(promises);
    } catch (e) {
      handleException(e, {
        name: 'Failed resolving image blobs',
        source: 's3',
      });
    }

    return blobMap;
  };

  addImageToNodes = async (
    workflowId: string,
    nodes: WorkflowNode[],
  ): Promise<WorkflowNode[]> => {
    const imgs = getImageDataFromNodes(nodes);
    const imgData = await this.getImageData(workflowId, imgs);
    nodes.forEach((node: WorkflowNode) => {
      if (node.type === NodeTypesEnum.Image) {
        node.data.imageData.thumbnailData =
          imgData[node.data.imageData.imageId];
      }
    });
    return nodes;
  };
}
