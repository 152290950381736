import type { AxiosResponse } from 'api-types-shared';
import { axios } from 'api-types-shared';
import type { WorkflowNode } from 'types-shared/workflowTypes';
import { NodeTypesEnum, WorkflowData } from 'types-shared/workflowTypes';

export const uploadBlobToS3 = async (
  blob: Blob,
  url: string,
): Promise<AxiosResponse> => {
  return axios.put(url, blob, {
    headers: {
      'Content-Type': blob.type,
      'Content-Length': blob.size.toString(),
    },
  });
};

export const getBlobFromS3 = async (url: string): Promise<Blob> => {
  const response = await axios.get(url, {
    responseType: 'blob',
  });
  return new Blob([response.data]);
};

export const blobToDataUrl = async (blob: Blob): Promise<string> => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onload = () => {
      const dataUrl = reader.result;
      if (typeof dataUrl === 'string') {
        resolve(dataUrl);
      } else {
        reject(new Error('No dataUrl found'));
      }
    };
    reader.readAsDataURL(blob);
  });
};

export const JSONToWorkflowData = (json: string): WorkflowData => {
  // DEBUGGING FUNCTION - REMOVE BEFORE PRODUCTION
  const workflowData = JSON.parse(json) as WorkflowData;

  workflowData.nodes.forEach((node: WorkflowNode) => {
    if (node.type === NodeTypesEnum.Image) {
      if (node.data.imageData.thumbnailData) {
        node.data.imageData.thumbnailData = dataUrlToBlob(
          String(node.data.imageData.thumbnailData),
        );
      }
      if (node.data.imageData.originalData) {
        node.data.imageData.originalData = dataUrlToBlob(
          String(node.data.imageData.originalData),
        );
      }
    }
  });

  return WorkflowData.parse(workflowData);
};

function dataUrlToBlob(
  dataUrl: string,
  contentType = 'application/octet-stream',
): Blob {
  const base64 = dataUrl.split(',')[1];
  const sliceSize = 512;
  const byteCharacters = atob(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
}
