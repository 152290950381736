import type { WorkflowNodeProps } from 'types-shared';
import { useStore } from 'types-shared/reactflow';
import { minZoomLevelForFullContent } from '../../utils/constants';
import NodeElement from './index';
import { PlaceholderNodeCore, useEditingNodeId } from 'editor-shared';

const zoomSelector = (s: { transform: number[] }) =>
  s.transform[2] >= minZoomLevelForFullContent;

export default function PlaceholderNode(workflowData: WorkflowNodeProps) {
  const { setEditingNodeId } = useEditingNodeId();
  const showFullContent = useStore(zoomSelector);

  return (
    <NodeElement workflowData={workflowData}>
      <PlaceholderNodeCore
        onClick={() => {
          setEditingNodeId(workflowData.id);
        }}
        showFullContent={showFullContent}
      />
    </NodeElement>
  );
}
