import ErrorIcon from '@mui/icons-material/Error';
import { CheckCircleIcon } from 'ui-kit';
import { clsx } from 'clsx';
import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import type {
  WorkflowEdge,
  WorkflowNode,
  WorkflowNodeProps,
} from 'types-shared';
import { NodeStatusEnum, NodeTypesEnum } from 'types-shared';
import { EditOutlined } from '@mui/icons-material';
import Add from '@mui/icons-material/Add';
import { v4 as uuid } from 'uuid';
import { getAllNodesAfter } from '../../utils/helper';
import { CustomHandle } from './Handles/Handle';

interface Props {
  nodeSelectionModeEnabled: boolean;
  distanceBetweenNodes: number;
  workflowData: WorkflowNodeProps;
  nodes: WorkflowNode[];
  edges: WorkflowEdge[];
  setEdges: (edges: WorkflowEdge[]) => void;
  updateNode: (node: WorkflowNode) => void;
  addNodes: (nodes: WorkflowNode[]) => void;
  handleNodeClick?: () => void;
  children: ReactNode;

  showAddButton?: boolean;
  showEditButton?: boolean;
  onClickEdit: () => void;
}

const actionIconMap: Record<string, ReactNode | undefined> = {
  [NodeStatusEnum.Checked]: <CheckCircleIcon className="!fill-none" />,
  [NodeStatusEnum.Error]: <ErrorIcon className="!text-error" />,
};

export function NodeElementCore({
  workflowData: { data, type, id },
  handleNodeClick,

  nodes,
  edges,
  setEdges,
  updateNode,
  addNodes,
  distanceBetweenNodes,
  nodeSelectionModeEnabled,
  children,

  showAddButton = false,
  showEditButton = false,
  onClickEdit,
}: Props) {
  const status = data.nodeStatus;
  const showIcon = useMemo(
    () => [NodeStatusEnum.Error, NodeStatusEnum.Checked].includes(status),
    [status],
  );
  const actionIcon = actionIconMap[status];

  const insertNode = (sourceId: string) => {
    const sourceNode = nodes.find((node) => node.id === sourceId);
    const targetNodeId = edges.find((e) => e.source === sourceId)?.target;

    if (!sourceNode) {
      throw Error('sourceNode not found!');
    }

    const newNodeId = uuid();
    const newNode: WorkflowNode = {
      id: newNodeId,
      position: {
        ...sourceNode.position,
        x: sourceNode.position.x + distanceBetweenNodes,
      },
      type: NodeTypesEnum.New,
      data: {
        nodeStatus: NodeStatusEnum.NotViewed,
      },
    };
    addNodes([newNode]);

    if (targetNodeId) {
      const nodeIds = getAllNodesAfter(sourceNode, nodes, edges);

      nodeIds.forEach((_id) => {
        const node = nodes.find((n) => n.id === _id);
        if (node && _id !== sourceId) {
          updateNode({
            ...node,
            position: {
              ...node.position,
              x: node.position.x + distanceBetweenNodes,
            },
          });
        }
      });
    }

    let newEdges = [
      ...edges,
      {
        id: uuid(),
        source: sourceId,
        target: newNodeId,
      },
    ];

    if (targetNodeId) {
      newEdges = newEdges.filter(
        (edge) => !(edge.source === sourceId && edge.target === targetNodeId),
      );
      newEdges.push({
        id: uuid(),
        source: newNodeId,
        target: targetNodeId,
      });
    }
    setEdges(newEdges);
  };

  return (
    <div className="group">
      <div
        className={clsx('!p-2 relative', {
          '!bg-gradient-to-br from-info-extralight to-purple-light !shadow-primary-purple drop-shadow-sm hover:from-info-semi-light hover:to-purple-light hover:drop-shadow-xl hover:shadow-inherit !rounded-3xl':
            status === NodeStatusEnum.Autolinked,
        })}
      >
        <div
          className={clsx(
            'relative bg-white flex flex-col space-y-3 overflow-hidden rounded-2xl p-3 w-60 h-50 ring-8 ring-transparent border border-indigo-light hover:border-info',
            {
              '!border-info !ring-info-extralight hover:!ring-info-semi-light':
                type !== 'datasource' &&
                (status === NodeStatusEnum.NotViewed ||
                  status === NodeStatusEnum.Viewed),
              '!border-error !ring-error-extralight hover:!ring-error-light':
                status === NodeStatusEnum.Error,
              '!border-checked-green !ring-primary-light-green hover:!ring-checked-green-light':
                status === NodeStatusEnum.Checked,
              '!border-primary-purple': status === NodeStatusEnum.Autolinked,
            },
          )}
          onClick={handleNodeClick}
          role="presentation"
        >
          {children}
          {showIcon ? (
            <div
              className={clsx(
                'absolute bottom-3 right-3 flex justify-between items-center bg-white',
                { 'text-error left-3': status === NodeStatusEnum.Error },
              )}
            >
              {status === NodeStatusEnum.Error && <span>Unavailable</span>}
              {actionIcon ? actionIcon : null}
            </div>
          ) : null}
        </div>
        <CustomHandle type="target" />
        <CustomHandle type="source" />
      </div>
      <div className="mt-4 w-full flex justify-center">
        {!nodeSelectionModeEnabled ? (
          <div className="justify-center gap-3 hidden absolute group-hover:flex">
            {showEditButton ? (
              <button
                className="flex justify-center items-center color-primary-blue text-primary-blue py-1 px-3 border-2 border-primary-blue rounded hover:bg-primary-blue hover:text-white !cursor-pointer"
                onClick={() => {
                  onClickEdit();
                }}
                type="button"
              >
                <EditOutlined />
              </button>
            ) : null}
            {showAddButton ? (
              <button
                className="flex justify-center items-center py-1 px-3 text-primary-blue border-2 border-primary-blue rounded  hover:bg-primary-blue hover:text-white !cursor-pointer"
                onClick={() => {
                  insertNode(id);
                }}
                type="button"
              >
                <Add />
              </button>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
}
