import {
  Button,
  Chip,
  Typography,
  Flex,
  ContentContainer,
  ScrollableContainer,
} from 'ui-kit';
import { DataGrid } from '@mui/x-data-grid';
import { Toolbar } from 'dashboard-shared';
import { useState } from 'react';
import type { DatasourceTable } from 'types-shared';

interface Props {
  tableData: DatasourceTable;
  fileName?: string;
  onBack: () => void;
  onSubmit?: () => Promise<void>;
  isSubmitDisabled?: boolean;
  previewMode?: boolean;
}

export default function DatasourcePreviewTable({
  tableData,
  fileName,
  onBack,
  onSubmit,
  isSubmitDisabled,
  previewMode = false,
}: Props) {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleOnSubmit = async () => {
    if (!onSubmit) {
      return;
    }
    setIsSubmitting(true);
    await onSubmit();
    setIsSubmitting(false);
  };

  return (
    <ScrollableContainer>
      <Toolbar
        className="flex items-center justify-between"
        onBack={onBack}
        pageInfo="Database preview"
      >
        <Flex className="gap-4">
          {previewMode ? (
            <>
              <Button
                className="!min-w-36"
                color="secondary"
                onClick={onBack}
                variant="outlined"
              >
                Back
              </Button>
              <Button
                color="secondary"
                disabled={
                  isSubmitDisabled || isSubmitting || !tableData.rowData.length
                }
                loading={isSubmitting}
                onClick={handleOnSubmit}
                variant="contained"
              >
                Finish and connect
              </Button>
            </>
          ) : null}
        </Flex>
      </Toolbar>
      <ContentContainer withToolbar>
        <Typography variant="h4">{fileName}</Typography>
        <div className="mt-2">
          <span className="font-normal text-sm">
            This is a preview of your database. Please ensure that your data is
            formatted such that each row represents a new run for your
            automation.
          </span>
        </div>
        {fileName ? (
          <div className="mt-6 p-4 rounded-lg bg-color-light-blue">
            <Chip
              className="!h-6"
              color="default"
              label={fileName}
              variant="filled"
            />
          </div>
        ) : null}
        <div className="mt-11">
          {tableData.rowData.length ? (
            <DataGrid
              columns={tableData.columnDefinitions.map((item) => {
                return { flex: 1, minWidth: 150, ...item };
              })}
              rows={tableData.rowData.map((item, i) => {
                return { id: i, ...item };
              })}
            />
          ) : (
            <div className="flex flex-col items-center justify-between mt-11">
              <p className="font-medium text-lg pb-2">No data found!</p>
              {previewMode ? (
                <>
                  <p className="font-normal text-sm pb-6">
                    We couldn't find any data in the uploaded file. Double-check
                    your CSV and try uploading again.
                  </p>
                  <Button
                    color="secondary"
                    onClick={onBack}
                    variant="contained"
                  >
                    Reupload CSV
                  </Button>
                </>
              ) : null}
            </div>
          )}
        </div>
      </ContentContainer>
    </ScrollableContainer>
  );
}
