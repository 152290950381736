import { MoreVert } from 'assets-shared';
import { useState } from 'react';
import type { WorkflowAction, WorkflowActionsOptions } from 'types-shared';
import {
  IconButton,
  Menu,
  MenuItem,
  PersonIcon,
  HideEyeIcon,
  QuestionAnswerIcon,
  DesktopIcon,
  EyeIcon,
} from 'ui-kit';

import { contactModalEventChannel } from '../../ContactModal';

interface Props {
  action: WorkflowAction;
  onUpdateActionOptions: (
    action: WorkflowAction,
    options: WorkflowActionsOptions,
  ) => void;
}

function ActionMenu({ action, onUpdateActionOptions }: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { options: { hidden, hitl } = {} } = action;
  const HideIcon = hidden ? EyeIcon : HideEyeIcon;
  const SkipIcon = hitl ? DesktopIcon : PersonIcon;

  const onClose = () => {
    setAnchorEl(null);
  };

  const toggleSkipAction = () => {
    onUpdateActionOptions(action, {
      ...action.options,
      hitl: !hitl,
    });
    onClose();
  };

  const toggleHideAction = () => {
    onUpdateActionOptions(action, {
      ...action.options,
      hidden: !hidden,
    });
    onClose();
  };

  return (
    <>
      <IconButton
        className="!p-0"
        onClick={(e) => {
          setAnchorEl(e.target as HTMLButtonElement);
        }}
      >
        <MoreVert className="!w-5 !h-5 text-black" />
      </IconButton>
      <Menu
        BackdropProps={{
          style: {
            backgroundColor: 'transparent',
          },
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        onClose={onClose}
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem className="min-w-64 h-8 !px-4" onClick={toggleSkipAction}>
          <span className="font-normal mr-4">
            {hitl ? 'Execute action automatically' : 'Handle action manually'}
          </span>
          <SkipIcon className="!w-5 !h-5 !ml-auto !text-info-dark !fill-none" />
        </MenuItem>
        <MenuItem className="min-w-64 h-8 !px-4" onClick={toggleHideAction}>
          <span className="font-normal">{hidden ? 'Show' : 'Hide'} Action</span>
          <HideIcon className="!w-5 !h-5 !ml-auto !text-info-dark !fill-none" />
        </MenuItem>
        <MenuItem
          className="min-w-64 h-8 !px-4"
          onClick={() => {
            onClose();
            contactModalEventChannel.emit('open', 'Incorrect Action');
          }}
        >
          <span className="font-normal">Report Inconsistency</span>
          <QuestionAnswerIcon className="!w-5 !h-5 !ml-auto !text-info-dark !fill-none" />
        </MenuItem>
      </Menu>
    </>
  );
}

export default ActionMenu;
