import TableRow from '@mui/material/TableRow';
import { clsx } from 'clsx';
import React from 'react';

import type { TableColumn } from '../index';
import TableCellRenderer from '../TableCellRenderer';

interface Props<T> {
  row: T & Record<string, string>;
  columns: TableColumn<T>[];
  showVerticalDivider: boolean;
  onRowClick?: (row: T) => void;
}

export default function TableRowRenderer<T>({
  row,
  columns,
  showVerticalDivider,
  onRowClick,
}: Props<T>) {
  const tableRowClass: string = clsx(
    'last:border-b-2 border-transparent hover:bg-gray-100',
    {
      'group hover:!cursor-pointer': Boolean(onRowClick),
    },
  );

  return (
    <TableRow
      className={tableRowClass}
      onClick={() => {
        if (onRowClick) onRowClick(row);
      }}
    >
      {columns.map(
        (column: TableColumn<T>) =>
          !column.isHidden && (
            <TableCellRenderer<T>
              cellKey={column.id}
              column={column}
              columnValue={row[column.id]}
              key={column.id}
              onClick={() => {
                if (column.onClick) column.onClick(row);
              }}
              showVerticalDivider={showVerticalDivider}
              values={row}
            />
          ),
      )}
    </TableRow>
  );
}
