"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getWorkflowMetadataSchema = void 0;
var types_shared_1 = require("types-shared");
var workflow_schema_1 = require("./workflow.schema");
exports.getWorkflowMetadataSchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.z.object({
            workflowId: types_shared_1.z.string(),
        }),
    }),
    response: workflow_schema_1.WorkflowMetadata,
};
