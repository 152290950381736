import { FilePlaceholderIcon } from 'assets-shared';
import React from 'react';
import { Typography, Flex } from 'ui-kit';

interface Props {
  heading: string;
  subHeading: string;
}
export function NoDataFound({ heading, subHeading }: Props) {
  return (
    <Flex className="px-11 py-8 bg-color-light-blue rounded-lg gap-2">
      <div className="w-1/2">
        <Typography
          className="font-medium text-lg"
          color="text.info"
          variant="h6"
        >
          {heading}
        </Typography>
        <Typography
          className="font-medium mt-8"
          color="text.tertiary"
          variant="body1"
        >
          {subHeading}
        </Typography>
      </div>

      <Flex alignItems="center" className="w-1/2 " justifyContent="center">
        <FilePlaceholderIcon className="!text-info" />
      </Flex>
    </Flex>
  );
}
