import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

interface Props {
  className?: string;
  size?: number;
  progress?: number;
}

export default function Spinner({
  className,
  progress = -1,
  size = 14,
}: Props) {
  const hasProgress = progress !== -1;

  return (
    <div className="relative inline-flex h-fit">
      <CircularProgress
        className={className}
        color="inherit"
        size={size}
        value={progress}
        variant={hasProgress ? 'determinate' : 'indeterminate'}
      />
      {hasProgress ? (
        <span
          className="absolute flex justify-center items-center top-0 left-0 right-0 bottom-0 text-[#00000099]"
          style={{ fontSize: `${Math.max(10, size / 4)}px` }}
        >
          {`${Math.round(progress)}%`}
        </span>
      ) : null}
    </div>
  );
}
