"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommitWorkflowState = void 0;
var zod_1 = require("zod");
var datasourceTypes_1 = require("../datasourceTypes");
var variable_schema_1 = require("./variable.schema");
var workflow_schema_1 = require("./workflow.schema");
var variable_helper_1 = require("./variable.helper");
exports.CommitWorkflowState = workflow_schema_1.WorkflowData.pick({
    nodes: true,
    edges: true,
})
    .merge(variable_schema_1.VariableStateData)
    .merge(workflow_schema_1.TargetStateData)
    .merge(datasourceTypes_1.DatasourceStateData.pick({ datasourceMetadata: true }))
    .transform(function (data, ctx) {
    var datasourceNodeIds = data.nodes
        .filter(function (node) { return node.type === workflow_schema_1.NodeTypesEnum.Datasource; })
        .map(function (node) { return node.id; });
    if (!datasourceNodeIds.length) {
        ctx.addIssue({
            code: zod_1.z.ZodIssueCode.custom,
            message: 'No datasource node',
        });
        return zod_1.z.NEVER;
    }
    data.nodes = data.nodes.filter(function (node) { return workflow_schema_1.WorkflowImageNode.safeParse(node).success; });
    data.edges = data.edges.filter(function (edge) {
        return !(datasourceNodeIds.includes(edge.target) ||
            datasourceNodeIds.includes(edge.source));
    });
    return data;
})
    .refine(function (data) { return data.nodes.length > 0; }, {
    message: 'No image node found',
})
    .refine(function (data) {
    return data.nodes.every(function (node) { return node.data.nodeStatus === workflow_schema_1.NodeStatusEnum.Checked; });
}, {
    message: 'Not all nodes are checked.',
})
    .refine(function (data) {
    var targets = data.targets, nodes = data.nodes;
    var imageNodes = nodes.map(function (node) { return workflow_schema_1.WorkflowImageNode.parse(node); });
    var actionTargets = imageNodes.flatMap(function (node) {
        return Object.values(node.data.actionData)
            .map(function (action) { return action.targetId; })
            .filter(Boolean);
    });
    var targetIds = Object.keys(targets);
    return actionTargets.every(function (targetId) { return !targetId || targetIds.includes(targetId); });
}, {
    message: 'Missing targets in target data',
})
    .superRefine(function (data, ctx) {
    var variables = data.variables, nodes = data.nodes, datasourceMetadata = data.datasourceMetadata;
    var imageNodes = nodes.map(function (node) { return workflow_schema_1.WorkflowImageNode.parse(node); });
    var variableIds = Object.keys(variables);
    var actionVariables = imageNodes.flatMap(function (node) {
        return Object.values(node.data.actionData)
            .flatMap(function (action) {
            return action.variableId ? (0, variable_helper_1.parseVariables)(action.variableId, variables) : [];
        })
            .filter(Boolean);
    });
    var invalidVariables = actionVariables.filter(function (variableId) { return !variableIds.includes(variableId); });
    if (invalidVariables.length > 0) {
        ctx.addIssue({
            code: zod_1.z.ZodIssueCode.custom,
            message: 'Missing variables in variable data',
            path: ['variables'],
        });
        return zod_1.z.NEVER;
    }
    var datasources = (0, variable_helper_1.getDatasources)(variableIds, variables);
    var datasourceCount = datasources.length;
    if (datasourceCount > 1 ||
        (datasourceCount === 1 &&
            datasources[0] !== (datasourceMetadata === null || datasourceMetadata === void 0 ? void 0 : datasourceMetadata.datasourceId))) {
        ctx.addIssue({
            code: zod_1.z.ZodIssueCode.custom,
            message: datasourceCount > 1
                ? 'Multiple datasources'
                : 'Datasource does not match',
            path: ['datasources'],
        });
        return zod_1.z.NEVER;
    }
})
    .transform(function (data) {
    data.nodes.forEach(function (node) {
        if (node.type === workflow_schema_1.NodeTypesEnum.Image) {
            if (node.data.imageData.originalData) {
                node.data.imageData.originalData = null;
            }
            if (node.data.imageData.thumbnailData) {
                node.data.imageData.thumbnailData = null;
            }
        }
    });
    data.datasourceMetadata = null;
    return data;
})
    .transform(function (data) {
    return {
        state: {
            nodes: data.nodes,
            edges: data.edges,
        },
        variables: data.variables,
        targets: data.targets,
    };
});
