import DatasourcePreviewTable from '../DatasourcePreviewTable';
import { EditorStore } from '../../Editor/store/EditorState';
import { useNavigate } from 'react-router-dom';

export default function DatasourcePreview() {
  const { tableData, datasourceMetadata } = EditorStore();
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // navigate back
  };

  return (
    <DatasourcePreviewTable
      fileName={datasourceMetadata?.name}
      onBack={handleGoBack}
      tableData={tableData || { columnDefinitions: [], rowData: [] }}
    />
  );
}
