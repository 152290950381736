import { useGoogleLogin } from '@react-oauth/google';
import {
  Typography,
  Flex,
  ScrollableContainer,
  ContentContainer,
} from 'ui-kit';
import { useShallow } from 'zustand/react/shallow';

import NavigationPanel from '../shared/NavigationPanel/NavigationPanel';
import IntegrationTile from './components/IntegrationTile';
import ComingSoonIntegrationTile from './components/ComingSoonIntegrationTile';
import {
  EditorStore,
  type EditorStoreProps,
} from '../Editor/store/EditorState';

export default function Integrations() {
  const { addIntegration, integrations } = EditorStore(
    useShallow((state: EditorStoreProps) => ({
      integrations: state.integrations,
      addIntegration: state.addIntegration,
    })),
  );

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      addIntegration({
        provider: 'google',
        token: tokenResponse.access_token,
      });
    },
  });

  return (
    <ScrollableContainer>
      <div className="w-full flex">
        <NavigationPanel />
        <ContentContainer>
          <div className="flex justify-between w-full items-center">
            <div>
              <Typography variant="h4">Integrations</Typography>
              <div className="mt-2">
                <span className="font-normal text-sm">
                  Configure external platforms and connect them with your
                  workflows
                </span>
              </div>
            </div>
          </div>
          <div className="mt-11">
            <Flex className="gap-4 flex-wrap">
              <IntegrationTile
                isConnected={Boolean(integrations.google)}
                onClick={login}
                platform="google"
              />
              <ComingSoonIntegrationTile />
            </Flex>
          </div>
        </ContentContainer>
      </div>
    </ScrollableContainer>
  );
}
