import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import type { PopoverPosition } from '@mui/material/Popover';
import { clsx } from 'clsx';
import type { ReactNode } from 'react';
import { useState } from 'react';

interface Props {
  loading: boolean;
  children: ReactNode;
}

interface MenuItem {
  title: string;
  onClick: () => void;
}

const menuItems: MenuItem[] = [
  {
    title: 'Copy Nodes',
    onClick: () => {
      // eslint-disable-next-line no-console
      console.log('Copy Nodes');
    },
  },
  {
    title: 'Copy Edges',
    onClick: () => {
      // eslint-disable-next-line no-console
      console.log('Copy Edges');
    },
  },
  {
    title: 'Paste Nodes',
    onClick: () => {
      // eslint-disable-next-line no-console
      console.log('Copy Paste');
    },
  },
  {
    title: 'Paste Edges',
    onClick: () => {
      // eslint-disable-next-line no-console
      console.log('Copy Edges');
    },
  },
];

export default function EditorContainer({ loading, children }: Props) {
  const [menuPos, setMenuPos] = useState<PopoverPosition>();

  // const handleMenuOpen = (event: MouseEvent<HTMLDivElement>) => {
  //   event.preventDefault();
  //   setMenuPos({
  //     top: event.clientY,
  //     left: event.clientX,
  //   });
  // };

  const onClose = () => {
    setMenuPos(undefined);
  };

  return (
    <div
      className={clsx('flex flex-col flex-1 h-full md:!flex-row', {
        'justify-center items-center': loading,
      })}
      // onContextMenu={handleMenuOpen}
    >
      <Menu
        anchorPosition={menuPos}
        anchorReference="anchorPosition"
        onClose={onClose}
        open={Boolean(menuPos)}
      >
        {menuItems.map(({ title, onClick }) => (
          <MenuItem
            key={title}
            onClick={() => {
              onClick();
              onClose();
            }}
          >
            {title}
          </MenuItem>
        ))}
      </Menu>
      {children}
    </div>
  );
}
