import React from 'react';
import { Handle, Position } from 'types-shared/reactflow';

interface Props {
  type: string;
}

export function CustomHandle({ type }: Props) {
  const isSource = type === 'source';

  return (
    <Handle
      className="h-50 w-10 rounded-none"
      position={isSource ? Position.Right : Position.Left}
      type={isSource ? 'source' : 'target'}
    />
  );
}
