import { QueryClient } from '@tanstack/react-query';
import { AlertVariant, notify } from 'ui-kit';

import { DEFAULT_CACHE_TIME } from '../constants';

interface Error {
  status: number;
  message: string;
}

const onError = (error: unknown) => {
  const { status, message } = error as Error;

  if (status === 401) {
    // generateIdToken();
    return;
  }

  notify({
    message,
    variant: AlertVariant.ERROR,
    debug: true,
  });
};

const retryFn = (failureCount: number, error: unknown) => {
  if (failureCount < 2 && (error as Error).status === 401) {
    // generateIdToken();
    return true;
  }
  return false;
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: retryFn,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: DEFAULT_CACHE_TIME,
    },
    mutations: {
      retry: retryFn,
      onError,
    },
  },
});
