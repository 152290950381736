import SvgIcon from '@mui/material/SvgIcon';
import type { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React from 'react';

export default function EditIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33337 16.6668H16.6667M3.33337 16.6668V13.3335L12.3906 4.2763L12.392 4.27488C12.7211 3.94581 12.8859 3.78099 13.0759 3.71925C13.2433 3.66487 13.4236 3.66487 13.5909 3.71925C13.7808 3.78094 13.9454 3.94558 14.274 4.27419L15.7239 5.72402C16.0539 6.05403 16.219 6.21911 16.2808 6.40939C16.3352 6.57676 16.3352 6.75704 16.2808 6.92441C16.219 7.11455 16.0542 7.27938 15.7246 7.60893L15.7239 7.60963L6.66671 16.6668L3.33337 16.6668Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </SvgIcon>
  );
}
