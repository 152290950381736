import SvgIcon from '@mui/material/SvgIcon';
import type { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React from 'react';

export default function DesktopIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33366 14.1667H2.91699C2.22664 14.1667 1.66699 14.7263 1.66699 15.4167C1.66699 16.107 2.22664 16.6667 2.91699 16.6667H17.0837C17.774 16.6667 18.3337 16.107 18.3337 15.4167C18.3337 14.7263 17.774 14.1667 17.0837 14.1667H16.667M3.33366 14.1667H16.667M3.33366 14.1667V6.83352C3.33366 5.9001 3.33366 5.43304 3.51531 5.07652C3.6751 4.76291 3.92989 4.50813 4.24349 4.34834C4.60001 4.16669 5.06707 4.16669 6.00049 4.16669H14.0005C14.9339 4.16669 15.4 4.16669 15.7565 4.34834C16.0701 4.50813 16.3257 4.76291 16.4855 5.07652C16.667 5.43269 16.667 5.89918 16.667 6.83078V14.1667"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </SvgIcon>
  );
}
