import { clsx } from 'clsx';
import { useMemo } from 'react';
import type { WorkflowAction, WorkflowNode } from 'types-shared';
import { ArrowLeftIcon } from 'assets-shared';
import { IconButton } from 'ui-kit';

import { EditorStore } from '../../store/EditorState';

interface Props {
  isAddNewVariable?: boolean;
  selectedAction?: WorkflowAction;
}

function ActionHeader({ isAddNewVariable = false, selectedAction }: Props) {
  const editorData = EditorStore();
  const { selectedNode, setSelectedNode, nodes, variables } = editorData;
  const selectedVariable = selectedAction?.variableId
    ? variables[selectedAction.variableId]
    : null;

  const node: WorkflowNode | undefined = useMemo(() => {
    return nodes.find((_node) => _node.id === selectedNode);
  }, [nodes, selectedNode]);

  const onClose = () => {
    setSelectedNode(null);
  };

  if (!node) {
    return null;
  }

  return (
    <div className="flex items-center space-x-2">
      <IconButton
        className="!border !border-solid !border-info !rounded-lg !h-10 !w-10"
        onClick={onClose}
      >
        <ArrowLeftIcon className="text-info" />
      </IconButton>
      <div className="flex flex-col text-xs !ml-3">
        <span className="text-gray-500">Workflow</span>
        <div className="flex items-center space-x-1">
          <span
            className={clsx('font-semibold', {
              'text-info': !selectedAction,
            })}
          >
            {`${node.id.slice(0, 5)} ${selectedVariable?.name ? '/' : ''}`}
          </span>
          {selectedVariable?.name ? (
            <span
              className={clsx('font-semibold', {
                'text-info': !isAddNewVariable,
              })}
            >
              {selectedVariable.name}
            </span>
          ) : null}
          {isAddNewVariable ? (
            <span className="font-semibold text-info">/ New variable</span>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default ActionHeader;
