import React from 'react';
import Spinner from './Spinner';

import Flex from './Containers/FlexContainer';

interface Props {
  loadingText?: string;
}
export default function DataLoader({ loadingText }: Props) {
  return (
    <Flex className="items-center justify-center space-x-4 mt-20">
      <Spinner className="text-primary-blue" size={24} />
      <span className="text-primary-blue font-medium">{loadingText}</span>
    </Flex>
  );
}
