import SvgIcon from '@mui/material/SvgIcon';
import type { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React from 'react';

export default function SkipIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0001 5V19M6.00006 10.5713V13.4287C6.00006 15.2557 6.00006 16.1693 6.38361 16.6958C6.71812 17.1549 7.2318 17.4496 7.79694 17.5073C8.4449 17.5733 9.2344 17.113 10.8126 16.1924L13.2617 14.7637L13.2702 14.7588C14.8217 13.8537 15.598 13.4009 15.8595 12.8105C16.0882 12.2946 16.0882 11.7062 15.8595 11.1902C15.5975 10.5988 14.8188 10.1446 13.2617 9.2363L10.8126 7.80762C9.2344 6.88702 8.4449 6.42651 7.79694 6.49256C7.2318 6.55017 6.71812 6.84556 6.38361 7.30469C6.00006 7.83111 6.00006 8.74424 6.00006 10.5713Z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
    </SvgIcon>
  );
}
