import { useMemo } from 'react';
import type {
  NodeData,
  WorkflowAction,
  WorkflowActionsOptions,
} from 'types-shared';
import { WorkflowImageNode, NodeStatusEnum } from 'types-shared';
import { Button } from 'ui-kit';
import { useUpdateWorkflowState } from '../../hooks';
import { EditorStore } from '../../store/EditorState';
import Action from './Action';
import ActionHeader from './ActionHeader';
import { NodeCheck } from 'editor-shared';

export type SelectedAction = WorkflowAction & {
  i: number;
};

interface Props {
  node: WorkflowImageNode;
  onClose: () => void;
  setSelectedAction: (actionData: SelectedAction) => void;
}

function ActionsList({ node, onClose, setSelectedAction }: Props) {
  const editorData = EditorStore();
  const { nodes, setNodes, variables } = editorData;
  const { mutate: updateWorkflowData } = useUpdateWorkflowState();

  const actions = useMemo(() => {
    const { actionData = {}, actionOrder = [] } = node.data;
    return actionOrder.map((id) => actionData[id]);
  }, [node]);

  const updateNodeData = (data: Partial<NodeData>) => {
    setNodes(
      nodes.map((_node) => {
        if (_node.id === node.id) {
          const updateNode = WorkflowImageNode.parse(_node);
          return {
            ...updateNode,
            data: {
              ...updateNode.data,
              ...data,
            },
          };
        }
        return _node;
      }),
    );
  };

  const updateAction = (
    action: WorkflowAction,
    options: WorkflowActionsOptions,
  ) => {
    updateNodeData({
      actionData: {
        ...node.data.actionData,
        [action.id]: {
          ...action,
          options,
        },
      },
    });
  };

  const saveWorkflowData = () => {
    if (editorData.workflowId) {
      updateWorkflowData({
        workflowId: editorData.workflowId,
        workflowData: editorData,
      });
    }
  };

  return (
    <>
      <ActionHeader />
      <h2 className="font-medium text-lg">Actions</h2>
      {actions.map((action, i) => (
        <Action
          action={action}
          i={i + 1}
          key={action.id}
          onEditClick={() => {
            setSelectedAction({
              ...action,
              i: i + 1,
            });
          }}
          onUpdateActionOptions={updateAction}
          variablesMap={variables}
        />
      ))}
      <span className="flex-1" />
      <NodeCheck
        isChecked={node.data.nodeStatus === NodeStatusEnum.Checked}
        updateNodeStatus={(status: NodeStatusEnum) => {
          updateNodeData({ nodeStatus: status });
        }}
      />
      <Button
        className="!text-info !border-info"
        onClick={() => {
          onClose();
          saveWorkflowData();
        }}
        variant="outlined"
      >
        Save & Exit
      </Button>
    </>
  );
}

export default ActionsList;
