import { clsx } from 'clsx';
import type { ReactNode } from 'react';
import React from 'react';

interface Classes {
  root?: string;
  label?: string;
  value?: string;
}

interface DetailedProps {
  classes?: Classes;
  label: string | ReactNode;
  value: ReactNode | string;
}

export default function DetailField({ classes, label, value }: DetailedProps) {
  const containerClasses = clsx(
    'flex flex-col items-start space-y-1.5 flex-1',
    classes?.root,
  );
  const valueClasses = clsx('break-all text-sm font-medium', classes?.value);
  const labelClasses = clsx(
    'text-xs uppercase text-primaryLight font-medium',
    classes?.label,
  );

  return (
    <div className={containerClasses}>
      <span className={labelClasses}>{label}</span>
      <span className={valueClasses}>{value}</span>
    </div>
  );
}
