import React, { useMemo } from 'react';
import type { EdgeProps } from 'types-shared/reactflow';
import {
  BaseEdge,
  EdgeLabelRenderer,
  getBezierPath,
} from 'types-shared/reactflow';
import { v4 as uuid } from 'uuid';
import AddIcon from '@mui/icons-material/Add';
import type { WorkflowEdge, WorkflowNode } from 'types-shared';
import { NodeStatusEnum, NodeTypesEnum } from 'types-shared';
import { getAllNodesAfter } from '../../utils/helper';
import { CustomMarker } from './CustomMarker';

interface Props {
  nodes: WorkflowNode[];
  edges: WorkflowEdge[];
  setEdges: (edges: WorkflowEdge[]) => void;
  updateNode: (node: WorkflowNode) => void;
  addNodes: (nodes: WorkflowNode[]) => void;
  edgeProps: EdgeProps;
  distanceBetweenNodes: number;
}
export function CustomEdgeCore({
  nodes,
  edges,
  setEdges,
  updateNode,
  addNodes,
  edgeProps: {
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    selected,
    source,
    target,
  },
  distanceBetweenNodes,
}: Props) {
  const [edgePath, labelX, labelY] = useMemo(
    () =>
      getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
      }),
    [sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition],
  );
  const currentEdge = edges.find((edge) => edge.id === id);

  const insertNode = () => {
    const sourceNode = nodes.find((n) => n.id === source);
    if (sourceNode) {
      const nodeId = uuid();
      const newNode: WorkflowNode = {
        id: nodeId,
        position: {
          ...sourceNode.position,
          x: sourceNode.position.x + distanceBetweenNodes,
        },
        type: NodeTypesEnum.New,
        data: {
          nodeStatus: NodeStatusEnum.NotViewed,
        },
      };
      addNodes([newNode]);
      const nodeIds = getAllNodesAfter(sourceNode, nodes, edges);
      nodeIds.forEach((_id) => {
        const node = nodes.find((n) => n.id === _id);
        if (node && _id !== source) {
          updateNode({
            ...node,
            position: {
              ...node.position,
              x: node.position.x + distanceBetweenNodes,
            },
          });
        }
      });
      const oldEdges = edges.filter((edge) => edge.id !== id);
      setEdges([
        ...oldEdges,
        {
          id: uuid(),
          source,
          target: nodeId,
        },
        {
          id: uuid(),
          source: nodeId,
          target,
        },
      ]);
    }
  };

  return (
    <>
      <CustomMarker />
      <BaseEdge
        markerEnd="url(#triangle)"
        path={edgePath}
        style={{ strokeWidth: '2px', stroke: selected ? '#8c8c8c' : '#000' }}
      />
      <EdgeLabelRenderer>
        <div
          className="nodrag nopan"
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 12,
            pointerEvents: 'all',
          }}
        >
          {currentEdge?.label ? (
            <p className="bg-white p-1">{currentEdge.label}</p>
          ) : (
            <button className="bg-flow-view" onClick={insertNode} type="button">
              <AddIcon />
            </button>
          )}
        </div>
      </EdgeLabelRenderer>
    </>
  );
}
