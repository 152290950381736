import SolaModal from '../../shared/SolaModal';
import type { EventMap } from 'ui-kit';
import { Button, Radio, createEventBus } from 'ui-kit';
import { useState, useEffect } from 'react';
import { Box, FormControl, RadioGroup, FormControlLabel } from '@mui/material';
import { useDuplicateWorkflow } from '../hooks';

interface DuplicateModalEvents extends EventMap {
  open: () => void;
}

export const duplicateModalEventChannel =
  createEventBus<DuplicateModalEvents>();

function DuplicateModal() {
  const [open, setOpen] = useState<boolean>(false);
  const [type, setType] = useState<string>('');

  useEffect(() => {
    const unsubscribe = duplicateModalEventChannel.on('open', () => {
      setOpen(true);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const { mutateAsync: duplicateWorkflow } = useDuplicateWorkflow();

  const onSubmit = async () => {
    await duplicateWorkflow(type);
    setOpen(false);
  };

  return (
    <SolaModal
      className="flex item-center justify-items-center max-w-4xl"
      onClose={() => {
        setOpen(false);
      }}
      open={open}
    >
      <div className="flex flex-col">
        <p className="mb-1 text-black">Duplicate Workflow</p>
        <p className="mb-10 text-sm text-lg text-gray-500">
          Duplicate Workflows
        </p>
        <p className="mb-0.5 text-black">
          Select what you want to keep in the new file
        </p>
        <Box className="ml-2">
          <FormControl sx={{ m: 0 }}>
            <RadioGroup
              defaultValue="1"
              name="radio-buttons-group"
              onChange={(_, val) => {
                setType(val);
              }}
            >
              <FormControlLabel
                control={<Radio color="secondary" />}
                label="Keep variables and database linked"
                value="linked"
              />
              <FormControlLabel
                control={<Radio color="secondary" />}
                label="Keep variables unliked (no database in the new workflow}"
                value="unlinked"
              />
              <FormControlLabel
                control={<Radio color="secondary" />}
                label="Keep the workflow without variables"
                value="noVariables"
              />
            </RadioGroup>
          </FormControl>
        </Box>

        <div className="flex flex-row gap-7 mt-10">
          <Button
            className="w-40 h-9"
            color="secondary"
            disabled={!type}
            onClick={onSubmit}
            variant="contained"
          >
            DUPLICATE
          </Button>
          <Button
            className="h-9"
            color="secondary"
            onClick={() => {
              setOpen(false);
            }}
            variant="text"
          >
            CANCEL
          </Button>
        </div>
      </div>
    </SolaModal>
  );
}

export default DuplicateModal;
