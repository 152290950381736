import { elementText, type MultiChoiceVariable } from 'types-shared';
import isNil from 'lodash/isNil';
import truncate from 'lodash/truncate';

interface Props {
  variable: MultiChoiceVariable;
}

function MultiChoicePreview({
  variable: { multiChoiceOptions, selectedChoiceIx },
}: Props) {
  const previewText = !isNil(selectedChoiceIx)
    ? truncate(elementText(multiChoiceOptions[selectedChoiceIx]) ?? '', {
        length: 50,
      }) || `Option ${selectedChoiceIx + 1}`
    : '';
  return <span className="px-3 flex items-center">{previewText}</span>;
}

export default MultiChoicePreview;
