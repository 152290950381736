import type { StateCreator } from 'zustand';
import { produce } from 'immer';

interface Integration {
  provider: 'google';
  token: string;
}

export interface IntegrationStateData {
  integrations: Record<string, Integration>;
}

export const initialIntegrationState: IntegrationStateData = {
  integrations: {},
};

export interface IntegrationStateActions {
  setIntegrations: (integrations: Record<string, Integration>) => void;
  addIntegration: (Integration: Integration) => void;
  deleteIntegration: (provider: string) => void;
  updateIntegration: (Integration: Integration) => void;
  resetIntegrations: () => void;
}

export const IntegrationState: StateCreator<
  IntegrationStateData & IntegrationStateActions
> = (set) => ({
  ...initialIntegrationState,
  setIntegrations: (integrations: Record<string, Integration>) => {
    set(
      produce((state: IntegrationStateData) => {
        state.integrations = integrations;
      }),
    );
  },
  addIntegration: (integration: Integration) => {
    set(
      produce((state: IntegrationStateData) => {
        state.integrations[integration.provider] = integration;
      }),
    );
  },
  deleteIntegration: (integrationId: string) => {
    set(
      produce((state: IntegrationStateData) => {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete state.integrations[integrationId];
      }),
    );
  },
  updateIntegration: (integration: Integration) => {
    set(
      produce((state: IntegrationStateData) => {
        state.integrations[integration.provider] = integration;
      }),
    );
  },
  resetIntegrations: () => {
    set(initialIntegrationState);
  },
});
