import { clsx } from 'clsx';
import values from 'lodash/values';
import React, { useEffect, useMemo, useRef } from 'react';
import type { NodeData, TargetMap } from 'types-shared/workflowTypes';
import { Spinner } from 'ui-kit';

interface Props {
  loading: boolean;
  nodeData: NodeData;
  activeTarget: string | null;
  className?: string;
  selectedNode: string | null | undefined;
  targets: TargetMap;
  setNodeImage: (nodeId: string, image: Blob) => void;
  fullImageData: Blob | null | undefined;
}

export function SelectedImageNodeContentCore({
  loading,
  nodeData,
  className,
  selectedNode,
  targets,
  setNodeImage,
  fullImageData,
  activeTarget,
}: Props) {
  const { imageData, actionData, actionOrder } = nodeData;

  const imgRef = useRef<HTMLImageElement>(null);
  const actions = useMemo(() => values(actionData), [actionData]);
  const { originalData } = imageData;

  useEffect(() => {
    if (!selectedNode) return;

    if (fullImageData && fullImageData !== imageData.originalData) {
      setNodeImage(selectedNode, fullImageData);
    }
  }, [selectedNode, setNodeImage, fullImageData, imageData.originalData]);

  return (
    <div
      className={clsx(
        'w-64 h-64 rounded-lg border border-indigo-light no-scrollbar nowheel overflow-y-auto',
        className,
      )}
    >
      <div className="relative h-fit w-full bg-gray-300">
        {loading ? (
          <div className="min-h-fit w-full h-64 z-[6] flex items-center justify-center">
            <Spinner size={36} />
          </div>
        ) : (
          <img
            alt=""
            className={clsx('min-h-fit w-full', {
              'h-64 z-[6]': !originalData,
            })}
            onError={(e) => {
              e.currentTarget.classList.add(
                'h-64',
                'z-[6]',
                'absolute',
                'left-0',
                'right-0',
                'top-0',
                'bottom-0',
              );
            }}
            ref={imgRef}
            src={
              originalData instanceof Blob
                ? URL.createObjectURL(originalData)
                : undefined
            }
          />
        )}
        {originalData
          ? actions.map(({ targetId = '', id: actionId }) => {
              const target = targets[targetId];
              const coordinates = target.coordinates;
              const order = actionOrder.indexOf(actionId);
              return (
                <div
                  className={clsx(
                    'absolute bg-[rgba(0,0,0,0.25)] border-2 border-gray-500',
                    'before:content-[attr(data-before)] before:text-white before:text-[0.6rem] before:bg-gray-700 before:rounded-full',
                    'before:absolute rounded before:-top-2 before:-translate-x-1/3 before:-translate-y-1/3 before:-right-5 before:w-6 before:h-6 before:leading-none before:flex before:justify-center before:items-center',
                    {
                      '!border-2 !border-info before:bg-info bg-[rgba(36,107,250,0.25)]':
                        activeTarget === targetId,
                    },
                  )}
                  data-before={order + 1}
                  key={targetId}
                  style={{
                    left: `${coordinates.xPercent}%`,
                    top: `${coordinates.yPercent}%`,
                    width: `${coordinates.widthPercent}%`,
                    height: `${coordinates.heightPercent}%`,
                  }}
                />
              );
            })
          : null}
      </div>
    </div>
  );
}
