import { clsx } from 'clsx';
import React from 'react';

interface Props {
  id?: string;
  className?: string;
  label?: string;
  required?: boolean;
  floatingLabel?: boolean;
}

export default function Label({
  id,
  className,
  label,
  required,
  floatingLabel = false,
}: Props) {
  const classes = clsx(
    'text-xs',
    {
      'flex items-center font-semibold': !floatingLabel,
      'absolute bg-white z-10 top-0 px-1 mt-1 text-gray-400': floatingLabel,
    },
    className,
  );

  return (
    <label className={classes} htmlFor={id}>
      {label}
      {required ? <sup className="text-red-500">*</sup> : null}
    </label>
  );
}
