import type {
  SendSlackMessageRequestPayload,
  ZodFetcher,
} from 'api-types-shared';
import { sendSlackMessageSchema } from 'api-types-shared';
import type { KyInstance, Options } from 'ky';
import { handleException } from 'sentry-browser-shared';
import { apiEndpoints, type NodeEnv } from 'ui-kit';

import { createZodKyFetcher } from '../fetcher';

export class MiscSDK {
  readonly endpoint: string;
  private _kyFetcher: ZodFetcher<KyInstance>;

  constructor(env: NodeEnv, kyOpts?: Options) {
    this.endpoint = apiEndpoints[env].miscApiV1;
    this._kyFetcher = createZodKyFetcher(kyOpts);
  }

  sendSlackMessage = async (req: SendSlackMessageRequestPayload) => {
    return this._kyFetcher(sendSlackMessageSchema.response, this.endpoint, {
      method: 'post',
      body: JSON.stringify(req),
    }).catch((err) => {
      handleException(err, {
        name: 'Send Slack Message Error',
        source: 'MiscAPI',
        userMessage: {
          title: 'Failed to send slack message',
        },
      });
      throw err;
    });
  };
}
