"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDatasourceSchema = void 0;
var types_shared_1 = require("types-shared");
var utils_schema_1 = require("../utils.schema");
exports.getDatasourceSchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.DatasourceMetadata.pick({ datasourceId: true }),
        query: types_shared_1.z.object({
            csvReq: utils_schema_1.booleanStringTransform,
            tableReq: utils_schema_1.booleanStringTransform,
        }),
        body: types_shared_1.z.object({}),
    }),
    response: types_shared_1.z.object({
        datasourceMetadata: types_shared_1.DatasourceMetadata,
        csvUrl: types_shared_1.z.string().optional(),
        tableUrl: types_shared_1.z.string().optional(),
    }),
};
