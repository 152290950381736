"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateLocalExecutionSchema = exports.startExecutionSchema = exports.startExecutionBodySchema = exports.getExecutionsSchema = void 0;
var types_shared_1 = require("types-shared");
var getExecutionsBodySchema = types_shared_1.z.object({
    workflowId: types_shared_1.z.string(),
});
exports.getExecutionsSchema = {
    request: types_shared_1.z.object({
        body: getExecutionsBodySchema,
    }),
    response: types_shared_1.z.object({
        executions: types_shared_1.z.array(types_shared_1.ExecutionRun),
    }),
};
exports.startExecutionBodySchema = types_shared_1.z.object({
    executions: types_shared_1.z.array(types_shared_1.ExecutionBody),
    data: types_shared_1.z.record(types_shared_1.z.string(), types_shared_1.z.object({
        workflowData: types_shared_1.ExecutionWorkflowData,
    })),
    setId: types_shared_1.uuidSchema.optional(),
});
exports.startExecutionSchema = {
    request: types_shared_1.z.object({
        body: exports.startExecutionBodySchema,
    }),
    response: types_shared_1.z.object({
        executing: types_shared_1.z.boolean(),
    }),
};
exports.updateLocalExecutionSchema = {
    request: types_shared_1.z.object({
        body: types_shared_1.z.object({
            windowId: types_shared_1.z.number().optional(),
            status: types_shared_1.ExecutionStatus.optional(),
            iterate: types_shared_1.z.boolean().optional(),
            execute: types_shared_1.z.boolean().optional(),
            selectedBranchId: types_shared_1.uuidSchema.optional(),
        }),
        sender: types_shared_1.z.object({
            tab: types_shared_1.z.object({
                windowId: types_shared_1.z.number(),
            }),
        }),
    }),
    response: types_shared_1.z.object({
        windowId: types_shared_1.z.number(),
    }),
};
