import { createBrowserRouter, Navigate } from 'react-router-dom';
import { AuthenticationGuard } from 'ui-kit';

import Datasource from './pages/Datasource';
import Editor from './pages/Editor';
import WorkflowDetail from './pages/Workflows/pages/WorkflowDetail';
import WorkflowsList from './pages/Workflows/pages/WorkflowsList';
import Integrations from './pages/Integration/Integrations';
import DatasourcePreview from './pages/Datasource/pages/DatasourcePreview';
import { ExecutionDetail } from './pages/Workflows/pages/ExecutionDetail';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <AuthenticationGuard component={WorkflowsList} />,
  },
  {
    path: 'editor',
    element: <AuthenticationGuard component={Editor} />,
  },
  {
    path: 'editor/:workflowId',
    element: <AuthenticationGuard component={Editor} />,
  },
  {
    path: 'datasource/:workflowId',
    element: <AuthenticationGuard component={Datasource} />,
  },
  {
    path: 'workflows/:workflowId',
    element: <AuthenticationGuard component={WorkflowDetail} />,
  },
  {
    path: 'integrations',
    element: <AuthenticationGuard component={Integrations} />,
  },
  {
    path: 'datasource/:workflowId/preview',
    element: <AuthenticationGuard component={DatasourcePreview} />,
  },
  {
    path: 'execution/:executionId',
    element: <AuthenticationGuard component={ExecutionDetail} />,
  },
  {
    path: '*',
    element: <Navigate to="/" />,
  },
]);
