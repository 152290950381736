import type { SelectChangeEvent } from '@mui/material';
import { FormControl } from '@mui/material';
import React from 'react';

import type { NodeEnv } from '../contexts/environment';
import { useEnvironment } from '../contexts/environment';
import Select from './Select';

export default function EnvToggler() {
  const { selectedEnv, setSelectedEnv, environments } = useEnvironment();
  return (
    <FormControl fullWidth>
      <Select
        defaultValue={selectedEnv}
        getLabel={(opt) => opt}
        getValue={(opt) => opt}
        label="Environment"
        labelId="select-environment-label"
        onChange={(event: SelectChangeEvent) => {
          setSelectedEnv(event.target.value as NodeEnv);
        }}
        options={environments}
      />
    </FormControl>
  );
}
