"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.simulateActionSchema = void 0;
var types_shared_1 = require("types-shared");
exports.simulateActionSchema = {
    request: types_shared_1.z.object({
        body: types_shared_1.z.object({
            text: types_shared_1.z.string().optional(),
            click: types_shared_1.z
                .object({
                x: types_shared_1.z.number(),
                y: types_shared_1.z.number(),
                count: types_shared_1.z.number().default(1),
            })
                .optional(),
            scroll: types_shared_1.z
                .object({
                x: types_shared_1.z.number().optional(),
                y: types_shared_1.z.number().optional(),
            })
                .optional(),
        }),
        sender: types_shared_1.z.object({
            tab: types_shared_1.z.object({
                id: types_shared_1.z.number(),
            }),
        }),
    }),
    response: types_shared_1.z.object({
        status: types_shared_1.z.boolean(),
    }),
};
