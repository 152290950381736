import { useEffect, type ReactNode, useState } from 'react';
import type { WorkflowNodeProps } from 'types-shared';
import { NodeElementCore, useEditingNodeId } from 'editor-shared';
import { EditorStore, type EditorStoreProps } from '../../store/EditorState';
import { useShallow } from 'zustand/react/shallow';
import { DISTANCE_BETWEEN_NODES } from '../../utils/constants';
import { nodeSelectEventChannel } from '../ActionsHeader';

interface Props {
  workflowData: WorkflowNodeProps;
  children: ReactNode;
  showAddButton?: boolean;
  showEditButton?: boolean;
  onClick?: () => void;
}

function NodeElement({
  workflowData,
  children,
  showAddButton,
  showEditButton,
  onClick,
}: Props) {
  const [nodeSelectionModeEnabled, setNodeSelectionModeEnabled] =
    useState<boolean>(false);
  const { nodes, edges, setEdges, updateNode, addNodes } = EditorStore(
    useShallow((state: EditorStoreProps) => ({
      nodes: state.nodes,
      edges: state.edges,
      setEdges: state.setEdges,
      updateNode: state.updateNode,
      addNodes: state.addNodes,
    })),
  );
  const { setEditingNodeId } = useEditingNodeId();

  useEffect(() => {
    const unsubscribe = nodeSelectEventChannel.on(
      'onToggleSelectionMode',
      (isEnabled) => {
        setNodeSelectionModeEnabled(isEnabled);
      },
    );

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <NodeElementCore
      addNodes={addNodes}
      distanceBetweenNodes={DISTANCE_BETWEEN_NODES}
      edges={edges}
      handleNodeClick={onClick}
      nodeSelectionModeEnabled={nodeSelectionModeEnabled}
      nodes={nodes}
      onClickEdit={() => {
        setEditingNodeId(workflowData.id);
      }}
      setEdges={setEdges}
      showAddButton={showAddButton}
      showEditButton={showEditButton}
      updateNode={updateNode}
      workflowData={workflowData}
    >
      {children}
    </NodeElementCore>
  );
}

export default NodeElement;
