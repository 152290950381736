import SvgIcon from '@mui/material/SvgIcon';
import type { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React from 'react';

export default function FileIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      fill="none"
      height="21"
      viewBox="0 0 19 21"
      width="19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0002 8.00019V4.2002C15.0002 3.08009 15.0002 2.51962 14.7822 2.0918C14.5905 1.71547 14.2847 1.40973 13.9084 1.21799C13.4806 1 12.9201 1 11.8 1H7.32548C7.20291 1 7.09569 1 7.00019 1.00087M1.00113 7C1.00022 7.09561 1.00019 7.20296 1.00019 7.32568V15.8036C1.00019 16.9215 1.00019 17.4805 1.21797 17.9079C1.40971 18.2842 1.71628 18.5905 2.0926 18.7822C2.52001 19 3.0792 19 4.19714 19L7.00019 19M1.00113 7C1.00385 6.71451 1.01446 6.53376 1.05586 6.36133C1.10485 6.15726 1.18598 5.96214 1.29564 5.7832C1.41932 5.58136 1.59131 5.40889 1.93721 5.06298L5.06269 1.9375C5.40838 1.59182 5.58163 1.41857 5.7834 1.29492C5.96234 1.18526 6.15728 1.10425 6.36135 1.05526C6.53386 1.01385 6.71456 1.00347 7.00019 1.00087M1.00113 7H1M1.00113 7H3.80328C4.9212 7 5.48099 7 5.9084 6.78223C6.28472 6.59048 6.59046 6.28392 6.78221 5.90759C7.00019 5.47977 7.00019 4.9201 7.00019 3.8V1.00087M17.3073 13.2099L9.40278 10.7777C9.01914 10.6597 8.65987 11.0188 8.77791 11.4024L11.2103 19.307C11.343 19.7381 11.9335 19.787 12.1352 19.3836L13.8102 16.0336C13.8586 15.9368 13.9371 15.8583 14.0339 15.81L17.384 14.1349C17.7875 13.9332 17.7385 13.3426 17.3073 13.2099Z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </SvgIcon>
  );
}
