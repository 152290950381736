import 'ui-kit/dist/tailwind.css';
import './index.css';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { EnvironmentProvider, Auth0Provider } from 'ui-kit';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { PostHogProvider } from 'posthog-js/react';

import Initializer from './initializer';

const options = {
  api_host: process.env.PUBLIC_POSTHOG_HOST,
  enable_recording_console_log: true,
  session_recording: {
    maskAllInputs: false,
  },
};

const rootElement = document.getElementById('root');
if (!rootElement) {
  throw new Error('Document root not found!');
}

const root = createRoot(rootElement);
root.render(
  <React.StrictMode>
    <Auth0Provider
      authorizationParams={{
        audience: process.env.REACT_APP_AUTH0_API_IDENTIFIER,
        redirect_uri: window.location.origin,
      }}
      cacheLocation="localstorage"
      clientId={String(process.env.REACT_APP_AUTH0_CLIENT_ID)}
      domain={String(process.env.REACT_APP_AUTH0_DOMAIN)}
    >
      <GoogleOAuthProvider clientId="Sample_client_Id">
        <EnvironmentProvider>
          <PostHogProvider
            apiKey={process.env.PUBLIC_POSTHOG_KEY}
            options={options}
          >
            <Initializer />
          </PostHogProvider>
        </EnvironmentProvider>
      </GoogleOAuthProvider>
    </Auth0Provider>
  </React.StrictMode>,
);
