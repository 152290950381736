import { NodeTypesEnum, type WorkflowNode } from 'types-shared/workflowTypes';

export const getImageDataFromNodes = (nodes: WorkflowNode[]) => {
  const imgs: string[] = [];
  nodes.forEach((node: WorkflowNode) => {
    if (node.type === NodeTypesEnum.Image) {
      imgs.push(node.data.imageData.imageId);
    }
  });

  return imgs;
};
