import NavigationItem from './NavigationItem';
import { Profile } from 'ui-kit';

const menuItems = [
  { text: 'Workflows', to: '/' },
  { text: 'Integrations', to: '/integrations' },
];

function NavigationPanel() {
  return (
    <div className="p-4 min-w-[300px] border-r-2 border-primary-blue-light flex flex-col">
      <div className="pt-[9px] h-14 mb-14 px-2">
        <img alt="Logo" className="w-8" src="/logo.png" />
      </div>
      <ul>
        {menuItems.map((menuItem) => (
          <NavigationItem
            className="mb-1"
            href={menuItem.to}
            key={menuItem.to}
            text={menuItem.text}
          />
        ))}
      </ul>
      <Profile />
    </div>
  );
}

export default NavigationPanel;
