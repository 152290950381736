"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.queueExecutionsSchema = void 0;
var types_shared_1 = require("types-shared");
exports.queueExecutionsSchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.z.object({
            workflowId: types_shared_1.uuidSchema,
        }),
        query: types_shared_1.z.object({}).optional(),
        body: types_shared_1.z.object({
            setRecordKeys: types_shared_1.z.array(types_shared_1.z.string()),
            bypassSQS: types_shared_1.z.boolean().optional().default(false),
            adminRun: types_shared_1.z.boolean().optional().default(false),
        }),
    }),
    response: types_shared_1.z.object({
        executionSetId: types_shared_1.uuidSchema,
        queuedExecutionIds: types_shared_1.z.array(types_shared_1.uuidSchema),
        unprocessedRecordKeys: types_shared_1.z.array(types_shared_1.z.string()).optional(),
    }),
};
