import React, { useEffect, useState } from 'react';

import type { EventMap } from '../../utils/eventBus';
import createEventBus from '../../utils/eventBus';
import type { Props } from './index';
import Dialog from './index';

type EventProps = Omit<Props, 'open' | 'onClose'>;

interface DialogEvents extends EventMap {
  open: (props: EventProps) => void;
  close: () => void;
}

export const dialogEventChannel = createEventBus<DialogEvents>();

export default function DialogWrapper() {
  const [open, setOpen] = useState<boolean>(false);
  const [props, setProps] = useState<EventProps>({} as EventProps);

  const handleOpen = (options: EventProps) => {
    setProps(options);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setProps({} as EventProps);
  };

  useEffect(() => {
    const unsubscribeOpen = dialogEventChannel.on('open', handleOpen);
    const unsubscribeClose = dialogEventChannel.on('close', handleClose);

    return () => {
      unsubscribeOpen();
      unsubscribeClose();
    };
  }, []);

  return <Dialog {...props} onClose={handleClose} open={open} />;
}
