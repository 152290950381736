import { clsx } from 'clsx';
import type { ReactNode } from 'react';
import React from 'react';

import type { ContainerProps } from '../types/common';
import { cardStyles } from '../utils/constants';

interface Classes {
  root?: string;
  child?: string;
}

interface Props extends ContainerProps {
  title: ReactNode;
  actions?: ReactNode;
  classes?: Classes;
  children: ReactNode;
}

export default function Card({ title, actions, children, classes }: Props) {
  const containerClasses = clsx(cardStyles, classes?.root);
  const childClasses = clsx('flex flex-col flex-1 p-8', classes?.child);

  return (
    <div className={containerClasses}>
      <div className="flex justify-between items-center p-5 border-b border-b-gray-200">
        {title}
        {actions}
      </div>
      <div className={childClasses}>{children}</div>
    </div>
  );
}
