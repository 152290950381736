"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultBrowserOptions = exports.handleException = exports.SentryBrowser = void 0;
const browser_1 = require("@sentry/browser");
const integrations_1 = require("@sentry/integrations");
const SENTRY_DSN_LOCAL = process.env.SENTRY_DSN_LOCAL ||
    'https://e00b92a78d367acc57ef264eb90458b4@o4506202933100544.ingest.sentry.io/4506203341193216';
exports.SentryBrowser = __importStar(require("@sentry/browser"));
var exception_handler_1 = require("./exception-handler");
Object.defineProperty(exports, "handleException", { enumerable: true, get: function () { return exception_handler_1.handleException; } });
const defaultBrowserOptions = () => {
    const environment = process.env.NODE_ENV;
    let sentryTarget;
    if (environment === 'production') {
        sentryTarget =
            process.env.PLASMO_PUBLIC_SENTRY_DSN || process.env.REACT_APP_SENTRY_DSN;
        if (!sentryTarget) {
            throw new Error(`Missing SENTRY_DSN environment variable in production environment`);
        }
        console.log(`Setting up Sentry Remote Error Reporting for '${environment}': SENTRY_DSN`);
    }
    else {
        console.log(`Setting up Sentry Remote Error Reporting for '${environment}': SENTRY_DSN_LOCAL`);
        sentryTarget = SENTRY_DSN_LOCAL;
    }
    return {
        dsn: sentryTarget,
        environment,
        integrations: [(0, browser_1.dedupeIntegration)(), (0, integrations_1.extraErrorDataIntegration)()],
    };
};
exports.defaultBrowserOptions = defaultBrowserOptions;
