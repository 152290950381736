import type { WorkflowEdge, WorkflowNode } from 'types-shared';
import isNil from 'lodash/isNil';

export const getAllNodesAfter = (
  node: WorkflowNode,
  nodes: WorkflowNode[],
  edges: WorkflowEdge[],
): string[] => {
  const visitedNodeIds = new Set<string>();
  const result: string[] = [];

  const traverseNodes = (currentNode: WorkflowNode) => {
    if (!visitedNodeIds.has(currentNode.id)) {
      visitedNodeIds.add(currentNode.id);
      result.push(currentNode.id);
      const nextNodeIds = edges
        .filter((edge) => edge.source === currentNode.id)
        .map((edge) => edge.target);

      nextNodeIds.forEach((nextNodeId) => {
        const nextNode = nodes.find((item) => item.id === nextNodeId);
        if (nextNode) traverseNodes(nextNode);
      });
    }
  };

  traverseNodes(node);
  return result;
};

export const findSiblingNodeIds = (
  sourceNode: WorkflowNode,
  _edges: WorkflowEdge[],
) => {
  const siblingNodeIds = _edges
    .filter((edge) => edge.source === sourceNode.id)
    .map((edge) => edge.target)
    .filter((e) => !isNil(e));

  return siblingNodeIds;
};
