import { Add, EditOutlined } from '@mui/icons-material';
import { CloseIcon } from 'assets-shared';
import { Button, Input, Select } from 'ui-kit';
import { EditBranch } from './EditBranch';
import type { WorkflowEdge, WorkflowNode } from 'types-shared';
import isNil from 'lodash/isNil';
import React from 'react';

interface Props {
  node: WorkflowNode;

  editingEdge: WorkflowEdge | undefined;
  setEditingEdge: (val: undefined | WorkflowEdge) => void;
  edges: WorkflowEdge[];
  setEdges: (edges: WorkflowEdge[]) => void;
  setNodes: (nodes: WorkflowNode[]) => void;

  deleteBranch: () => void;
  insertNode: (sourceId: string) => void;
  updateNodeName: (name: string) => void;
  updateBranchName: (name: string) => void;
  onCancel: () => void;
}
const typeOptions: string[] = ['type 1', 'type 2'];

export function ConditionalBlock({
  edges,
  node,
  editingEdge,
  onCancel,
  insertNode,
  updateNodeName,
  updateBranchName,
  deleteBranch,
  setEditingEdge,
}: Props) {
  const nodeEdges = edges
    .filter((edge) => edge.source === node.id)
    .filter((e) => !isNil(e));

  return (
    <>
      {!editingEdge ? (
        <div className="absolute left-2 top-2 bottom-2 w-120 bg-white rounded-lg z-[10] p-8 flex flex-col justify-between space-y-5">
          <div className="overflow-auto">
            <div className="flex justify-between items-center">
              <span className="text-sm text-primary-blue font-medium">
                Conditional logic
              </span>
              <button
                className="border border-info rounded-lg h-10 w-10 flex justify-center items-center"
                onClick={onCancel}
                type="button"
              >
                <CloseIcon className="text-info" />
              </button>
            </div>
            <div className="my-6">
              <h2 className="text-lg font-medium">Conditional logic</h2>
              <p className="text-sm font-normal text-info-dark">
                Create multiple branches and add conditions for them to execute.
              </p>
            </div>
            <div className="flex-1 flex flex-col gap-4">
              <Input
                floatingLabel
                label="Block Name"
                onChange={updateNodeName}
                placeholder="Block Name"
                value={node.name ?? ''}
              />
              <Select
                classes={{ select: 'w-100' }}
                getLabel={(opt: string) => opt}
                getValue={(opt: string) => opt}
                label="Condition Type"
                labelId="type"
                options={typeOptions}
              />
              <div className="mt-8">
                <p className="font-bold text-sm">Branches</p>
                <div className="mt-4">
                  {nodeEdges.map((edge) => {
                    return (
                      <div
                        className="px-3 py-4 mt-3 rounded-lg border border-indigo-light bg-primary-blue-light flex justify-between items-center"
                        key={edge.id}
                      >
                        <span>{edge.label}</span>
                        <div
                          className="flex justify-center items-center text-primary-blue cursor-pointer"
                          onClick={() => {
                            setEditingEdge(edge);
                          }}
                          role="presentation"
                        >
                          <EditOutlined className="!w-5 !h-5" />
                        </div>
                      </div>
                    );
                  })}
                  <Button
                    className="!mt-6"
                    color="secondary"
                    onClick={() => {
                      insertNode(node.id);
                    }}
                    startIcon={<Add />}
                    variant="text"
                  >
                    Add Branch
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <Button
            color="secondary"
            fullWidth
            onClick={onCancel}
            variant="outlined"
          >
            Back to flow view
          </Button>
        </div>
      ) : (
        <EditBranch
          edge={editingEdge}
          onCancel={() => {
            setEditingEdge(undefined);
          }}
          onDelete={() => {
            deleteBranch();
          }}
          onUpdateName={(name) => {
            updateBranchName(name);
          }}
        />
      )}
    </>
  );
}
