import type { EdgeProps } from 'types-shared/reactflow';
import { EditorStore } from '../../store/EditorState';
import { DISTANCE_BETWEEN_NODES } from '../../utils/constants';
import { CustomEdgeCore } from 'editor-shared';

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  selected,
  source,
  target,
}: EdgeProps) {
  const edgeProps = {
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    selected,
    source,
    target,
  };
  const { nodes, edges, setEdges, updateNode, addNodes } = EditorStore();

  return (
    <CustomEdgeCore
      addNodes={addNodes}
      distanceBetweenNodes={DISTANCE_BETWEEN_NODES}
      edgeProps={edgeProps}
      edges={edges}
      nodes={nodes}
      setEdges={setEdges}
      updateNode={updateNode}
    />
  );
}
