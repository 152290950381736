import {
  useMutation,
  type UseMutationResult,
  useQuery,
  type UseQueryResult,
  useQueryClient,
} from '@tanstack/react-query';
import {
  axios,
  UploadUrlContentTypeEnum,
  type CreateDatasourceResponse,
  type GetDatasourceResponse,
  UploadUrlTypeEnum,
} from 'api-types-shared';
import type {
  DatasourceMetadata,
  DatasourceTable,
} from 'types-shared/datasourceTypes';
import type { GoogleDriveFileMetadata } from 'types-shared/googleTypes';

import { handleException } from 'sentry-browser-shared';
import { useAPI } from '../../hooks/useApi';
import { useAuth0 } from 'ui-kit';

export const useFetchDatasourcesList = (): UseQueryResult<
  DatasourceMetadata[]
> => {
  const { datasourceSDK: sdk } = useAPI();
  return useQuery<DatasourceMetadata[]>({
    queryKey: ['datasources'],
    queryFn: async () => {
      const { datasourceMetas } = await sdk
        .fetchDatasourcesList({
          query: {},
        })
        .catch((err) => {
          handleException(err, {
            name: 'Error fetching datasource metas',
            source: sdk.endpoint,
          });
          return { datasourceMetas: [] };
        });

      return datasourceMetas;
    },
  });
};

export const useGetDatasource = (
  datasourceId: string,
): UseQueryResult<GetDatasourceResponse> => {
  const { datasourceSDK: sdk } = useAPI();
  return useQuery<GetDatasourceResponse>({
    queryKey: ['datasources', datasourceId],
    queryFn: () => {
      // const user = await getAuthUser();
      return sdk.getDatasource({
        params: { datasourceId },
        body: {},
        query: {},
      });
    },
  });
};

export const useGetDatasourceForWorkflow = (
  workflowId: string,
): UseQueryResult<DatasourceMetadata[]> => {
  const { datasourceSDK: sdk } = useAPI();
  return useQuery<DatasourceMetadata[]>({
    queryKey: ['datasources', workflowId],
    queryFn: async () => {
      const { datasourceMetas } = await sdk
        .fetchDatasourcesList({
          query: { workflowId },
        })
        .catch((err) => {
          handleException(err, {
            name: 'Error fetching datasource metas',
            source: sdk.endpoint,
          });
          return { datasourceMetas: [] };
        });
      return datasourceMetas;
    },
  });
};

export const useFetchDatasourceTable = (
  datasourceId: string | undefined,
  enabled: boolean,
): UseQueryResult<DatasourceTable | null> => {
  const { datasourceSDK: sdk } = useAPI();
  return useQuery<DatasourceTable | null>({
    queryKey: ['datasources', datasourceId],
    queryFn: async () => {
      if (!datasourceId) {
        return null;
      }
      const response = await sdk.getDatasource({
        params: { datasourceId },
        body: {},
        query: { tableReq: true },
      });

      if (response.tableUrl) {
        const table = await sdk.getDatasourceTable(response.tableUrl);
        return table || null;
      }
      return null;
    },
    enabled: Boolean(datasourceId) && enabled,
    refetchInterval: (query) =>
      query.state.data || query.state.dataUpdateCount > 5 ? false : 2000,
  });
};

export const useCreateDatasource = (): UseMutationResult<
  CreateDatasourceResponse,
  Error,
  { workflowId: string; name: string; description: string }
> => {
  const queryClient = useQueryClient();
  const { datasourceSDK: sdk } = useAPI();
  return useMutation<
    CreateDatasourceResponse,
    Error,
    {
      workflowId: string;
      name: string;
      description: string;
    }
  >({
    mutationFn: async ({ workflowId, name, description }) => {
      const result = await sdk.createDatasource({
        params: {},
        body: {
          workflowId,
          name,
          description,
        },
        query: {},
      });

      await queryClient.invalidateQueries({
        queryKey: ['datasources', workflowId],
      });

      return result;
    },
  });
};

export const useSaveDatasourceTable = (): UseMutationResult<
  unknown,
  Error,
  { datasourceId: string; datasourceTable: string }
> => {
  const queryClient = useQueryClient();
  const { datasourceSDK: sdk } = useAPI();
  return useMutation<
    unknown,
    Error,
    { datasourceId: string; datasourceTable: string }
  >({
    mutationFn: async ({ datasourceId, datasourceTable }) => {
      const { url } = await sdk.createUploadUrl({
        params: { datasourceId },
        body: {
          filename: 'datasourceTable',
          contentType: UploadUrlContentTypeEnum.JSON,
        },
        query: {
          uploadUrlType: UploadUrlTypeEnum.Table,
        },
      });

      await axios
        .put(url, datasourceTable, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .catch((err) => {
          handleException(err, {
            userMessage: {
              title: 'Failed to save datasource',
            },
          });
        });

      await queryClient.invalidateQueries({
        queryKey: ['datasources', datasourceId],
      });
    },
  });
};

export const useUploadFile = () => {
  const { datasourceSDK: sdk } = useAPI();
  const queryClient = useQueryClient();
  return useMutation<unknown, Error, { datasourceId: string; file: File }>({
    mutationFn: async ({ datasourceId, file }) => {
      const { url } = await sdk.createUploadUrl({
        params: { datasourceId },
        body: {
          filename: file.name,
          contentType: UploadUrlContentTypeEnum.CSV,
        },
        query: {
          uploadUrlType: UploadUrlTypeEnum.CSV,
        },
      });

      if (url) {
        await axios
          .put(url, file, {
            headers: {
              'Content-Type': UploadUrlContentTypeEnum.CSV,
            },
          })
          .catch((err) => {
            handleException(err, {
              userMessage: {
                title: 'Failed to save datasource',
              },
            });
          });

        await queryClient.invalidateQueries({
          queryKey: ['datasources', datasourceId],
        });
      }
    },
  });
};

export const useFetchGoogleSheets = (): UseQueryResult<
  GoogleDriveFileMetadata[]
> => {
  const { user } = useAuth0();
  const { datasourceSDK: sdk } = useAPI();

  return useQuery<GoogleDriveFileMetadata[]>({
    queryKey: ['googleSheets', user?.email],
    queryFn: async () => {
      try {
        const resp = await sdk.fetchDatasourcesList({
          query: { sheetsReq: true },
        });
        return resp.googleSheetMetas;
      } catch (e) {
        handleException(e, { source: sdk.endpoint });
      }
      return [];
    },
    enabled: Boolean(user),
  });
};
