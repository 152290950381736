"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.elementAttributesSchema = void 0;
var zod_1 = require("zod");
exports.elementAttributesSchema = zod_1.z.object({
    isAttached: zod_1.z.boolean(),
    isVisible: zod_1.z.boolean(),
    isEnabled: zod_1.z.boolean(),
    isStable: zod_1.z.boolean(),
    receivesEvents: zod_1.z.boolean(),
    isAriaBusy: zod_1.z.boolean(),
    isEditable: zod_1.z.boolean(),
    isSelectable: zod_1.z.boolean(),
    isCheckbox: zod_1.z.boolean(),
});
