"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fileResponseBodySchema = exports.deleteFileRequestSchema = exports.updateFileRequestSchema = exports.getFileRequestSchema = exports.createFileRequestSchema = exports.listFilesRequestSchema = exports.fileDbSchema = void 0;
var types_shared_1 = require("types-shared");
exports.fileDbSchema = types_shared_1.z.object({
    fileId: types_shared_1.z.string(),
    name: types_shared_1.z.string(),
    type: types_shared_1.z.enum(['csv', 'excel', 'pdf', 'image']),
    md5: types_shared_1.z.string(),
    createdAt: types_shared_1.z.number(),
    updatedAt: types_shared_1.z.number(),
    uploadedAt: types_shared_1.z.number().nullable(),
    location: types_shared_1.z.string().nullable(),
});
exports.listFilesRequestSchema = {
    params: types_shared_1.z.object({}),
    body: types_shared_1.z.object({}),
    query: types_shared_1.z.object({}),
};
exports.createFileRequestSchema = {
    params: types_shared_1.z.object({}),
    body: exports.fileDbSchema.pick({
        name: true,
        type: true,
        md5: true,
    }),
    query: types_shared_1.z.object({}),
};
exports.getFileRequestSchema = {
    params: exports.fileDbSchema.pick({
        fileId: true,
    }),
    body: types_shared_1.z.object({}),
    query: types_shared_1.z.object({}),
};
exports.updateFileRequestSchema = {
    params: exports.fileDbSchema.pick({
        fileId: true,
    }),
    body: exports.fileDbSchema.pick({
        name: true,
    }),
    query: types_shared_1.z.object({}),
};
exports.deleteFileRequestSchema = {
    params: exports.fileDbSchema.pick({
        fileId: true,
    }),
    body: types_shared_1.z.object({}),
    query: types_shared_1.z.object({}),
};
exports.fileResponseBodySchema = exports.fileDbSchema.extend({
    uploadUrl: types_shared_1.z.string(),
    downloadUrl: types_shared_1.z.string().nullable(),
});
