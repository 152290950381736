"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.elementText = exports.getDatasources = exports.parseVariables = exports.stringifyVariable = void 0;
var variable_schema_1 = require("./variable.schema");
var stringifyTemplateString = function (data, variableMap, recordData) {
    var stringifiedVariable = '';
    for (var _i = 0, data_1 = data; _i < data_1.length; _i++) {
        var item = data_1[_i];
        if (typeof item === 'string') {
            stringifiedVariable += item;
        }
        else {
            stringifiedVariable += (0, exports.stringifyVariable)(variableMap[item.id], variableMap, recordData);
        }
    }
    return stringifiedVariable;
};
// TODO(michael): Refactor this functions ...
var stringifyVariable = function (variable, variableMap, recordData) {
    switch (variable.type) {
        case variable_schema_1.VariableTypeEnum.Template:
            return stringifyTemplateString(variable.data, variableMap, recordData);
        case variable_schema_1.VariableTypeEnum.Datasource:
            if (!recordData) {
                throw new Error("No record data provided for datasource variable: ".concat(JSON.stringify(variable)));
            }
            return recordData[variable.data.key];
        case variable_schema_1.VariableTypeEnum.Scrape:
            if (!variable.name) {
                throw new Error("Scrape variable has no name: ".concat(JSON.stringify(variable)));
            }
            return variable.name;
        case variable_schema_1.VariableTypeEnum.Select:
            return stringifyTemplateString(variable.data, variableMap, recordData);
        case variable_schema_1.VariableTypeEnum.MultiChoice:
            return String(variable.selectedChoiceIx || 0);
        default:
            throw new Error("Unsupported variable type ".concat(JSON.stringify(variable)));
    }
};
exports.stringifyVariable = stringifyVariable;
var parseVariables = function (variableId, variableMap) {
    var mapValue = variableMap[variableId];
    var parsedVariable = variable_schema_1.TemplateVariable.safeParse(mapValue);
    if (!parsedVariable.success) {
        return [variableId];
    }
    var variable = parsedVariable.data;
    var variableIds = variable.data.flatMap(function (item) {
        var subVariable = variable_schema_1.VariableRef.safeParse(item);
        if (subVariable.success) {
            return (0, exports.parseVariables)(subVariable.data.id, variableMap);
        }
        return [];
    });
    return __spreadArray([variableId], variableIds, true);
};
exports.parseVariables = parseVariables;
var getDatasources = function (variableIds, variableMap) {
    var datasources = [];
    for (var _i = 0, variableIds_1 = variableIds; _i < variableIds_1.length; _i++) {
        var variableId = variableIds_1[_i];
        var variable = variableMap[variableId];
        if (variable.type === variable_schema_1.VariableTypeEnum.Datasource) {
            datasources.push(variable.data.datasourceId);
        }
    }
    return datasources;
};
exports.getDatasources = getDatasources;
var elementText = function (element) {
    if (element.innerText) {
        return element.innerText;
    }
    else if (element.ariaLabel) {
        return element.ariaLabel;
    }
    else if (element.labelText) {
        return (0, exports.elementText)(element.labelText);
    }
    return null;
};
exports.elementText = elementText;
